import { Button, Modal, Form, Input, Radio, DatePicker, Select } from 'antd';
import React from 'react';

const { Option } = Select;


const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      const config = {
         rules: [{ type: 'object', required: true, message: 'Selecciona una fecha de inicio!' }],
       };
      return (
        <Modal
          visible={visible}
          title="Clona un nuevo proyecto"
          okText="Clonar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre del proyecto">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Inserta un nombre del proyecto!' }],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Tipo de proyecto">
             {getFieldDecorator('tipo', {
               rules: [
                 { required: true, message: 'Seleciona un tipo de proyecto!', },
               ],
             })(
               <Select placeholder="Selecciona un tipo de proyecto">
                   {this.props.projectTypes.map(function(elem,index) {
                     //  //console.log(elem)
                      return <Option value={elem.key}>{elem.label}</Option>
                   })}
               </Select>,
             )}
           </Form.Item>
            <Form.Item label="Staff">
             {getFieldDecorator('staff', {
               rules: [
                 { required: true, message: 'Seleciona un tipo de proyecto!', },
               ],
             })(
               <Select placeholder="Selecciona un staff!">
                   {this.props.staffs.map(function(elem,index) {
                     //  //console.log(elem)
                      return <Option value={elem.key}>{elem.label}</Option>
                   })}
               </Select>,
             )}
           </Form.Item>
            <Form.Item label="Cliente">
             {getFieldDecorator('cliente', {
               rules: [
                 { required: true, message: 'Seleciona un cliente!', },
               ],
             })(
               <Select placeholder="Selecciona un tipo de proyecto">
                   {this.props.clientes.map(function(elem,index) {
                     //  //console.log(elem)
                      return <Option value={elem.key}>{elem.label}</Option>
                   })}
               </Select>,
             )}
           </Form.Item>

            <Form.Item label="Fecha de inicio">
                {getFieldDecorator('start_date', config)(<DatePicker />)}
            </Form.Item>

          </Form>
        </Modal>
      );
    }
  },
);

export default CollectionCreateForm;
