 import { revConstants } from "../constants";

const initialState = {
  REVS:{
        0:{ id: 0,
            columns: [
          {
            title: 'Numero de revisiones',
            dataIndex: 'nrev',
            key: 'nrev',
            editable:false,

          },
          {
            title: 'Incremento de presupuesto',
            dataIndex: 'increv',
            key: 'increv',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                increv: '2%',
                nrev: 1,

              },
              {
                key: '1',
                increv: '4%',
                nrev: 2,

              },
              {
                key: '2',
                increv: '10%',
                nrev: 3,

              },
              {
                key: '3',
                increv: '15%',
                nrev: 4,

              },


            ],
        },
        1:{ id: 1,
            columns: [
          {
            title: 'Numero de revisiones',
            dataIndex: 'nrev',
            key: 'nrev',
            editable:false,

          },
          {
            title: 'Incremento de presupuesto',
            dataIndex: 'increv',
            key: 'increv',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                increv: '2%',
                nrev: 1,

              },
              {
                key: '1',
                increv: '4%',
                nrev: 2,

              },
              {
                key: '2',
                increv: '10%',
                nrev: 3,

              },
              {
                key: '3',
                increv: '15%',
                nrev: 4,

              },


            ],
        },
        2:{ id: 2,
            columns: [
          {
            title: 'Numero de revisiones',
            dataIndex: 'nrev',
            key: 'nrev',
            editable:false,

          },
          {
            title: 'Incremento de presupuesto',
            dataIndex: 'increv',
            key: 'increv',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                increv: '2%',
                nrev: 1,

              },
              {
                key: '1',
                increv: '4%',
                nrev: 2,

              },
              {
                key: '2',
                increv: '10%',
                nrev: 3,

              },
              {
                key: '3',
                increv: '15%',
                nrev: 4,

              },


            ],
        },

    }

};

export default (state = initialState, action) => {
  switch (action.type) {
    case revConstants.REVS_FETCH_SUCCESS:
    return action.payload;

    case revConstants.ADD_REV_SUCCESS:
      return Object.assign({}, state, {
        rows: state.rows.concat(action.payload)
      });
      case revConstants.UPDATE_ROW_REV:
        var newData=state.REVS[action.payload.cpuKey];
        newData=newData.rows.map(
          row =>{

              if(row.key===action.payload.key){
                return {...row, [action.payload.prop]:action.payload.value}
              }
              return row;
            }
                 );

        return { ...state, REVS:{
                ...state.REVS,
                  [action.payload.cpuKey]: {
                    ...state.REVS[action.payload.cpuKey],
                    rows:newData
                  }
              //  ...row, completed: !row.completed
        }
      };
    case revConstants.REMOVE_REV_SUCCESS:
      return {
        rows: state.rows.filter(row => row.id !== action.payload)
      };
    default:
      return state;
  }
};
