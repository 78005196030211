import React from "react";
import "./styles.scss";
import { Button, Typography, Row, Col, Table,Input, Form, Select } from "antd";
import CatalogosTable from "./CatalogosTable";
const { Title } = Typography;
const { Option } = Select;

const tabuladores={
	"interior": "Interior",
	"exterior": "Exterior",
	"especial1": "Especial 1",
	"especial2": "Especial 2",
}

class CatalogosTabContent extends React.Component {
    constructor(props){
       super(props)
       //console.log(props.cpu.CPUcolumns)

          this.state = {

             selectedTabulador:-1,
			selectedProject:-1,
			newName:""
          };

  }
  handleSubConceptoAdd = () => {
	  if(this.state.newName!="" && this.state.selectedTabulador!==-1)
	  this.props.addSub(this.props.conceptoId,{name:this.state.newName,pane_id:this.state.selectedTabulador});
  }
  handleSubConceptoRemove = (sid) => {
	 // alert(sid)
	this.props.removeSub(this.props.conceptoId,sid);
  }

 handleInputChange = e =>{
	 console.log('aaaagria');
	 console.log(e);
	 this.setState({newName:e.target.value})
 }
  handleProjectTypeSelectChange = value => {
	 //console.log(`selected ${value}`);
	// console.log('ey k oonda')
	  this.setState({selectedProject:value});
	  this.props.updateConcepto(this.props.conceptoId, {gantt2_id:value});

	}

	handleTabuladorSelectChange = value => {
  	 //console.log(`selected ${value}`);
  	 //console.log('ey k oonda')
	 console.log(value)
  	  this.setState({selectedTabulador:value});
  	 // this.props.updateConcepto(this.props.conceptoId, {gantt2_id:value});

  	}

    CPUhandleDelete = key => {
      const dataSource = [...this.state.CPUdataSource];
      this.setState({ CPUdataSource: dataSource.filter(item => item.key !== key) });
    };
    CPUhandleRowAdd = () => {
      const { CPUcount, CPUdataSource } = this.state;
      var newData = {
        key: CPUcount,
        finterval: 'Desde',
        linterval: 'Hasta',
        sotano: '$$',
        interior: '$$',
      };
      this.setState({
        CPUdataSource: [...CPUdataSource, newData],
        CPUcount: CPUcount + 1,
      });
    };
    CPUhandleColumnAdd = () => {
      const { CPUcount, CPUdataSource } = this.state;
      var newData = {
        key: CPUcount,
        finterval: 'Desde',
        linterval: 'Hasta',
        sotano: '$$',
        interior: '$$',
      };
      this.setState({
        CPUdataSource: [...CPUdataSource, newData],
        CPUcount: CPUcount + 1,
      });
    };
    CPUhandleSave = row => {
      /*const newData = [...this.state.CPUdataSource];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      //console.log(row);
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      console.log(newData)
      this.setState({ CPUdataSource: newData });*/
      this.props.HandleUpdateCPURow({...row, cpuKey:this.props.cpuKey })

      console.log(this.props.cpu);
    };

    HandleAllCPUSave = cpu =>{
      this.props.HandleCPUSave({cpuKey:this.props.cpuKey, cpu:this.props.cpu, rev:this.props.rev, auxi:this.props.auxi,})
    }

    REVhandleDelete = key => {
      const dataSource = [...this.state.REVdataSource];
      this.setState({ REVdataSource: dataSource.filter(item => item.key !== key) });
    };
    REVhandleRowAdd = () => {
      const { REVcount, REVdataSource } = this.state;
      var newData = {
        key: REVcount,
        finterval: 'Desde',
        linterval: 'Hasta',
        sotano: '$$',
        interior: '$$',
      };
      this.setState({
        REVdataSource: [...REVdataSource, newData],
        REVcount: REVcount + 1,
      });
    };
    REVhandleColumnAdd = () => {
      const { REVcount, REVdataSource } = this.state;
      var newData = {
        key: REVcount,
        finterval: 'Desde',
        linterval: 'Hasta',
        sotano: '$$',
        interior: '$$',
      };
      this.setState({
        REVdataSource: [...REVdataSource, newData],
        REVcount: REVcount + 1,
      });
    };
    REVhandleSave = row => {
      const newData = [...this.state.REVdataSource];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      this.setState({ REVdataSource: newData });
    };

    render(){
    //  const  REVdataSource  = this.props.rev.REVrows;
      //const CPUdataSource = this.props.cpu.CPUrows;

	  console.log(this.props.subConceptos)
      {/*const REVcolumns = this.props.rev.REVcolumns.map(col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      });*/}
        return (
        <div className='concepto-tab'>
			<Row gutter={6}>
			<Select style={{width:200}} defaultValue={this.props.project} onChange={this.handleProjectTypeSelectChange}>
					  <Option key={-1} value={-1}>Tipo de proyecto</Option>
					  {this.props.tipos.map(function(elem,index) {
						//  //console.log(elem)
						 return <Option key={elem.key} value={elem.key}>{elem.label}</Option>
					  })}
					</Select>
			</Row>
			<Row gutter={6}>
				<Col span={4}> <Title level={4}>Conceptos</Title></Col>
				<Col span={4}> <Title level={4}>Variable del tabulador</Title></Col>
			</Row>
			{this.props.subConceptos!=undefined?Object.keys(this.props.subConceptos).map((elem,index) =>{
			  //  //console.log(elem)

			   return this.props.subConceptos[elem].status>0?
			   	<Row gutter={6}>
					<Col span={4}>
						{this.props.subConceptos[elem].name}
					</Col>
					<Col span={4}>
						{tabuladores[this.props.subConceptos[elem].pane_id]!=undefined?
				   		tabuladores[this.props.subConceptos[elem].pane_id]:""}
					</Col>
				    <Col span={4}>
						<Button onClick={()=>this.handleSubConceptoRemove(this.props.subConceptos[elem].id)} type="secondary">
							Eliminar
					  	</Button>
				   </Col></Row>:""
		   }) :""}
			<Row gutter={6}>

				<Col span={4}>
					<Input
					  value={this.state.newName || ''}
					onChange={e => this.handleInputChange(e)} placeholder="Nombre" />
				</Col>

				<Col span={4}>
					<Select style={{width:200}}   defaultValue="-1" onChange={this.handleTabuladorSelectChange}>
							  <Option value="-1">Selecciona una variable</Option>

							 	<Option value="interior" key="interior">Interior</Option>
							 	<Option value="exterior" key="exterior">Exterior</Option>
							 	<Option value="especial1" key="especial1">Especial 1</Option>
							 	<Option value="especial2" key="especial2">Especial 2</Option>

							</Select>
				</Col>
				<Col span={4}>
				<Button onClick={this.handleSubConceptoAdd} type="primary">
						Añadir
				  </Button>
				  </Col>
			</Row>
             {/* <Row gutter={6}>
                  <Title level={2}>Costos por unidad</Title>
                <Col span={22}>


                <CatalogosTable data={this.props.cpu} HandleUpdateRow={this.props.HandleUpdateCPURow} cpuKey={this.props.cpuKey} />
                </Col>
              </Row>

              <Row gutter={6}>
              <Title level={2}>Factores de alteración</Title>
              <Col span={10}>
              <CatalogosTable data={this.props.rev} HandleUpdateRow={this.props.HandleUpdateREVRow} cpuKey={this.props.cpuKey} />
              </Col>
              <Col offset = {2} span={10}>  <CatalogosTable data={this.props.auxi} HandleUpdateRow={this.props.HandleUpdateAUXISRow} cpuKey={this.props.cpuKey} /> </Col>

              </Row>
              <Row gutter={6}>
              <Button onClick={ this.HandleAllCPUSave } type="primary" style={{ margin: 16 }}>
                  Guardar

                </Button></Row>
				*/}
				{/*  <Button onClick={this.handleRowAdd} type="primary" style={{ marginBottom: 16 }}>
                    Añadir fila
                  </Button>
                  <Button onClick={this.handleColumnAdd} type="primary" style={{ marginBottom: 16 }}>
                    Añadir columna
                    </Button>*/}
        </div>

        );
      }
}

export default CatalogosTabContent;
