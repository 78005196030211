import { combineReducers } from "redux";
//import todo from "./todo.reducer";
import cpu from "./cpu.reducer";
import rev from "./rev.reducer";
import auxi from "./auxi.reducer";
import proceso from "./proceso.reducer";
import staff from "./staff.reducer";
import cliente from "./cliente.reducer";
import gantt from "./gantt.reducer";
import gantt2 from "./gantt2.reducer";
import auth from "./auth.reducer";
import pane from "./pane.reducer";
import concepto from "./concepto.reducer";
import proyecto from "./proyecto.reducer";

export default combineReducers({
   cpu, rev, auxi, proceso, staff, cliente, gantt, gantt2, auth, pane, concepto, proyecto
});
