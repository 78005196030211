import React from "react";
import CatalogosTabContent from "../../components/arquitectos/CatalogosTabContent";
import { Layout, Menu, Icon, Tabs, Button, Typography,Form,
   Input,
   Tooltip,
   Cascader,
   Select,
   Row,
   Col,
   Checkbox,
   AutoComplete,
 } from 'antd';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import ASider from "../../components/arquitectos/ASider";
import AHeader from "../../components/arquitectos/AHeader";
import { cpuActions, revActions, auxiActions, staffActions } from "../../actions";
const { Title } = Typography;

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;



// importing todo components

// importing our todo's action's



class Staffs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '0',
      edit:false,
      confirmDirty: false,

      };
  }

  componentWillMount(){
//    this.props.fetchAllCPU();
    const { match: { params } } = this.props;
    //alert(params.id);
    if(params.id!="" && params.id!=undefined){
    //alert(params.id+' entro')
    this.setState({edit:true});
    this.props.staffFetch(params.id);}

  }

  handleConfirmBlur = e => {
  const { value } = e.target;
  this.setState({ confirmDirty: this.state.confirmDirty || !!value });
};

compareToFirstPassword = (rule, value, callback) => {
  const { form } = this.props;
  if (value && value !== form.getFieldValue('password')) {
    callback('Las contraseñas no son iguales!');
  } else {
    callback();
  }
};

validateToNextPassword = (rule, value, callback) => {
  const { form } = this.props;
  if (value && this.state.confirmDirty) {
    form.validateFields(['confirm'], { force: true });
  }
  callback();
};

  handleCreate = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);


      if(this.state.edit===true){
         console.log('editar desde el formulario')
         this.props.staffUpdate({...values,id:this.props.staff.id},this.handleRedirect)}
     else{console.log('guardar desde el formulario')
         this.props.staffSave(values,this.handleRedirect)};
      form.resetFields();

    });
  };

  handleRedirect = e => {
    this.props.history.push('/staffs/');
  };


  render()
  {

    //console.log("copia esto we")
    //console.log(JSON.stringify(this.props.cpu))
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout id="sider">
        <ASider/>
        <Layout  className='layout-container-antd'>
          <AHeader >{this.state.edit===true?this.props.staff.nombre:"Nuevo Staff"}</AHeader>
          <Content  style={{ margin: '24px 16px 0' }}>
            <div style={{ overflowY:'auto', padding: 24, background: '#fff', height:'100%' }}>
              <Form>
                <Title>Staff</Title>
                <Form.Item label="">
                    {getFieldDecorator('nombre', {
                        initialValue: this.state.edit===true?this.props.staff.nombre:'',
                    rules: [
                        {
                            required: true,
                            message: 'Campo requerido!',
                        },
                    ],
                    })(<Input placeholder="Nombre del Staff" />)}
                </Form.Item>
                <Form.Item label="" hasFeedback>
                  {getFieldDecorator('role', {
                      initialValue: this.state.edit===true?this.props.staff.role:'',
                    rules: [{ required: true, message: 'Selecciona un rol!' }],
                  })(
                    <Select placeholder="Selecciona un rol">
                      <Select.Option value="operador">Operador</Select.Option>
                      <Select.Option value="manager">Manager</Select.Option>
                      <Select.Option value="admin">Admin</Select.Option>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('contraccion', {
                    initialValue: this.state.edit===true?this.props.staff.contraccion:'',
                          rules: [
                            {
                              required: true,
                              message: 'Campo requerido!',
                            },
                          ],
                      })(<Input placeholder="Contraccion" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('movil', {
                    initialValue: this.state.edit===true?this.props.staff.movil:'',
                    rules: [
                        {
                          required: true,
                          message: 'Campo requerido!',
                        },
                      ],
                    })(<Input placeholder="Movil" />)}
                </Form.Item>
                <Form.Item label="">
                    {getFieldDecorator('email', {
                    initialValue: this.state.edit===true?this.props.staff.email:'',
                    rules: [
               {
                 type: 'email',
                 message: 'The input is not valid E-mail!',
               },
               {
                 required: true,
                 message: 'Please input your E-mail!',
               },
             ],
                    })(<Input placeholder="Email" />)}
                </Form.Item>

                {this.state.edit===false && <Form.Item hasFeedback>
                  {getFieldDecorator('password', {
                    initialValue: this.state.edit===true?this.props.staff.password:'',
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                })(<Input.Password placeholder="Contraseña" />)}
            </Form.Item>}
            {this.state.edit===false &&
                <Form.Item hasFeedback>
                  {getFieldDecorator('confirm', {
                    initialValue: this.state.edit===true?this.props.staff.confirm:'',
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                })(<Input.Password placeholder="Confirmar Contraseña" onBlur={this.handleConfirmBlur} />)}
            </Form.Item>}

                <Form.Item label="">
                {getFieldDecorator('direccion', {
                  initialValue: this.state.edit===true?this.props.staff.direccion:'',
                  rules: [
                    {
                      required: true,
                      message: 'Campo requerido!',
                    },
                  ],
                  })(<Input placeholder="Dirección" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('cp', {
                  initialValue: this.state.edit===true?this.props.staff.cp:'',
                  rules: [
                    {
                      required: true,
                      message: 'Campo requerido!',
                    },
                  ],
                  })(<Input placeholder="CP" />)}
                </Form.Item>

                <Form.Item>
                  <Button  onClick={this.handleCreate} type="primary" htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>
                </Form>


            </div>
          </Content>

        </Layout>
      </Layout>
    );
  };
}

const mapStateToProps = state => {
  return {
  //  cpu: state.cpu.CPUS,
      staff:state.staff.byID,

  };
};




const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchAllCPU: cpuActions.fetchAllCPU,
      staffFetch:staffActions.fetchByID,
      staffSave:staffActions.formSave,
       staffUpdate:staffActions.update,

    },
    dispatch
  );
const WrappedStaffs = Form.create()(Staffs)
export default connect(mapStateToProps,mapDispatchToProps)(WrappedStaffs);
