export const conceptoConstants = {
  ADD_SUCCESS: "CONCEPTO_ADD_SUCCESS",
  REMOVE_SUCCESS: "CONCEPTO_REMOVE_SUCCESS",
  UPDATE_SUCCESS: "CONCEPTO_UPDATE_SUCCESS",
  UPDATE_DIMENSION_SUCCESS: "DIMENSION_UPDATE_SUCCESS",
  UPDATE_DIMENSIONCUS_SUCCESS: "DIMENSIONCUS_UPDATE_SUCCESS",
  UPDATE_NIVEL_SUCCESS: "NIVEL_UPDATE_SUCCESS",
  FETCH_SUCCESS: "CONCEPTO_FETCH_SUCCESS",
  FETCH_BY_ID_SUCCESS: "CONCEPTO_FETCH_BY_ID_SUCCESS",
};
