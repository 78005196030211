import React from "react";
import { Table,Input, Form } from "antd";
import "./styles.scss";



const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      console.log(record.key);
      const key= record.key;
      const prop=Object.keys(values)[0];
      const value=values[Object.keys(values)[0]];
      handleSave({ key,prop,value });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {cpu,
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    console.log(cpu);
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}




class CatalogosTable extends React.Component {
    constructor(props){
       super(props)


          this.state = {

              CPUcount:2,

            CPUcolumnscount:4,
            REVdataSource: [
              {
                key: '0',
                finterval: 0,
                linterval: 149,
                sotano: '$',
                interior: '$',
              },
              {
                key: '1',
                finterval: 150,
                linterval: 349,
                sotano: '$',
                interior: '$',
              },
              {
                key: '2',
                finterval: 350,
                linterval: 549,
                sotano: '$',
                interior: '$',
              },
              {
                key: '3',
                finterval: 550,
                linterval: 699,
                sotano: '$',
                interior: '$',
              },
              {
                key: '4',
                finterval: 700,
                linterval: 999,
                sotano: '$',
                interior: '$',
              },
              {
                key: '5',
                finterval: 1000,
                linterval: 'en adelante',
                sotano: '$',
                interior: '$',
              },
            ],
              REVcount:2,
            REVcolumns:[
                        {
                          title: 'Intervalos',
                          dataIndex: 'finterval',
                          key: 'finterval',
                          colSpan: 2,
                          //editable:true,

                        },
                        {
                          title: 'FIntervalos',
                          dataIndex: 'linterval',
                          key: 'linterval',
                          colSpan: 0,
                        //  editable:true,

                        },
                        {
                          title: 'Exterior',
                          dataIndex: 'sotano',
                          key: 'sotano',
                          editable:true,

                        },
                        {
                          title: 'Interior',
                          dataIndex: 'interior',
                          key: 'interior',
                          editable:true,

                        },
                        /*{
                          title: 'Operaciones',
                          dataIndex: 'operation',
                          render: (text, record) =>
                            this.state.dataSource.length >= 1 ? (
                              <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                                <a href="javascript:;">Borrar</a>
                              </Popconfirm>
                            ) : null,
                        },*/
                      ],
            REVcolumnscount:4,
          };

  }

    handleDelete = key => {
      const dataSource = [...this.state.CPUdataSource];
      this.setState({ CPUdataSource: dataSource.filter(item => item.key !== key) });
    };
    handleRowAdd = () => {
      const { CPUcount, CPUdataSource } = this.state;
      var newData = {
        key: CPUcount,
        finterval: 'Desde',
        linterval: 'Hasta',
        sotano: '$$',
        interior: '$$',
      };
      this.setState({
        CPUdataSource: [...CPUdataSource, newData],
        CPUcount: CPUcount + 1,
      });
    };
    handleColumnAdd = () => {
      const { CPUcount, CPUdataSource } = this.state;
      var newData = {
        key: CPUcount,
        finterval: 'Desde',
        linterval: 'Hasta',
        sotano: '$$',
        interior: '$$',
      };
      this.setState({
        CPUdataSource: [...CPUdataSource, newData],
        CPUcount: CPUcount + 1,
      });
    };
    handleSave = row => {
      /*const newData = [...this.state.CPUdataSource];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      //console.log(row);
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      console.log(newData)
      this.setState({ CPUdataSource: newData });*/
      
      this.props.HandleUpdateRow({...row, cpuKey:this.props.cpuKey })


    };


    render(){
      const dataSource = this.props.data.rows;
      const components = {
        body: {
          row: EditableFormRow,
          cell: EditableCell,
        },
      };
      const columns = this.props.data.columns.map(col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      });

        return (

              <div>

                    {/*  <Button onClick={this.handleRowAdd} type="primary" style={{ marginBottom: 16 }}>
                        Añadir fila
                      </Button>
                      <Button onClick={this.handleColumnAdd} type="primary" style={{ marginBottom: 16 }}>
                        Añadir columna
                        </Button>*/}

                      <Table pagination={false}
                        components={components}
                        rowClassName={() => 'editable-row'}
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                      />

                    </div>
        );
      }
}
export default CatalogosTable;
