import firebase from 'firebase';
import { authConstants } from "../constants";
import { toast } from "react-toastify";
import axios from 'axios';



const loginUser = ( auth, redirect ) => {
  console.log(auth);

  return (dispatch) => {
      toast.info("Intentando conectar...");
      firebase.auth().signInWithEmailAndPassword(auth.username, auth.password)
      .then((response) => {
          firebase.auth().currentUser.getIdToken().then(function(idToken) {  // <------ Check this line
           console.log(idToken); // It shows the Firebase token now


           axios({
                 method: 'get',
                 url: authConstants.API+response.user.uid,
                 headers: {'Authorization': 'Bearer '+idToken},

             }).then(function(UserInfo){
                 console.log('entro')
                 console.log(UserInfo.data.user)

                 auth.uid=response.user.uid;
                 dispatch({ type: authConstants.LOGIN_USER_SUCCESS, payload:{...auth, idToken:idToken,UserInfo:UserInfo.data.user}});
                 toast.success("Sesion iniciada exitosamente.");
                 redirect();

             }).catch(function(error){
                 if(error.response)
                 {toast.error(error.response.data.message);}
             });

        });

        //Actions.employeeList({ type: 'reset' });
    }).catch(
        function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            toast.error(errorCode+" "+errorMessage);
            // ...
}

    );
  };
};

const logoutUser=(redirect) => {

};


const sendMail = ( mailData ) => {
  console.log(mailData);
  return (dispatch) => {
  firebase.auth().currentUser.getIdToken().then(function(idToken) {  // <------ Check this line
      console.log(idToken); // It shows the Firebase token now
      // Send a POST request
      axios.defaults.headers.common['Authorization'] = idToken;
      axios({
            method: 'post',
            url: authConstants.API_EMAIL,
            headers: {'Authorization': 'Bearer '+idToken},
            data: {
              //displayName: mailData.nombre,
              dest:mailData.dest,
              file:mailData.file,
              //role:mailData.role,
              //password: mailData.password,
            }
        }).then((response)=>{
            console.log('entro')
            console.log(response)
            //const id= Math.round(Math.random() * 36 ** 12).toString(36);

                  dispatch({ type: authConstants.EMAIL_SUCCESS });
                    toast.success("Enviado");
                  //Actions.employeeList({ type: 'reset' });


          }).catch(function(error){toast.error(error.response.data.message);});
    });
};


};

export const authActions = {
  loginUser, logoutUser, sendMail,
};
