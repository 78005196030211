export const proyectoConstants = {
  ADD_SUCCESS: "PROYECTO_ADD_SUCCESS",
  REMOVE_SUCCESS: "PROYECTO_REMOVE_SUCCESS",
  UPDATE_SUCCESS: "PROYECTO_UPDATE_SUCCESS",
  UPDATE_DIMENSION_SUCCESS: "DIMENSION_UPDATE_SUCCESS",
  UPDATE_DIMENSIONCUS_SUCCESS: "DIMENSIONCUS_UPDATE_SUCCESS",
  UPDATE_NIVEL_SUCCESS: "NIVEL_UPDATE_SUCCESS",
  FETCH_SUCCESS: "PROYECTO_FETCH_SUCCESS",
  FETCH_BY_ID_SUCCESS: "PROYECTO_FETCH_BY_ID_SUCCESS",
};
