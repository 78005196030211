import firebase from 'firebase';
import { cpuConstants } from "../constants";
import { toast } from "react-toastify";

const fetchAllCPU = () => {
  return (dispatch) => {
    firebase.database().ref(`/`)
      .on('value', snapshot => {
        dispatch({ type: cpuConstants.CPUS_FETCH_SUCCESS, payload: snapshot.val() });
      });
  };
};


const CPUSave = ({cpuKey, cpu, rev, auxi }) => {

  return (dispatch) => {
    firebase.database().ref(`/AUXIS/${cpuKey}/`)
      .set({ ...auxi });
    firebase.database().ref(`/REVS/${cpuKey}/`)
      .set({ ...rev });
    firebase.database().ref(`/CPUS/${cpuKey}/`)
      .set({ ...cpu })
      .then(() => {
        dispatch({ type: cpuConstants.CPUS_SAVE_SUCCESS });
          toast.success("Guardado");
        //Actions.employeeList({ type: 'reset' });
      });
  };
};

const addCPUSuccess = cpu => dispatch => {
  dispatch({
    type: cpuConstants.ADD_CPU_SUCCESS,
    payload: cpu
  });

  toast.success("CPU added!");
};

const updateRowCPU = cpu => dispatch => {
  dispatch({
    type: cpuConstants.UPDATE_ROW_CPU,
    payload: cpu
  });


};

const toggleCPUSuccess = id => dispatch => {
  dispatch({
    type: cpuConstants.TOGGLE_CPU_SUCCESS,
    payload: id
  });

  toast.info("CPU state updated!");
};

const removeCPUSuccess = id => dispatch => {
  dispatch({
    type: cpuConstants.REMOVE_CPU_SUCCESS,
    payload: id
  });
  toast.error("CPU removed!");
};

const addCPU = name =>
  addCPUSuccess({
    id: Math.round(Math.random() * 36 ** 12).toString(36),
    name: name,
    completed: false
  });

const toggleCPU = id => toggleCPUSuccess(id);

const removeCPU = id => removeCPUSuccess(id);

export const cpuActions = {
  addCPU,
  removeCPU,
  toggleCPU,
  updateRowCPU, fetchAllCPU, CPUSave,
};
