import { conceptoConstants } from "../constants";

const initialState = {
 data:[], dataCompleted:[],byID:{},subByID_object:{},subByID_array:[]};

export default (state = initialState, action) => {
switch (action.type) {
   case conceptoConstants.FETCH_SUCCESS:
     return action.payload;
	 case conceptoConstants.FETCH_BY_ID_SUCCESS:
     	return {...state,...action.payload};
	 case conceptoConstants.UPDATE_DIMENSION_SUCCESS:
     	return {...state,subByID_object:{
			...state.subByID_object,
			[action.uid]:{
				...state.subByID_object[action.uid],
				dimensiones:action.value
			}
		} };
		case conceptoConstants.UPDATE_NIVEL_SUCCESS:
		   return {...state,subByID_object:{
			   ...state.subByID_object,
			   [action.uid]:{
				   ...state.subByID_object[action.uid],
				   niveles:action.value
			   }
		   } };
		case conceptoConstants.UPDATE_DIMENSIONCUS_SUCCESS:
		   return {...state,subByID_object:{
			   ...state.subByID_object,
			   [action.uid]:{
				   ...state.subByID_object[action.uid],
				   dimensioncus:action.value
			   }
		   } };
   default: return state;
}

};
