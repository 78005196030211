 import { staffConstants } from "../constants";

const initialState = {
    byID:{},
  staffs:{
    0:{
      id: 0,
      nombre:'KAREN MALACARA',
      movil:'3221173023',
      email:'micorreo@gmail.com',
      proyectoDireccion:'16 de octubre #1493',
      cp:'48290',
      empresaNombre:'Empresa s.a de c.v',
      empresaTelefono: '2990181',
      staffDireccion:'1ro de Mayo #145',
      empresaDireccion: 'Indefinida',
      empresaCP: '45320',
      razonSocial: 'Miempresa S.A de C.V ',
      rfc: '499230',
      estado: 'Jalisco',
      ciudad: 'Puerto Vallarta',
      calle: 'Heroes',
      nExt: '1392',
      nInt: '194',
      fiscalesCP:'48290',

     },
  }
};

export default (state = initialState, action) => {
switch (action.type) {
   case staffConstants.FETCH_SUCCESS:
        return {...state,...action.payload};
    case staffConstants.FETCH_BY_ID_SUCCESS:
       return {...state,...action.payload};
   default: return state;
}

};
