import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';

import Gantt from '../../components/Gantt';
import Toolbar from '../../components/Toolbar';
//import MessageArea from '../../components/MessageArea';
import { Layout, Menu, Dropdown, Radio, Icon, Tabs, Button, Row, Col, DatePicker, Select, Spin, Alert } from 'antd';
import ASider from "../../components/arquitectos/ASider";
import AHeader from "../../components/arquitectos/AHeader";
import "./styles.scss";

import { ganttActions, gantt2Actions, staffActions, clienteActions  } from "../../actions";

import { Typography } from 'antd';

const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = 'YYYY/MM/DD';
const monthFormat = 'YYYY/MM';

const { Title } = Typography;

//import './App.css';

// static data for our gantt chart
var data;
var data2;





const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const TabPane = Tabs.TabPane;

class App extends Component {
  state = {
    currentZoom: 'Días',
    messages: [],
    filteredStatus:"0",
    filteredProjectType:"0",
    filteredResponsable:"0",
    filteredClient:"0",
    filteredMinDate:moment(moment().subtract(6,'months'), dateFormat),
    filteredMaxDate:moment(moment().add(6,'months'), dateFormat),
    tab:"proyectos",
    data,
    data2,
    fechaFiltrada:false
  };
  componentWillMount(){
      data=[];
      data2=[];
      this.props.fetchAll();
      this.props.fetchAll2();
      this.props.clientefetchAll();
      this.props.stafffetchAll();
      //console.log(JSON.stringify(this.props.gantt));


     // this.props.gantt.remove(null);
     //console.log('datos');

      //console.log(Object.values(this.props.gantt2.data))


  }
  addMessage(message) {
    const maxLogLength = 5;
    const newMessate = { message };
    const messages = [
      newMessate,
      ...this.state.messages
    ];

    if (messages.length > maxLogLength) {
      messages.length = maxLogLength;
    }
    this.setState({ messages });
  }

  logDataUpdate = (type, action, item, id) => {
    let text = item && item.text ? ` (${item.text})` : '';
    let message = `${type} ${action}: ${id} ${text}`;
    if (type === 'link' && action !== 'delete') {
      message += ` ( source: ${item.source}, target: ${item.target} )`;
    }
    this.addMessage(message);
  }

  handleZoomChange = (zoom) => {
    this.setState({
      currentZoom: zoom
    });
  }

  handleTabChange = e => {
    this.setState({ tab: e.target.value });
  };

  handleStatusSelectChange = value => {
      //console.log(`selected ${value}`);
      this.setState({filteredStatus:value});
    }
  handleProjectTypeSelectChange = value => {
     //console.log(`selected ${value}`);
      this.setState({filteredProjectType:value});
    }
  handleResponsableSelectChange = value => {
      //console.log(`selected ${value}`);
      this.setState({filteredResponsable:value});
    }
  handleClientSelectChange = value => {
      //console.log(`selected ${value}`);
      this.setState({filteredClient:value});
    }
  handleDateChange = value =>{
    //console.log('fechas')
    //console.log(value)
    if(this.state.fechaFiltrada==false){this.setState({fechaFiltrada:true})}
    if(value!==undefined)
    {
        this.setState({filteredMinDate:value[0],filteredMaxDate:value[1],});
        ////console.log('ssi entro');
    }else{

    }


}


  render() {
      const menu = (
        <Menu>
          <Menu.Item>
            <a rel="noopener noreferrer" href="#">
              Cerrar sesión
            </a>
          </Menu.Item>

        </Menu>
      );
    const { currentZoom, messages } = this.state;
    var data=[];
    var data2=[];
    data.data=this.props.gantt.data;
    data.links= this.props.gantt.link;
    data2.data=this.props.gantt2.data;
    data2.links= this.props.gantt2.link;
    //data2.data=Object.values(this.props.gantt2.data);
    //console.log(data)
    //data2.links= Object.values(this.props.gantt2.link)
    //var tipos=[{key:'Residencial',label:'Residencial'},{key:'Condominios',label:'Condominios'},{key:'Comercial',label:'Comercial'}];
    if (data2.data!=undefined){
    var tipos=data2.data.filter(function(elem) {
        return !elem.parent
    })
    tipos=tipos.map(function(elem, index) {
        return {key:elem.id.toString(),label:elem.text};
    })}else{tipos=[{key:'Undefined',label:'No se encontraron tipos'}]}
    //console.log(tipos);
    //console.log('contenedor data arriba');
    var staff= this.props.staff.data;
    var cliente= this.props.cliente.clientes;
   //console.log('ganttuser')
   //console.log(this.props.user)
    var staffData=[];
     Object.keys(staff||{}).forEach(function (el) {
         staffData.push({key:staff[el].id,label:staff[el].nombre});
        //staffData.push({key:staff[el].nombre,label:staff[el].nombre});
    });
    var clienteData=[];
     Object.keys(cliente).forEach(function (el) {
         clienteData.push({key:cliente[el].id,label:cliente[el].nombre});
        //clienteData.push({key:cliente[el].nombre,label:cliente[el].nombre});
    });
    //console.log(staffData);
    console.log('@@@@@@data:',data);
    console.log('@@@@@@data2:',data2);

    return (
        <Layout id="sider">
            <ASider/>
            <Layout className='layout-container-antd'>
                <AHeader style={{ background: '#fff', padding:"10px 20px "}} >
                     Proyectos
                 </AHeader>
                <Content style={{ margin: '24px 16px 0', overflowY:'auto' }}>
                    <Row style={{marginBottom:'20px' }} type="flex">
                        <Col span={6}>
                            <Radio.Group value={this.state.tab} onChange={this.handleTabChange}>
                                 <Radio.Button value="proyectos"> <Icon type="project" /> Proyectos</Radio.Button>
                                 <Radio.Button value="tipos" > <Icon type="setting" disabled={this.props.user&&this.props.user.UserInfo.customClaims.role==='admin'?true:false}/> Tipos de proyecto</Radio.Button>

                               </Radio.Group>
                         </Col>
                               { this.state.tab=="proyectos" &&
                                   <Col span={18}>

                                <span className="subtitle"> Filtrar: </span>
                                <Select defaultValue="0" style={{ width: 120 }} onChange={this.handleStatusSelectChange}>
                                      <Option value="0">Status</Option>
                                      <Option value="1">Completada</Option>
                                      <Option value="2">No completada</Option>

                                    </Select>
                                    {/*defaultValue={[this.state.filteredMinDate, this.state.filteredMaxDate]}*/}
                                 <RangePicker

                                         format={dateFormat}
                                         onChange={this.handleDateChange}
                                       />
                                   <Select defaultValue="0" onChange={this.handleProjectTypeSelectChange}>
                                             <Option value="0">Tipo de proyecto</Option>
                                             {tipos.map(function(elem,index) {
                                               //  //console.log(elem)
                                                return <Option value={elem.key}>{elem.label}</Option>
                                             })}
                                           </Select>
                                    <Select defaultValue="0" onChange={this.handleResponsableSelectChange}>
                                         <Option value="0">Responsable</Option>
                                         {staffData.map(function(elem,index) {
                                           //  //console.log(elem)
                                            return <Option value={elem.key}>{elem.label}</Option>
                                         })}
                                   </Select>
                                    <Select defaultValue="0" onChange={this.handleClientSelectChange}>
                                         <Option value="0">Cliente</Option>
                                         {clienteData.map(function(elem,index) {
                                           //  //console.log(elem)
                                            return <Option value={elem.key}>{elem.label}</Option>
                                         })}
                                   </Select>
                               </Col>}



                         <Col span={0}>

                         </Col>
                         <Col span={0}>

                         </Col>
                       </Row>
                  <div>
                    {this.state.tab==="proyectos" && <div className="zoom-bar">
                      <Toolbar
                        zoom={currentZoom}

                        onZoomChange={this.handleZoomChange}
                      />
                    </div>}
                    <div className="gantt-container">
                     {data.data!=undefined&&data2.data!=undefined&&staffData!==undefined&&clienteData!==undefined?<Gantt
                        staff={staffData}
                        cliente={clienteData}
                        tasks={data}
                        tasks2={data2}
                        projectTypes={tipos}
                        zoom={currentZoom}
                        onChange={this.props.UpdateGantt}
                        onChange2={this.props.UpdateGantt2}
                        onDataUpdated={this.logDataUpdate}
                        onDataUpdated2={this.logDataUpdate}
                        filteredResponsable={this.state.filteredResponsable}
                        filteredClient={this.state.filteredClient}
                        filteredProjectType={this.state.filteredProjectType}
                        filteredStatus={this.state.filteredStatus}
                        filteredMinDate={this.state.fechaFiltrada?(this.state.filteredMinDate!==undefined?this.state.filteredMinDate.format():undefined):undefined}
                        filteredMaxDate={this.state.fechaFiltrada?(this.state.filteredMaxDate!==undefined?this.state.filteredMaxDate.format():undefined):undefined}
                        tab={this.state.tab}
                        role={this.props.user?this.props.user.UserInfo.customClaims.role:0}
                        uid={this.props.user?this.props.user.UserInfo.uid:0}
                      />:<Spin tip="Loading...">
                            <Alert
                              message="Cargando proyectos"
                              description="En caso de un error, recargue el sitio."
                              type="info"
                            />
                          </Spin>

                     }
                    </div>
                    {/*<MessageArea
                      messages={messages}
                    />*/}
                  </div>
              </Content>
          </Layout>
        </Layout>
    );
  }
}


const mapStateToProps = state => {
  return {
  /*cpu: state.cpu.CPUS,
    rev: state.rev.REVS,
    auxi: state.auxi.AUXIS,*/
    gantt: state.gantt,
    gantt2: state.gantt2,
    staff:state.staff,
    cliente:state.cliente,
    user:state.auth.user,
  };
};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAll: ganttActions.fetchAll,
      fetchAll2: gantt2Actions.fetchAll,
      stafffetchAll:staffActions.fetchAll,
      clientefetchAll:clienteActions.fetchAll,
      UpdateGantt:ganttActions.formSave,
      UpdateGantt2:gantt2Actions.formSave,

    },
    dispatch
  );

export default connect(mapStateToProps,mapDispatchToProps)(App);
