/*
Aqui se hacen peticiones tanto al api como a firebase

*/
import firebase from 'firebase';
import { staffConstants, authConstants } from "../constants";
import { toast } from "react-toastify";
import axios from 'axios';

const fetchAll = () => {
	return (dispatch) => {

      firebase.database().ref(`/staffs/`)
   	 .on('value', snapshot => {
   		 const preData=Object.values(snapshot.val());
		 const data=preData.filter(d => d.status==1 || d.status==undefined)

   		 console.log("fetch all exitoso");
   	   dispatch({ type: staffConstants.FETCH_SUCCESS, payload: {data:data} });
   	 });
    };
};

const formSave = ( staff, redirect) => {
	  console.log(staff);
	  return (dispatch) => {
	  firebase.auth().currentUser.getIdToken().then(function(idToken) {  // <------ Check this line
	      console.log(idToken); // It shows the Firebase token now
	      // Send a POST request
	      axios.defaults.headers.common['Authorization'] = idToken;
	      axios({
	            method: 'post',
	            url: authConstants.API,
	            headers: {'Authorization': 'Bearer '+idToken},
	            data: {
	              displayName: staff.nombre,
	              email:staff.email,
	              role:staff.role,
	              password: staff.password,
	            }
	        }).then(function(response){
	            console.log('entro')
	            console.log(response)
	            //const id= Math.round(Math.random() * 36 ** 12).toString(36);

	                console.log('entro a disp')
	              firebase.database().ref(`/staffs/${response.data.uid}/`)
	                .set({ id: response.data.uid, status:1, ...staff })
	                .then(() => {
	                  dispatch({ type: staffConstants.ADD_SUCCESS });
	                    toast.success("Guardado");
	                    redirect();
	                  //Actions.employeeList({ type: 'reset' });
	                });

	          }).catch(function(error){toast.error(error.response.data.message);});
	    });
	};

};

const update = ( staff, redirect ) => {
  console.log("@@@@ actualizando:",staff);
 // const id= Math.round(Math.random() * 36 ** 12).toString(36);
  return (dispatch) => {
    firebase.database().ref(`/staffs/${staff.id}`)
      .update({ ...staff })
      .then((response) => {

				dispatch({ type: staffConstants.UPDATE_SUCCESS });
		          toast.success("Guardado");
				  redirect();

        //Actions.employeeList({ type: 'reset' });
      });
  };
};

const fetchByID = (uid) => {
	return (dispatch) => {

      firebase.database().ref(`/staffs/${uid}`)
   	 .on('value', snapshot => {
   		 const data=snapshot.val();
		 //const data=preData.filter(d => d.status==1)

   		 console.log("fetch by id exitoso");
		 console.log(data);
		    		 console.log("fetch by id exitoso");
   	   dispatch({ type: staffConstants.FETCH_BY_ID_SUCCESS, payload: {byID:data} });
   	 });
    };
};


const deleteStaff = (id) =>{
    return (dispatch) => {
      firebase.database().ref(`/staffs/${id}/`)
        .remove()
        .then(() => {
          dispatch({ type: staffConstants.REMOVE_SUCCESS });
            toast.warning("Eliminado");
          //Actions.employeeList({ type: 'reset' });
        });
    };

}


/*const remove = (uid) => {
	return(dispatch) =>{

			firebase.database().ref(`/staffs/${uid}/`)
			  .update({"status":0}).then(()=>{
				  dispatch({ type: staffConstants.REMOVE_SUCCESS });
					toast.success("Eliminado");
			  })

  	}
};*/


const remove = ( staff, redirect) => {
  console.log(staff);
  return (dispatch) => {
  firebase.auth().currentUser.getIdToken().then(function(idToken) {  // <------ Check this line
      console.log(idToken); // It shows the Firebase token now
      // Send a POST request
      axios.defaults.headers.common['Authorization'] = idToken;
      axios({
            method: 'delete',
            url: authConstants.API+staff,
            headers: {'Authorization': 'Bearer '+idToken},

        }).then(function(response){
            console.log('entro')
            console.log(response)
            //const id= Math.round(Math.random() * 36 ** 12).toString(36);

                console.log('entro a disp')



                firebase.database().ref(`/staffs/${response.data.uid}/`)
                  .update({"status":0}).then(()=>{
                      dispatch({ type: staffConstants.REMOVE_SUCCESS });
                        toast.success("Eliminado");
                  })

          }).catch(function(error){toast.error(error.response.data.message);});
    });
}};




export const staffActions = {
  fetchAll, formSave, deleteStaff,remove, update, fetchByID
};
