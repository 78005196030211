import app from 'firebase/app';

const config = {
  apiKey: "AIzaSyCOcGVsE5B5nUBJx4DWKZc_HKUd2nj2eBE",
  authDomain: "arquitectos-88d9d.firebaseapp.com",
  databaseURL: "https://arquitectos-88d9d.firebaseio.com",
  projectId: "arquitectos-88d9d",
  messagingSenderId: "144864439045",
  appId: "1:144864439045:web:3a63abea2b7f5e85",
  storageBucket: "arquitectos-88d9d.appspot.com",
}

/*const config = {
  apiKey: "AIzaSyA8TmukuOk0H69S5fnMkQqmw5io3vTjuvE",
  authDomain: "arquitectos-11fa5.firebaseapp.com",
  databaseURL: "https://arquitectos-11fa5.firebaseio.com",
  projectId: "arquitectos-11fa5",
  messagingSenderId: "403886094686",
  appId: "1:403886094686:web:0daa2fd829e3f7d77f37cf",
  storageBucket: "",
};*/

class Firebase {
  constructor() {
    app.initializeApp(config);
  }
}

export default Firebase;
