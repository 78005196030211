import React, { Component } from 'react';
import {   authActions } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Form, Icon, Input, Button, Checkbox } from 'antd';
import "./styles.scss";


class NormalLoginForm extends Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.loginUser(values,this.props.handleSubmit)
      }
    });
  };

  /*handleCreate = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);
      this.props.clienteSave(values)
      form.resetFields();

    });
  };*/



  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{type: 'email', message:'Por favor ingresa un correo válido'},
            { required: true, message: 'Por favor ingresa tu correo de usuario' },],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Por favor ingresa tu contraseña' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Contraseña"
            />,
          )}
        </Form.Item>
        {/*
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)}
         {/* <a className="login-form-forgot" href="">
            Forgot password
          </a>*/}
          <Form.Item>
          <Button id="submitButton" type="primary" htmlType="submit" className="login-form-button">
            Ingresar
          </Button>
          {/*Or <a href="">register now!</a>*/}
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
  //  cpu: state.cpu.CPUS,

  };
};




const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
     // fetchAllCPU: cpuActions.fetchAllCPU,

      loginUser:authActions.loginUser,
    },
    dispatch
  );

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default connect(mapStateToProps,mapDispatchToProps)(WrappedNormalLoginForm);
