import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Tabs, Button, Row, Dropdown, } from 'antd';
import "./styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withRouter} from 'react-router-dom'

//import * as logo from "./aA.svg";

import {  staffActions, authActions } from "../../../actions";


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;



class AHeader extends React.Component {

    componentWillMount(){

    }

  render(){
     // console.log('usuario')
      //console.log(this.props.user.UserInfo)
      const menu = (
        <Menu>
          <Menu.Item>
            <a rel="noopener noreferrer" href="#">
              Cerrar sesión
            </a>
          </Menu.Item>

        </Menu>
      );
    return(
        <Header style={{ background: '#fff', padding:"10px 20px "}} >
             <Row type="flex" justify="space-between">
                <span className="titleHeader"> {this.props.children} </span>
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link">
                                <Icon type="user" />{this.props.user?this.props.user.UserInfo.displayName:""} - {this.props.user?this.props.user.UserInfo.customClaims.role:""}<Icon type="down" />
                        </a>
                      </Dropdown>
            </Row>
            </Header>);
        };

}

const mapStateToProps = state => {
  return {
  /*  cpu: state.cpu.CPUS,
    rev: state.rev.REVS,
    auxi: state.auxi.AUXIS,*/
//    gantt: state.gantt,
//    gantt2: state.gantt2,
//    staff:state.staff,
//    cliente:state.cliente,
    user:state.auth.user

  };
};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
     // fetchAll: ganttActions.fetchAll,
      //fetchAll2: gantt2Actions.fetchAll,
     // stafffetchAll:staffActions.fetchAll,
     // clientefetchAll:clienteActions.fetchAll,
    //  UpdateGantt:ganttActions.formSave,
    //  UpdateGantt2:gantt2Actions.formSave,

    },
    dispatch
  );

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AHeader));
