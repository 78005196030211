import React from "react";
import "./styles.scss";
import { Button, Typography, Row, Col, Table,Input, Form } from "antd";
  import WrappedDynamicFieldSet from "./buttonList";
const { Title } = Typography;



class ServiciosTabContent extends React.Component {
    constructor(props){
       super(props)


          this.state = {
            procesoActivo:0,
            procesos: {
              fields: {
                names: {
                  value: ['Preliminares','Anteproyecto', 'Desarrollo de proyecto' ],
                },
                keys:{
                  value: [0,1,2]
                }
              }
            },
            subprocesos: {
              0:{
                  fields: {
                    names: {
                      value: ['Proyecto Ejecutivo','Control de planos','Plantas'],
                    },
                    keys:{
                      value: [0,1,2]
                  }
                }
              },
              1:{
                  fields: {
                    names: {
                      value: ['Proyecto Ejecutivo',],
                    },
                    keys:{
                      value: [0,]
                  }
                }
              },
              2:{
                  fields: {
                    names: {
                      value: ['Proyecto Ejecutivo','Control de planos','Plantas'],
                    },
                    keys:{
                      value: [0,1,2]
                  }
                }
              }
            },

          };

  }
    handleProcesoOnClick = row => {
      this.setState({procesoActivo:row});

    };
    handleProcesoFormChange = changedFields => {

      //console.log('ultimo valor',changedFields.keys.value);
     this.setState(({ procesos:{fields} }) => ({
       procesos:{fields: { ...fields, ...changedFields }},
     }));

     console.log('ajalas',changedFields);
     console.log('ultima llave', changedFields.keys.value[Object.keys(changedFields.keys.value).length-1]);
     const ultimakey = changedFields.keys.value[Object.keys(changedFields.keys.value).length-1];

     const nuevoSubProceso = {
             names: {
               value: [],
             },
             keys:{
               value: [],
                     }
     }


     //console.log('aver',this.state.subprocesos[ultimakey].fields);
     this.state.subprocesos[ultimakey]===undefined?
      this.setState({subprocesos:{...this.state.subprocesos,[ultimakey]:{fields: {  ...nuevoSubProceso }}}})
      :console.log('ya existia');
      console.log('ketransa',this.state);
      this.state.procesoActivo=ultimakey?this.setState({procesoActivo:99}):console.log('no se cambio proceso activo')

   };
   handleSubProcesoOnClick = row => {
     this.setState({subprocesoActivo:row});
   };
   handleSubProcesoFormChange = changedFields => {

    this.setState(({ subprocesos:{[this.state.procesoActivo]:{fields}}}) => ({
       subprocesos:{...this.state.subprocesos,[this.state.procesoActivo]:{fields: { ...fields, ...changedFields }}},
    }));
  };

    render(){
    //  const  REVdataSource  = this.props.rev.REVrows;
      //const CPUdataSource = this.props.cpu.CPUrows;
      const { procesos, subprocesos } = this.state;
      console.log('ola',this.state);
      {/*const REVcolumns = this.props.rev.REVcolumns.map(col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      });*/}
        return (
        <div className='catalogo-tab'>

              <Row>

                <Col span={7}>
                  <Title level={2}>Procesos</Title>
              {/*
                <Button onClick={this.handleColumnAdd} type="primary" style={{ marginBottom: 16 }}>
                  Añadir columna
                  </Button>*/}

                <WrappedDynamicFieldSet  {...procesos.fields} onChange={this.handleProcesoFormChange} handleOnClick={this.handleProcesoOnClick}/>
                </Col>

                <Col offset = {1} span={7}>

                <Title level={2}>Subprocesos</Title>
                {subprocesos[this.state.procesoActivo]?
                <WrappedDynamicFieldSet  {...subprocesos[this.state.procesoActivo].fields} onChange={this.handleSubProcesoFormChange} handleOnClick={this.handleSubProcesoOnClick}/>:""
                }

                </Col>
                <Col offset = {1} span={7}>
                 <Title level={2}>Entregables</Title> </Col>


              </Row>




              <Row>
              {/*<Button onClick={ this.HandleAllCPUSave } type="primary" style={{ margin: 16 }}>
                  Guardar
                </Button>*/}
              </Row>
        </div>

        );
      }
}

export default ServiciosTabContent;
