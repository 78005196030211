import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import  WrappedNormalLoginForm from "./loginForm";
import {withRouter} from 'react-router-dom'
import Logo from './aA.png'

import { Layout, Menu, Icon, Tabs, Button, Row, Col, DatePicker, Select, Card, Avatar, Form, Input, Checkbox } from 'antd';

import "./styles.scss";


import { Typography } from 'antd';

const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;


const { Meta } = Card;

const { Title } = Typography;



class App extends Component {
  state = {


  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };
  handleSubmit = e => {
    this.props.history.push('/Gantt');
  };

  render() {


    return (
        <Layout id="sider">

            <Layout className='layout-container-antd'>
                <Header style={{ background: '#fff', padding:"10px 20px "}} >  <span className="title"> Login </span> </Header>
                <Content style={{ margin: '24px 16px 0', overflowY:'auto' }}>
                    <Row style={{height:'100%'}} type="flex" justify="center" align="middle">
                         <Col span={4}>
                            <Card
                                style={{ width: 300 }}
                                cover={
                                  <img
                                    alt="example"
                                    src={Logo}
                                  />
                                }

                              >

                              <WrappedNormalLoginForm handleSubmit={this.handleSubmit}/>



                              </Card>
                          </Col>
                      </Row>
              </Content>
          </Layout>
        </Layout>
    );
  }
}


const mapStateToProps = state => {
  return {

  };
};




const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {



    },
    dispatch
  );

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App));
