import React from "react";
import CatalogosTabContent from "../../components/arquitectos/CatalogosTabContent";
import ASider from "../../components/arquitectos/ASider";

import { Layout, Menu, Icon, Tabs, Button, Modal, Form, Input} from 'antd';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { cpuActions, revActions, auxiActions, paneActions } from "../../actions";

const {confirm} = Modal;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const TabPane = Tabs.TabPane;


// importing todo components

// importing our todo's action's

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(

  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form,  } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Crear nuevo elemento"
          okText="Crear"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: 'Ingresa el nombre de tu elemento' }],
              })(<Input />)}
            </Form.Item>

          </Form>
        </Modal>
      );
    }
  },
);





class Catalogos extends React.Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    console.log(props.cpu);
  const panes = [    ];
    this.state = {
      activeKey: '0',
      panes,
	  visible:false,
    };
  }


////////////////////////////////////////////////////////////////////////////
/////////////////////////////Modal
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
	this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
 	 if (err) {
 	   return;
 	 }

 	 console.log('Received values of form: ', values);
 	 form.resetFields();
 	 this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };


  addModal = () => {
	const modalForm= this.formRef.props.form;
	let name= '';
	modalForm.validateFields((err, values) => {
	  if (err) {
		return;
	  }

	  console.log('Received values of form: ', values);
	  name=values.title;

	  modalForm.resetFields();
	  this.props.addPane(name)

	  this.setState({ visible: false });




	});


  };


  //////////////////////////////////////////////////////////////////





  showDeleteConfirm(id) {

    confirm({
      title: 'Desea eliminar el elemento?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
		  this.props.removePane(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  componentWillMount(){
    this.props.fetchAllCPU();
    this.props.fetchAllREV();
    this.props.fetchAllAUXI();
    this.props.fetchAllPanes();
  }

  HandleUpdateCPURow = row => this.props.updateRowCPU(row);
  HandleUpdateREVRow = row => this.props.updateRowREV(row);
  HandleUpdateAUXISRow = row => this.props.updateRowAUXI(row);
  HandleCPUSave = cpu => this.props.CPUSave(cpu);

  onChange = (activeKey) => {
    this.setState({ activeKey });
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }
  add = () => {

    //const panes = this.state.panes;
    //const activeKey = `newTab${this.newTabIndex++}`;
    //panes.push({ title: 'A'+(this.newTabIndex+3), content: <CatalogosTabContent/>, key: activeKey });
    //this.setState({ panes, activeKey });
	this.showModal();
	//this.props.addPane('Nueva Pestaña')
  }





  remove = (targetKey) => {
	 if(targetKey!=1 & targetKey!=2 & targetKey!=0){
		 this.showDeleteConfirm(targetKey);}
    //this.props.removePane(targetKey);
  }

render()
{
  console.log("copia esto wwe")
  console.log(JSON.stringify(this.props.rev['2'].rows))
  console.log(JSON.stringify(this.props.cpu['2'].rows))
  console.log(JSON.stringify(this.props.auxi['2'].rows))

 // console.log(this.props.auxi['2'])
 // console.log(this.props.cpu['2'])
 //  console.log(this.props.rev['2'])


 /* var panes = [
    { title: 'A', content: <CatalogosTabContent    HandleUpdateAUXISRow={this.HandleUpdateAUXISRow} HandleUpdateREVRow={this.HandleUpdateREVRow}  HandleCPUSave={this.HandleCPUSave} HandleUpdateCPURow={this.HandleUpdateCPURow}  auxi={this.props.auxi['0']} rev={this.props.rev['0']} cpu={this.props.cpu['0']} cpuKey={0} />, key: '0' },
    { title: 'AA', content: <CatalogosTabContent  HandleUpdateAUXISRow={this.HandleUpdateAUXISRow} HandleUpdateREVRow={this.HandleUpdateREVRow} HandleCPUSave={this.HandleCPUSave} HandleUpdateCPURow={this.HandleUpdateCPURow} auxi={this.props.auxi['1']} rev={this.props.rev['1']} cpu={this.props.cpu['1']} cpuKey={1} />, key: '1' },
    { title: 'AAA', content: <CatalogosTabContent  HandleUpdateAUXISRow={this.HandleUpdateAUXISRow} HandleUpdateREVRow={this.HandleUpdateREVRow} HandleCPUSave={this.HandleCPUSave} HandleUpdateCPURow={this.HandleUpdateCPURow} auxi={this.props.auxi['2']} rev={this.props.rev['2']} cpu={this.props.cpu['2']} cpuKey={2} />, key: '2' },
];*/

	const panes= this.props.panes.map((pane)=> {
		const returnable = { title: pane.name, content: <CatalogosTabContent
		   HandleUpdateAUXISRow={this.HandleUpdateAUXISRow} HandleUpdateREVRow={this.HandleUpdateREVRow}
		    HandleCPUSave={this.HandleCPUSave} HandleUpdateCPURow={this.HandleUpdateCPURow}  auxi={this.props.auxi[pane.id]}
		 rev={this.props.rev[pane.id]} cpu={this.props.cpu[pane.id]} cpuKey={pane.id} />, key: pane.id };
		return returnable
	})


  return (
    <Layout id="sider">
      <ASider/>
      <Layout className='layout-container-antd'>
        <Header style={{ background: '#fff', padding: 0 }} />
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, background: '#fff', height:'100%' }}>

              <div style={{ marginBottom: 16 }}>
                {/*<Button onClick={this.add}>Nueva tarifa</Button>*/}
              </div>
              <Tabs
			   onEdit={this.onEdit}
                closable={false}
                onChange={this.onChange}
                activeKey={this.state.activeKey}
                type="editable-card"

                style={{  display: 'flex',
                flexDirection: 'column',height:'100%'}}
              >
                {panes.map(pane => <TabPane style={{position:'absolute', height: '100%'}} tab={pane.title} key={pane.key}>{pane.content}</TabPane>)}
              </Tabs>
          </div>
        </Content>

      </Layout>
	  <CollectionCreateForm
		wrappedComponentRef={this.saveFormRef}
		visible={this.state.visible}
		onCancel={this.handleCancel}
		onCreate={this.addModal}
	  />


    </Layout>
  );
};
}

const mapStateToProps = state => {
  return {
    cpu: state.cpu.CPUS,
    panes: state.pane.data,
    rev: state.rev.REVS,
    auxi: state.auxi.AUXIS,
  };
};

Catalogos.propTypes = {
  cpu: PropTypes.array.isRequired,
  updateRowCPU: PropTypes.func.isRequired,
};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addCPUrow: cpuActions.addCPUrow,
      removeCPUrow: cpuActions.removeCPUrow,
      updateRowCPU: cpuActions.updateRowCPU,
      updateRowREV: revActions.updateRowREV,
      updateRowAUXI: auxiActions.updateRowAUXI,
      fetchAllCPU: cpuActions.fetchAllCPU,
      fetchAllREV: revActions.fetchAllREV,
      fetchAllAUXI: auxiActions.fetchAllAUXI,
	  fetchAllPanes:paneActions.fetchAll,
      CPUSave: cpuActions.CPUSave,
	  addPane:paneActions.add,
	  removePane:paneActions.remove,



    },
    dispatch
  );

export default connect(mapStateToProps,mapDispatchToProps)(Catalogos);
