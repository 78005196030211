 import { auxiConstants } from "../constants";

const initialState = {
  AUXIS:{
        0:{ id: 0,
            columns: [
          {
            title: 'Numero de auxiliares',
            dataIndex: 'n',
            key: 'n',
            editable:false,

          },
          {
            title: 'Incremento de presupuesto',
            dataIndex: 'inc',
            key: 'inc',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                inc: '2%',
                n: 1,

              },
              {
                key: '1',
                inc: '4%',
                n: 2,

              },
              {
                key: '2',
                inc: '10%',
                n: 3,

              },
              {
                key: '3',
                inc: '15%',
                n: 4,

              },


            ],
        },
        1:{ id: 1,
            columns: [
          {
            title: 'Numero de auxiliares',
            dataIndex: 'n',
            key: 'n',
            editable:false,

          },
          {
            title: 'Incremento de presupuesto',
            dataIndex: 'inc',
            key: 'inc',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                inc: '2%',
                n: 1,

              },
              {
                key: '1',
                inc: '4%',
                n: 2,

              },
              {
                key: '2',
                inc: '10%',
                n: 3,

              },
              {
                key: '3',
                inc: '15%',
                n: 4,

              },


            ],
        },
        2:{ id: 2,
            columns: [
          {
            title: 'Numero de Auxiliares',
            dataIndex: 'n',
            key: 'n',
            editable:false,

          },
          {
            title: 'Incremento de presupuesto',
            dataIndex: 'inc',
            key: 'inc',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                inc: '2%',
                n: 1,

              },
              {
                key: '1',
                inc: '4%',
                n: 2,

              },
              {
                key: '2',
                inc: '10%',
                n: 3,

              },
              {
                key: '3',
                inc: '15%',
                n: 4,

              },


            ],
        },

    }

};

export default (state = initialState, action) => {
  switch (action.type) {
    case auxiConstants.FETCH_SUCCESS:
    return action.payload;

    case auxiConstants.ADD_SUCCESS:
      return Object.assign({}, state, {
        rows: state.rows.concat(action.payload)
      });
      case auxiConstants.UPDATE_ROW:
        var newData=state.AUXIS[action.payload.cpuKey];
        newData=newData.rows.map(
          row =>{

              if(row.key===action.payload.key){
                return {...row, [action.payload.prop]:action.payload.value}
              }
              return row;
            }
                 );

        return { ...state, AUXIS:{
                ...state.AUXIS,
                  [action.payload.cpuKey]: {
                    ...state.AUXIS[action.payload.cpuKey],
                    rows:newData
                  }
              //  ...row, completed: !row.completed
        }
      };
    case auxiConstants.REMOVE_SUCCESS:
      return {
        rows: state.rows.filter(row => row.id !== action.payload)
      };
    default:
      return state;
  }
};
