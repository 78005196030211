import React from "react";
import ProyectosTable from "../../components/arquitectos/ProyectosListContent";
import ASider from "../../components/arquitectos/ASider";
import AHeader from "../../components/arquitectos/AHeader";

import { Layout, Menu, Icon, Tabs, Button, } from 'antd';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { cpuActions, revActions, auxiActions, clienteActions, proyectoActions, } from "../../actions";

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const TabPane = Tabs.TabPane;


// importing todo components

// importing our todo's action's



class clientesList extends React.Component {
  constructor(props) {
    super(props);

    //console.log(props.cpu);

  }

  componentWillMount(){
    /*    this.props.fetchAllCPU();
    this.props.fetchAllREV();
    this.props.fetchAllAUXI();*/
    this.props.fetchAllProyectos();
    this.props.fetchAllClientes();

  }

  /*HandleUpdateCPURow = row => this.props.updateRowCPU(row);
  HandleUpdateREVRow = row => this.props.updateRowREV(row);
  HandleUpdateAUXISRow = row => this.props.updateRowAUXI(row);
  HandleCPUSave = cpu => this.props.CPUSave(cpu);*/

  onChange = (activeKey) => {
    this.setState({ activeKey });
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }

  remove = (targetKey) => {
    let activeKey = this.state.activeKey;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter(pane => pane.key !== targetKey);
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = panes[lastIndex].key;
    }
    this.setState({ panes, activeKey });
  }

render()
{

  //console.log("copia esto we")
  //console.log(JSON.stringify(this.props.cpu))
  let data = [
    {
      id: 0,
      nombre:'KAREN MALACARA',
      movil:'3221173023',
      email:'micorreo@gmail.com',
      proyectoDireccion:'16 de octubre #1493',
      cp:'48290',
      empresaNombre:'Empresa s.a de c.v',
      empresaTelefono: '2990181',
      clienteDireccion:'1ro de Mayo #145',
      empresaDireccion: 'Indefinida',
      empresaCP: '45320',
      razonSocial: 'Miempresa S.A de C.V ',
      rfc: '499230',
      estado: 'Jalisco',
      ciudad: 'Puerto Vallarta',
      calle: 'Heroes',
      nExt: '1392',
      nInt: '194',
      fiscalesCP:'48290',

     },
  ];
  //var obj = {"1":5,"2":7,"3":0,"4":0,"5":0,"6":0,"7":0,"8":0,"9":0,"10":0,"11":0,"12":0}

 // console.log(this.props.cliente);
    const proyecto= this.props.proyectos;
   data = Object.keys(this.props.proyectos).map(function(key) {
     //console.log(proyecto[key]);
    return { ...proyecto[key]};
  });
//console.log(data);

  return (
    <Layout id="sider">
      <ASider/>
      <Layout className='layout-container-antd'>
          <AHeader >Lista de Proyectos</AHeader>
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, background: '#fff', height:'100%' }}>

              <div style={{ marginBottom: 16 }}>
                {/*<Button onClick={this.add}>Nueva tarifa</Button>*/}
              </div>
              <ProyectosTable remove={this.props.remove} update={this.props.update} data={data}/>
          </div>
        </Content>

      </Layout>
    </Layout>
  );
};
}

const mapStateToProps = state => {
  return {
  /*  cpu: state.cpu.CPUS,
    rev: state.rev.REVS,
    auxi: state.auxi.AUXIS,*/
    cliente: state.cliente.data,
    proyectos: state.proyecto.data,
  };
};

// clientesList.propTypes = {
//   cpu: PropTypes.array.isRequired,
//   updateRowCPU: PropTypes.func.isRequired,
// };


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    /*  addCPUrow: cpuActions.addCPUrow,
      removeCPUrow: cpuActions.removeCPUrow,
      updateRowCPU: cpuActions.updateRowCPU,
      updateRowREV: revActions.updateRowREV,
      updateRowAUXI: auxiActions.updateRowAUXI,
      fetchAllCPU: cpuActions.fetchAllCPU,
      fetchAllREV: revActions.fetchAllREV,
      fetchAllAUXI: auxiActions.fetchAllAUXI,
      CPUSave: cpuActions.CPUSave,*/

      fetchAllClientes: clienteActions.fetchAll,
      fetchAllProyectos: proyectoActions.fetchAll,

	  remove: proyectoActions.remove,
	  update: clienteActions.formUpdate,

    },
    dispatch
  );

export default connect(mapStateToProps,mapDispatchToProps)(clientesList);
