import firebase from 'firebase';
import { gantt2Constants } from "../constants";
import { toast } from "react-toastify";

const fetchAll = () => {
  return (dispatch) => {
    firebase.database().ref(`/gantts/2/`)
      .on('value', snapshot => {
          const data=Object.values(snapshot.val().data);
          const links=Object.values(snapshot.val().link);
          console.log(data);
        dispatch({ type: gantt2Constants.FETCH_SUCCESS, payload: {data:data,link:links} });
      });
  };
};

const formSave = ( gantt ) => {
  console.log(gantt);
if(gantt.type=="task"){
    if(gantt.action=="delete")
      return (dispatch) => {
        firebase.database().ref(`/gantts/2/data/${gantt.id}/`)
          .remove()
          .then(() => {
            dispatch({ type: gantt2Constants.ADD_SUCCESS });
              //toastsuccess("Eliminado");
            //Actions.employeeList({ type: 'reset' });
          });
      };
    else{
        return (dispatch) => {
          firebase.database().ref(`/gantts/2/data/${gantt.id}/`)
            .set({ id: gantt.id, ...gantt.item })
            .then(() => {
              dispatch({ type: gantt2Constants.ADD_SUCCESS });
                //toastsuccess("Guardado");
              //Actions.employeeList({ type: 'reset' });
            });
        };
    }
}else{
    if(gantt.action=="delete")
        return (dispatch) => {
          firebase.database().ref(`/gantts/2/link/${gantt.id}/`)
            .remove()
            .then(() => {
              dispatch({ type: gantt2Constants.ADD_SUCCESS });
                //toastsuccess("Guardado");
              //Actions.employeeList({ type: 'reset' });
            });
    };
    else{
        return (dispatch) => {
          firebase.database().ref(`/gantts/2/link/${gantt.id}/`)
            .set({ id: gantt.id, ...gantt.item })
            .then(() => {
              dispatch({ type: gantt2Constants.ADD_SUCCESS });
                //toastsuccess("Guardado");
              //Actions.employeeList({ type: 'reset' });
            });
        };
    }
  }

//  const id= Math.round(Math.random() * 36 ** 12).toString(36);
/**/
};


export const gantt2Actions = {
  fetchAll, formSave,
};
