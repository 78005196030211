import firebase from 'firebase';
import { proyectoConstants, clienteConstants } from "../constants";
import { toast } from "react-toastify";


const fetchAll = () => {
	return (dispatch) => {

      firebase.database().ref(`proyectos/`)
   	 .on('value', snapshot => {
   		 const preData=Object.values(snapshot.val());
		 const data=preData.filter(d => d.status==1)

   		 console.log(data);
   	   dispatch({ type: proyectoConstants.FETCH_SUCCESS, payload: {data:data} });
   	 });
    };
};

const fetchAllCompleted = () => {
	return (dispatch) => {

      firebase.database().ref(`proyectos/`)
   	 .on('value', snapshot => {
   		 let preData=Object.values(snapshot.val());
		let preData2= preData.map(elem=> {
			if(elem.subproyectos!==undefined){
				//alert(typeof(elem.subproyectos))
				const subElem= Object.values(elem.subproyectos).filter(d => d.status===1)

				if(subElem.length<1){
					//alert(subElem.length)
					elem.status=0}
			}else{elem.status=0}
			return elem;
		 });
		 const data=preData2.filter(d => d.status===1  && d.gantt2_id!==-1)

   		 console.log(data);
   	   dispatch({ type: proyectoConstants.FETCH_SUCCESS, payload: {dataCompleted:data} });
   	 });
    };
};


const fetchSoftDeleted = () => {
	return (dispatch) => {

      firebase.database().ref(`proyectos/`)
   	 	.on('value', snapshot => {
   		 	const data=Object.values(snapshot.val());
   		 	console.log(data);
   	   		dispatch({ type: proyectoConstants.FETCH_SUCCESS, payload: {data:data} });
   	 });
    };

};



const remove = (uid) => {
	return(dispatch) =>{
		if(uid!=0 & uid!=1 & uid!=2){
			firebase.database().ref(`proyectos/${uid}/`)
			  .update({"status":0}).then(()=>{
				  dispatch({ type: proyectoConstants.REMOVE_SUCCESS });
					toast.success("Eliminado");
			  })
		}
  	}
};

const add = (data) => {
	return (dispatch) => {
	  firebase.database().ref(`proyectos/`)
		.push({"name":data, "gantt2_id":-1,subproyectos:[],status:1})
		.then((response) => {
			console.log(response.path.pieces_[1])
			const uid= response.path.pieces_[1];
			firebase.database().ref(`/proyectos/${uid}/`)
			  .update({ id: uid })
			  .then(() => {
			dispatch({ type: proyectoConstants.ADD_SUCCESS });
			toast.success("Creado");
		});
				//Actions.employeeList({ type: 'reset' });
			  });
		  //Actions.employeeList({ type: 'reset' });
	  	}};

		const formSave = ( cliente,proyecto,redirect) => {
		 // console.log(cliente);
		 // const id= Math.round(Math.random() * 36 ** 12).toString(36);
		  return (dispatch) => {
			firebase.database().ref(`/clientes/`)
			  .push({ ...cliente, status:2 })
			  .then((response) => {
				  const uid= response.path.pieces_[1];
				  firebase.database().ref(`/clientes/${uid}/`)
					.update({ id:uid, status:1 }).then(()=>{
						firebase.database().ref(`/proyectos/`).push({ ...proyecto,...cliente,cliente_id:uid, status:2 }).then(pro_response=>{
							const pid= pro_response.path.pieces_[1];
							firebase.database().ref(`/proyectos/${pid}/`)
						   .update({ id:pid, status:1 }).then(()=>{
								dispatch({ type: proyectoConstants.ADD_SUCCESS });
						   })
							toast.success("Guardado");
							redirect();

						})

					})

				//Actions.employeeList({ type: 'reset' });
			  });
		  };
		};

const update = (uid, changes) => {
	return (dispatch) => {
	  firebase.database().ref(`proyectos/${uid}`)
		.update({...changes})
		.then((response) => {

			dispatch({ type: proyectoConstants.UPDATE_SUCCESS });
			//toast.success("ctualizado");


				//Actions.employeeList({ type: 'reset' });
			  });
		  //Actions.employeeList({ type: 'reset' });
		}};


		const addSub = (uid, data) => {
			 console.log(uid)
			return (dispatch) => {
			  firebase.database().ref(`proyectos/${uid}/subproyectos/`)
				.push({...data,status:0})
				.then((response) => {

					const sid= response.path.pieces_[3];
					console.log( response.path.pieces_);
					firebase.database().ref(`/proyectos/${uid}/subproyectos/${sid}`)
					  .update({ id: sid, status:1 })
					  .then(() => {
						  dispatch({ type: proyectoConstants.ADD_SUCCESS });
						  //toast.success("ctualizado");
						});

						//Actions.employeeList({ type: 'reset' });
					  });
				  //Actions.employeeList({ type: 'reset' });
				}};

				const removeSub = (uid,sid, data) => {
					 console.log(uid)
					return (dispatch) => {
					  firebase.database().ref(`proyectos/${uid}/subproyectos/${sid}`)
							  .update({ status:0 })
							  .then(() => {
								  dispatch({ type: proyectoConstants.REMOVE_SUCCESS });
								  //toast.success("ctualizado");
								});

								//Actions.employeeList({ type: 'reset' });

						  //Actions.employeeList({ type: 'reset' });
						}};

			const fetchByID_especial = (uid, superficie, cos, cus) => {
						return (dispatch) => {

					      firebase.database().ref(`/proyectos/${uid}`)
					   	 .on('value', snapshot => {
					   		 const data=snapshot.val();
							// const  subproyectosFiltered=Object.values(data.subproyectos).filter(d => d.status==1)
							 //const data=preData.filter(d => d.status==1)

							Object.filter = (obj, predicate) =>
							  Object.keys(obj)
							          .filter( key => predicate(obj[key]) )
							          .reduce( (res, key) => (res[key] = obj[key], res), {} );

							// Example use:

							let subproyectosFiltered = Object.filter(data.subproyectos, subproyecto => subproyecto.status == 1);
							let x=0;
							Object.keys(subproyectosFiltered).map((elem,index)=>{
								if(x===0){
									subproyectosFiltered[elem].dimensiones=superficie*cos;
									subproyectosFiltered[elem].dimensioncus=superficie*cus;

								}else{
									subproyectosFiltered[elem].dimensiones=0
									subproyectosFiltered[elem].dimensioncus=0
								}
								subproyectosFiltered[elem].niveles=0;
								x++;

							});
					   		 console.log("fetch by id exitoso");
							 console.log(data);
				    		 console.log("fetch by id exitoso");
					   	   dispatch({ type: proyectoConstants.FETCH_BY_ID_SUCCESS, payload: {byID:data, subByID_array:Object.values(subproyectosFiltered),subByID_object:subproyectosFiltered } });
					   	 });
					    };
					};

			const fetchByID = (uid) => {
							return (dispatch) => {

						      firebase.database().ref(`/proyectos/${uid}`)
						   	 .on('value', snapshot => {
						   		 const data=snapshot.val();
								// const  subproyectosFiltered=Object.values(data.subproyectos).filter(d => d.status==1)
								 //const data=preData.filter(d => d.status==1)

								/*Object.filter = (obj, predicate) =>
								  Object.keys(obj)
								          .filter( key => predicate(obj[key]) )
								          .reduce( (res, key) => (res[key] = obj[key], res), {} );

								// Example use:

								let subproyectosFiltered = Object.filter(data.subproyectos, subproyecto => subproyecto.status == 1);
								let x=0;
								subproyectosFiltered=subproyectosFiltered.map((elem,index)=>{
									if(x===0){

									}else{
									elem.dimensiones=0
									elem.dimensioncus=0
									elem.niveles=0}
								});*/
						   		 console.log("fetch by id exitoso");
								 console.log(data);
					    		 console.log("fetch by id exitoso");
						   	   dispatch({ type: proyectoConstants.FETCH_BY_ID_SUCCESS, payload: {byID:data, } });
						   	 });
						    };
						};

			const handleDimensionesChange = (uid, value) =>
			{
				return (dispatch) => {
					dispatch({type:proyectoConstants.UPDATE_DIMENSION_SUCCESS, uid:uid, value: value});

				}
			}

			const handleDimensioncusChange = (uid, value) =>
			{
				return (dispatch) => {
					dispatch({type:proyectoConstants.UPDATE_DIMENSIONCUS_SUCCESS, uid:uid, value: value});

				}
			}
			const handleNivelesChange = (uid, value) =>
			{
				return (dispatch) => {
					dispatch({type:proyectoConstants.UPDATE_NIVEL_SUCCESS, uid:uid, value: value});

				}
			}



export const proyectoActions = {
  formSave,remove, add, fetchAll, fetchSoftDeleted, update, addSub, removeSub, fetchAllCompleted, fetchByID_especial, fetchByID, handleDimensionesChange,handleDimensioncusChange,handleNivelesChange,
};
