import React, { Component } from 'react';
import { Gantt} from 'dhtmlx-gantt';

import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_auto_scheduling.js'
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_click_drag.js'
import 'dhtmlx-gantt/codebase/locale/locale_es.js'


//import './utils';
import { Button } from 'antd';
import  TipoModal  from './modal';
import moment from 'moment';
const gantt = Gantt.getGanttInstance();
const gantt2 = Gantt.getGanttInstance();
const dateFormat = 'YYYY/MM/DD';

const newLocal = gantt.config.layout = {
  css: "gantt_container",
  cols: [
    {
      width:400,
      min_width: 300,
      rows:[
        {view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer"},

         // horizontal scrollbar for the grid
         {view: "scrollbar", id: "gridScroll", group:"horizontal"},
      ]
    },
 { resizer: true, width: 1 },
    {
      rows:[
        {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},

        // horizontal scrollbar for the timeline
        {view: "scrollbar", id: "scrollHor", group:"horizontal"}
      ]
    },
    {view: "scrollbar", id: "scrollVer"}
  ]
};
// src={'images/logo-holistic-spa.png'}
const newLocal2 = gantt2.config.layout = {
  css: "gantt_container",
  cols: [
    {
      width:400,
      min_width: 300,
      rows:[
        {view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer"},

         // horizontal scrollbar for the grid
         {view: "scrollbar", id: "gridScroll", group:"horizontal"},
      ]
    },
 { resizer: true, width: 1 },
    {
      rows:[
        {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},

        // horizontal scrollbar for the timeline
        {view: "scrollbar", id: "scrollHor", group:"horizontal"}
      ]
    },
    {view: "scrollbar", id: "scrollVer"}
  ]
};
// src={'images/logo-holistic-spa.png'}


export default class GanttComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {height: props.height, width:props.width, visible:false};

      }

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  saveFormRef = formRef => {
      this.formRef = formRef;
    };

    handleCreate = () => {
      const { form } = this.formRef.props;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.clone_to_Gantt1wp(values);
       //console.log('Received values of form: ', values);
        //datos.nombre


        form.resetFields();
        this.setState({ visible: false });
      });
    };

    //clonar
    child_links=[];
    clone_original_ids_table=[];

    obtain_link_ids(id){
        var task = gantt2.getTask(id);
        var source_links = task.$source;
        for (var i = 0; i < source_links.length; i++) {
            this.child_links.push(source_links[i]);
        }
    }

    create_clone_original_ids_table(original_id, clone_id){
        this.clone_original_ids_table[original_id] = clone_id;
    }

    clone_child_links(){

        for (var i = 0; i < this.child_links.length; i++) {
            var link = gantt2.getLink(this.child_links[i]);
        if (this.clone_original_ids_table[link.source] && this.clone_original_ids_table[link.target]){
          var clone_link = {};
          clone_link.id = gantt.uid();
          clone_link.target = this.clone_original_ids_table[link.target];
          clone_link.source = this.clone_original_ids_table[link.source];
          clone_link.type = link.type;
          gantt.addLink(clone_link)
            }

        }

    }


    clone_children(id, new_parent){
        var task = gantt2.getTask(id);
        //console.log('entro a clone_children');
        var children = gantt2.getChildren(id)
        for (var i = 0; i < children.length; i++) {
        var child_original = gantt2.getTask(children[i]);
        var child_clone = gantt.copy(child_original);
        child_clone.id = gantt.uid();
        child_clone.parent = new_parent;
        gantt.addTask(child_clone, child_clone.parent, child_clone.$index);

        this.obtain_link_ids(child_original.id);
        this.create_clone_original_ids_table(child_original.id, child_clone.id);

        if (gantt2.hasChild(child_original.id)) this.clone_children(child_original.id, child_clone.id);
        }

    }

    clone_task(id){
        var task = gantt2.getTask(id);
        var clone = gantt.copy(task);
        clone.id = gantt.uid();
        clone.tipo = id.toString();
       //console.log('clonando')
       //console.log(clone)
        gantt.addTask(clone, clone.parent, clone.$index);
        //alert(JSON.stringify(gantt.getTask(clone.id)))
        this.child_links = [];
        this.obtain_link_ids(id);

        this.clone_original_ids_table = {};
        this.create_clone_original_ids_table(task.id, clone.id);

        if (gantt2.hasChild(id)) {
            this.clone_children(id, clone.id)
        }

        this.clone_child_links()
        //console.log(this.child_links)
        //console.log(this.child_links)
        gantt.render();
    }


    clone_to_Gantt1 = () => {
        var id = gantt2.getSelectedId() || gantt2.getTaskByIndex(0).id;
        //var task = gantt.copy(gantt2.getTask(id));

        //task.id = gantt.uid();
        this.clone_task(id)
        gantt.render();
      }





    //^clonar

    clone_childrenwp(id, new_parent, firstDate,clone){
        var task = gantt2.getTask(id);
        //console.log('entro a clone_children');
        var children = gantt2.getChildren(id)
        for (var i = 0; i < children.length; i++) {
            var child_original = gantt2.getTask(children[i]);
            var child_clone = gantt.copy(child_original);
            child_clone.id = gantt.uid();
            child_clone.tipo = clone.tipo;
            child_clone.staff = clone.staff;
            child_clone.cliente = clone.cliente;
            //
            const date2 =moment(child_clone.start_date);
            var diffInDays = date2.diff(firstDate, 'days');

            if(child_original.$target.length===0){
            if(diffInDays>0){
                child_clone.start_date = gantt.date.add(child_clone.start_date, -diffInDays, 'day');
                child_clone.end_date = gantt.date.add(child_clone.end_date, -diffInDays, 'day');
            }
            else if(diffInDays!=0){
                child_clone.start_date = gantt.date.add(child_clone.start_date, -diffInDays, 'day');
                child_clone.end_date = gantt.date.add(child_clone.end_date, -diffInDays, 'day');
            }
            }

            //
            child_clone.parent = new_parent;
            gantt.addTask(child_clone, child_clone.parent, child_clone.$index);
            //gantt.correctTaskWorkTime(child_clone.id);
            this.obtain_link_ids(child_original.id);
            this.create_clone_original_ids_table(child_original.id, child_clone.id);

            if (gantt2.hasChild(child_original.id)) this.clone_childrenwp(child_original.id, child_clone.id,firstDate,clone);
        }

    }

    clone_taskwp(id,extraData){
        var task = gantt2.getTask(id);
        var clone = gantt.copy(task);
        clone.id = gantt.uid();
        clone.tipo = id;
        clone.text = extraData.name;
        clone.staff = extraData.staff;
        clone.cliente = extraData.cliente;
       //console.log('clonando')
       //console.log(clone)
        gantt.addTask(clone, clone.parent, clone.$index);
        //alert(JSON.stringify(gantt.getTask(clone.id)))
        this.child_links = [];
        this.obtain_link_ids(id);

        this.clone_original_ids_table = {};
        this.create_clone_original_ids_table(task.id, clone.id);

        if (gantt2.hasChild(id)) {
            this.clone_childrenwp(id, clone.id,extraData.start_date,clone)
        }

        this.clone_child_links()

        //console.log(this.child_links)
        //console.log(this.child_links)
        gantt.render();
        //gantt.getTask(clone.id).start_date = extraData.start_date.format('YYYY-MM-DD HH:mm:ss'); //changes task's data
        //gantt.updateTask(clone.id); //renders the updated task
    }

    clone_to_Gantt1wp = (extraData) => {
        var id = gantt2.getSelectedId() || gantt2.getTaskByIndex(0).id;
        //var task = gantt.copy(gantt2.getTask(id));

        //task.id = gantt.uid();
        this.clone_taskwp(extraData.tipo,extraData)
        gantt.render();
      }
    //^clonar v2

  events=[];

  // instance of gantt.dataProcessor
  dataProcessor = null;
  dataProcessor2 = null;

  /**
   * applies one of the predefined settings of the time scale
   */

  setZoom(value) {
      var weekScaleTemplate = function (date) {
          var dateToStr = gantt.date.date_to_str("%d %M");
          var weekNum = gantt.date.date_to_str("(semana %W)");
          var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
          return dateToStr(date) + " - " + dateToStr(endDate) + " " + weekNum(date);
      };

    switch (value) {
      case 'Horas':
        gantt.config.scale_unit = 'day';
        gantt.config.date_scale = '%d %M';

        gantt.config.scale_height = 60;
        gantt.config.min_column_width = 30;
        gantt.config.subscales = [
          { unit: 'hour', step: 1, date: '%H' }
        ];
        break;
      case 'Días':

        gantt.config.scale_unit = "day";
        gantt.config.date_scale = "%D, %d";
        gantt.config.min_column_width = 60;
        gantt.config.duration_unit = "day";
        gantt.config.scale_height = 20 * 3;
        gantt.config.row_height = 30;



        gantt.config.subscales = [
            {unit: "month", step: 1, date: "%F, %Y"},
            {unit: "week", step: 1, template: weekScaleTemplate}

        ];

        break;
      case 'Meses':
        gantt.config.min_column_width = 70;
        gantt.config.scale_unit = 'month';
        gantt.config.date_scale = '%F';
        gantt.config.scale_height = 60;
        gantt.config.subscales = [
          { unit: 'week', step: 1, template: weekScaleTemplate }
        ];
        break;
      default:
        break;
    }
  }
  setZoom2(value) {
      var weekScaleTemplate = function (date) {
          var dateToStr = gantt2.date.date_to_str("%d %M");
          var weekNum = gantt2.date.date_to_str("(semana %W)");
          var endDate = gantt2.date.add(gantt2.date.add(date, 1, "week"), -1, "day");
          return dateToStr(date) + " - " + dateToStr(endDate) + " " + weekNum(date);
      };

    switch (value) {
      case 'Horas':
        gantt2.config.scale_unit = 'day';
        gantt2.config.date_scale = '%d %M';

        gantt2.config.scale_height = 60;
        gantt2.config.min_column_width = 30;
        gantt2.config.subscales = [
          { unit: 'hour', step: 1, date: '%H' }
        ];
        break;
      case 'Días':
        /*gantt2.config.min_column_width = 70;
        gantt2.config.scale_unit = 'week';
        gantt2.config.date_scale = '#%W';
        gantt2.config.subscales = [
          { unit: 'day', step: 1, date: '%d %M' }
        ];
        gantt2.config.scale_height = 60;*/
        gantt2.config.scale_unit = "day";
        gantt2.config.date_scale = "%D, %d";
        gantt2.config.min_column_width = 60;
        gantt2.config.duration_unit = "day";
        gantt2.config.scale_height = 20 * 3;
        gantt2.config.row_height = 30;



        gantt2.config.subscales = [
            {unit: "month", step: 1, date: "%F, %Y"},
            {unit: "week", step: 1, template: weekScaleTemplate}

        ];

        break;
      case 'Meses':
        gantt2.config.min_column_width = 70;
        gantt2.config.scale_unit = 'month';
        gantt2.config.date_scale = '%F';
        gantt2.config.scale_height = 60;
        gantt2.config.subscales = [
          { unit: 'week', step: 1, template: weekScaleTemplate }
        ];
        break;
      default:
        break;
    }
  }

  determineStatus(progress, filter){
      if(filter==="2"){
          if(progress>=0 && progress <1){return true}
      }
      if(filter==="1"){
          if(progress === 1){return true}
      }
      if(filter==="0"){
          return true;
      }

      return false;
  }

determineDate(minDate,maxDate, filteredMinDate, filteredMaxDate){
        //alert(filteredMinDate);
        if(filteredMinDate===undefined){return true}
        const formatFilteredMinDate=moment(filteredMinDate);
        const formatFilteredMaxDate=moment(filteredMaxDate);
        const formatMinDate=moment(minDate);
        const formatMaxDate=moment(maxDate);

        if(formatMinDate.isSameOrAfter(formatFilteredMinDate) && formatMaxDate.isSameOrBefore(formatFilteredMaxDate)){return true}
        return false
}

determineRole(uid,role,staff){
    //console.log("comparando "+uid+"="+staff)
    if (role!=='admin'){
        return staff===uid?true:false
    }else{return true}
}

getGrandParent(childId){
  var parentId = gantt.getParent(childId);
  return (parentId)?this.getGrandParent(parentId):(childId);
}

determineProjectType(taskId,projectType,filteredProjectType,getGrandParent){

        if(projectType == filteredProjectType || filteredProjectType == "0"){return true;}
        else{
            const grandParentId=getGrandParent(taskId);
            alert('ola',grandParentId);

            var grandParent= gantt.getTask(grandParentId);

            if(grandParent.tipo == filteredProjectType){return true;}


        }
}

  filter(){
     gantt.detachEvent(this.eventId);
     //const state=gantt.getState();
     ////console.log("###########");
     ////console.log(state.min_date);
     ////console.log(state.max_date);
     ////console.log("###########");
     //const minDate = moment(state.min_date).format(dateFormat);
     //const maxDate = moment(state.max_date).format(dateFormat);
     ////console.log(minDate);
    // //console.log(maxDate);
     const  {filteredResponsable,filteredClient,filteredProjectType, filteredStatus, filteredMinDate, filteredMaxDate, uid, role}=this.props;
     const determineStatus = this.determineStatus;
     const determineDate = this.determineDate;
     const determineRole = this.determineRole;
     const determineProjectType = this.determineProjectType;
     const getGrandParent=this.getGrandParent;

     this.eventId= gantt.attachEvent("onBeforeTaskDisplay", function(id, task){
   console.log(task.text+' filtrando staff '+task.staff+ ' contra '+ filteredResponsable)
     if ((task.staff === filteredResponsable || filteredResponsable == "0") && (task.cliente === filteredClient || filteredClient == "0") && (task.tipo == filteredProjectType || filteredProjectType == "0") &&
     determineStatus(task.progress, filteredStatus) && determineDate(task.start_date, task.end_date,filteredMinDate, filteredMaxDate) && determineRole(uid,role,task.staff)

     ){
         return true;
     }
     return false;
     });

    ////console.log("###########");
    ////console.log(this.props);

  }

  initGanttDataProcessor() {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    const onDataUpdated = this.props.onDataUpdated;
    const onDataUpdated2 = this.props.onDataUpdated2;
    this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise((resolve, reject) => {
        if (onDataUpdated) {
          onDataUpdated(type, action, item, id);
          ////console.log(gantt.$data.tasksStore.pull);
          //console.log({type,item,action,id});
        //  //console.log(gantt.$data.linksStore.pull);
        //alert(JSON.stringify(item));
          this.props.onChange({type:type,action:action,item:item,id:id});

        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});

        return resolve();
      });
    });
    this.dataProcessor2 = gantt2.createDataProcessor((type, action, item, id) => {
      return new Promise((resolve, reject) => {
        if (onDataUpdated) {
          onDataUpdated2(type, action, item, id);
          ////console.log(gantt.$data.tasksStore.pull);
          //console.log({type,item,action,id});
        //  //console.log(gantt.$data.linksStore.pull);
          this.props.onChange2({type:type,action:action,item:item,id:id});

        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});

        return resolve();
      });
    });
    }

  /* shouldComponentUpdate(nextProps) {
    return this.props.zoom !== nextProps.zoom;
    return this.props.filteredResponsable !== nextProps.filteredResponsable;
  } */


  componentDidUpdate(prevProps) {
    /*//console.log("didupdate");
      //console.log(prevProps.tasks);
      //console.log(this.props.tasks);
    if (prevProps.tasks !== this.props.tasks) {

        gantt.parse(this.props.tasks)
      }*/
    gantt.render();
  }

    /*
    WillReceiveProps(nextProps){
          //console.log(this.props.tasks)
          //console.log('props arriba')
          //console.log(nextProps.tasks)
          //console.log('nextprops arriba')

        if(nextProps.tasks!==this.props.tasks){
          //Perform some operation

          gantt.parse(nextProps.tasks)
          gantt.render();
          //this.classMethod();
        }
    }
  */


  componentDidMount() {
    //  this.setState({...this.props})
    //job
    /*--VARIABLES--*/
        //var gantt2= Gantt.getGanttInstance(); esta linea era antes de saber que podia inicializarla como constante. asies
        var staffArray= this.props.staff
        var clienteArray= this.props.cliente

        //var tipos=[{key:'Residencial',label:'Residencial'},{key:'Condominios',label:'Condominios'},{key:'Comercial',label:'Comercial'}];
        var tipos=this.props.projectTypes;
       //console.log('ola',clienteArray)
       //console.log('ola',tipos)
    //finVARIABLES
    //GANTT2
        /*--CONFIG--*/
            gantt2.config.show_errors = false;
            gantt.config.show_errors = false;
            gantt2.config.order_branch = true;
            gantt2.config.order_branch_free = true;
            gantt2.config.columns = [
                {
                    name: "overdue", label: "", width: 38, template: function (obj) {
                        if (obj.deadline) {
                            var deadline = gantt.date.parseDate(obj.deadline, "xml_date");
                            if (deadline && obj.end_date > deadline) {
                                return '<div class="overdue-indicator">!</div>';
                            }
                        }
                        return '<div></div>';
                    }
                },
                {name: "add", width: 44, min_width: 44, max_width: 44},
                {name: "text", tree: true, width: 156, resize: true},
                {name: "start_date", align: "center", resize: true, width: 90},
                {name: "duration", align: "center", width: 70},
                {name: "staff", align: "center", width: 100, resize: true, template:function(obj){
                                        return getLabelbyKey(staffArray,obj.staff)
                                        }},
                {name: "cliente", align: "center", width: 106, resize: true, template:function(obj){
                                        return getLabelbyKey(clienteArray,obj.cliente)
                                        }},
                {name: "tipo", align: "center", width: 106, resize: true},


            ];
            gantt2.config.fit_tasks = true;
            gantt2.config.autosize_min_height = 800;
            gantt2.config.xml_date = "%Y-%m-%d %H:%i";
            gantt2.config.auto_types = true;
            gantt2.config.sort = true;
            gantt2.config.auto_scheduling = true;
            gantt2.config.work_time = true;  // removes non-working time from calculations
            //gantt2.config.skip_off_time = true;    // hides non-working time in the chart
            //gantt2.config.autosize = "y";
            //MODALES
            gantt2.config.lightbox.sections=[
                {name: "type", type: "typeselect", map_to: "type"},
                {name:"description", height:70, map_to:"text", type:"textarea", focus:true},
                {name:"nombreEntregable", height:70, map_to:"nombreEntregable", type:"textarea", focus:true},
                {name:"time", height:72, map_to:"auto", type:"duration"},

            ];
            gantt2.config.lightbox.project_sections= [
                {name: "description", height: 70, map_to: "text", type: "textarea", focus: true},
                //{name:"tipo",        height:72, map_to:"tipo",  type:"select", options:tipos},
                {name: "type", type: "typeselect", map_to: "type"},

                {
                    name: "deadline", map_to: {start_date: "deadline"},
                    type: "duration_optional",
                    button: true,
                    single_date: true
                },
                {name: "time", type: "duration", readonly: true, map_to: "auto"}
            ];
            gantt2.config.lightbox.milestone_sections= [
                {name: "description", height: 70, map_to: "text", type: "textarea", focus: true},
                {name: "type", type: "typeselect", map_to: "type"},
                {name: "time", type: "duration", single_date: true, map_to: "auto"}
            ];
        //finCONFIG
        /*--TEXTOS--*/
            //Deadline
        	gantt2.locale.labels.section_deadline = "Deadline";
            gantt2.locale.labels.deadline_enable_button = 'Agregar';
        	gantt2.locale.labels.deadline_disable_button = 'Eliminar';
            //pares de grid-lightbox
            gantt2.locale.labels.nombreEntregable='Entregable';
            gantt2.locale.labels.section_nombreEntregable='Nombre de entregable';
            gantt2.locale.labels.staff='Staff';
            gantt2.locale.labels.section_staff='Staff';
            gantt2.locale.labels.cliente='Cliente';
            gantt2.locale.labels.section_cliente='Cliente';
            gantt2.locale.labels.tipo='Tipo';
            gantt2.locale.labels.section_tipo='Tipo de proyecto';
            gantt2.locale.labels.column_duration='Duración';
            gantt2.locale.labels.column_text='Nombre de tarea';
            gantt2.locale.labels.column_start_date='Fecha de inicio';
            gantt2.locale.labels.section_description='Nombre de tarea';
            gantt2.locale.labels.section_time='Duracion de la tarea';
            gantt2.locale.labels.message_cancel='Cancelar';
            gantt2.locale.labels.icon_cancel='Cancelar';
            gantt2.locale.labels.icon_save='Guardar';
            gantt2.locale.labels.icon_delete='Eliminar';
            gantt2.locale.labels.icon_details='Detalles';
            gantt2.locale.labels.icon_edit='Editar';
            gantt2.locale.labels.new_task='Nueva tarea';
            gantt2.locale.labels.link='Enlace';
            gantt2.locale.labels.link_end='(fin)';
            gantt2.locale.labels.link_start='(inicio)';
            gantt2.locale.labels.confirm_deleting='La tarea será borrada permanentemente, estas seguro?';
            gantt2.locale.labels.confirm_link_deleting='será borrado';
            gantt2.locale.labels.days='Días';
            gantt2.locale.labels.months='Meses';
            gantt2.locale.labels.weeks='Semanas';
            gantt2.locale.labels.years='Años';
            gantt2.locale.date.day_full=['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'];
            gantt2.locale.date.day_short=['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
            gantt2.locale.date.month_full=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
            gantt2.locale.date.month_short=['Ene','Feb','Mar','Abr','May','Jun','Jul','Agos','Sep','Oct','Nov','Dic'];
        //finTEXTOS
        /*--FUNCIONES--*/
            gantt2.addTaskLayer(function draw_deadline(task) {
                    if (task.deadline) {
                        var el = document.createElement('div');
                        el.className = 'deadline';
                        var sizes = gantt2.getTaskPosition(task, task.deadline);

                        el.style.left = sizes.left + 'px';
                        el.style.top = sizes.top + 'px';

                        el.setAttribute('title', gantt2.templates.task_date(task.deadline));
                        return el;
                    }
                    return false;

            });
            gantt2.templates.task_class = function (start, end, task) {
                if (task.deadline && end.valueOf() > task.deadline.valueOf()) {
                    return 'overdue';
                }
            };
            gantt2.templates.rightside_text = function (start, end, task) {
                if (task.deadline) {
                    if (end.valueOf() > task.deadline.valueOf()) {
                        var overdue = Math.ceil(Math.abs((end.getTime() - task.deadline.getTime()) / (24 * 60 * 60 * 1000)));
                        var text = "<b>Retraso: " + overdue + " días</b>";
                        return text;
                    }
                }
            };
            gantt2.templates.timeline_cell_class = function (task, date) {
                if (!gantt2.isWorkTime(date))
                    return "week_end";
                    return "";
            };
            gantt2.attachEvent("onTaskLoading", function (task) {
                if (task.deadline)
                    task.deadline = gantt2.date.parseDate(task.deadline, "xml_date");
                return true;
            });
            /*gantt.attachEvent("onError", function(errorMessage){
                debugger;
                return true;
                });
                gantt2.attachEvent("onError", function(errorMessage){
                    debugger;
                    return true;
                    });*/

            function gantt2onDragEnd(startPoint, endPoint, startDate, endDate, tasksBetweenDates, tasksInRow) {
                        if (tasksInRow.length === 1) {
                            var parent = tasksInRow[0];
                            gantt2.createTask({
                                text:"Subtarea " + parent.text,
                                start_date: gantt2.roundDate(startDate),
                                end_date: gantt2.roundDate(endDate)
                            }, parent.id);
                        } else if (tasksInRow.length === 0) {
                            gantt2.createTask({
                                text:"Nueva tarea",
                                start_date: gantt2.roundDate(startDate),
                                end_date: gantt2.roundDate(endDate)
                            });
                        }
            }
            (function gantt2dynamicProgress() {

                function gantt2calculateSummaryProgress(task) {
                    if (task.type != gantt2.config.types.project)
                        return task.progress;
                    var totalToDo = 0;
                    var totalDone = 0;
                    gantt2.eachTask(function (child) {
                        if (child.type != gantt2.config.types.project) {
                            totalToDo += child.duration;
                            totalDone += (child.progress || 0) * child.duration;
                        }
                    }, task.id);
                    if (!totalToDo) return 0;
                    else return totalDone / totalToDo;
                }

                function gantt2refreshSummaryProgress(id, submit) {
                    if (!gantt2.isTaskExists(id))
                        return;

                    var task = gantt2.getTask(id);
                    task.progress = gantt2calculateSummaryProgress(task);

                    if (!submit) {
                        gantt2.refreshTask(id);
                    } else {
                        gantt2.updateTask(id);
                    }

                    if (!submit && gantt2.getParent(id) !== gantt2.config.root_id) {
                        gantt2refreshSummaryProgress(gantt2.getParent(id), submit);
                    }
                }


                gantt2.attachEvent("onParse", function () {
                    gantt2.eachTask(function (task) {
                        task.progress = gantt2calculateSummaryProgress(task);
                    });
                });

                gantt2.attachEvent("onAfterTaskUpdate", function (id) {
                    gantt2refreshSummaryProgress(gantt2.getParent(id), true);
                });

                gantt2.attachEvent("onTaskDrag", function (id) {
                    gantt2refreshSummaryProgress(gantt2.getParent(id), false);
                });
                gantt2.attachEvent("onAfterTaskAdd", function (id) {
                    gantt2refreshSummaryProgress(gantt2.getParent(id), true);
                });


                (function () {
                    var idParentBeforeDeleteTask = 0;
                    gantt2.attachEvent("onBeforeTaskDelete", function (id) {
                        idParentBeforeDeleteTask = gantt2.getParent(id);
                    });
                    gantt2.attachEvent("onAfterTaskDelete", function () {
                        gantt2refreshSummaryProgress(idParentBeforeDeleteTask, true);
                    });
                })();
            })();
            gantt2.templates.progress_text = function (start, end, task) {
                return "<span style='text-align:left;'>" + Math.round(task.progress * 100) + "% </span>";
            };
            gantt2.templates.task_class = function (start, end, task) {
                if (task.type == gantt2.config.types.project)
                    return "hide_project_progress_drag";
            };
            gantt2.config.click_drag =
                    {
                        callback: gantt2onDragEnd,
                        singleRow: true
                    };
        //finFUNCIONES
    //finGANTT2
    //GANTT1
        /*--CONFIG--*/
            //gantt.config.drag_project = true;
            gantt.config.order_branch = true;
            gantt.config.order_branch_free = true;
            gantt.config.fit_tasks = true;
            gantt.config.autosize_min_height = 800;
            gantt.config.xml_date = "%Y-%m-%d %H:%i";
            gantt.config.auto_types = true;
            gantt.config.sort = true;
            gantt.config.auto_scheduling = true;
            gantt.config.work_time = true;  // removes non-working time from calculations
            //gantt.config.skip_off_time = true;    // hides non-working time in the chart
            //gantt.config.autosize = "y";
            //COLUMNAS
            gantt.config.columns = [
                {
                    name: "overdue", label: "", width: 38, template: function (obj) {
                        if (obj.deadline) {
                            var deadline = gantt.date.parseDate(obj.deadline, "xml_date");
                            if (deadline && obj.end_date > deadline) {
                                return '<div class="overdue-indicator">!</div>';
                            }
                        }
                        return '<div></div>';
                    }
                },
                {name: "add", width: 44, min_width: 44, max_width: 44},
                {name: "text", tree: true, width: 156, resize: true},
                {name: "start_date", align: "center", resize: true, width: 90},
                {name: "duration", align: "center", width: 70},
                {name: "staff", align: "center", width: 100, resize: true, template:function(obj){
                                        return getLabelbyKey(staffArray,obj.staff)
                                        }},
                {name: "cliente", align: "center", width: 106, resize: true, template:function(obj){
                                        return getLabelbyKey(clienteArray,obj.cliente)
                                        }},
                {name: "tipo", align: "center", width: 106, resize: true, template:function(obj){
                                    return getLabelbyKey(tipos,obj.tipo)
                                                                }},



            ];
            //MODALES
            gantt.config.lightbox.sections=[
                {name: "type", type: "typeselect", map_to: "type"},
                {name:"description", height:70, map_to:"text", type:"textarea", focus:true},
                {name:"nombreEntregable", height:70, map_to:"nombreEntregable", type:"textarea", focus:true},
                {name:"staff", height:72, map_to:"staff",  type:"select", options:this.props.staff},
                {name:"time", height:72, map_to:"auto", type:"duration"},

            ];
            gantt.config.lightbox.project_sections= [
                {name: "type", type: "typeselect", map_to: "type"},
                {name: "description", height: 70, map_to: "text", type: "textarea", focus: true},
                {name:"tipo",        height:72, map_to:"tipo",  type:"select", options:tipos},
                {name:"staff", height:72, map_to:"staff",  type:"select", options:this.props.staff},
                {name:"cliente", height:72, map_to:"cliente",  type:"select", options:this.props.cliente},
                {
                    name: "deadline", map_to: {start_date: "deadline"},
                    type: "duration_optional",
                    button: true,
                    single_date: true
                },
                {name: "time", type: "duration", readonly: true, map_to: "auto"}
            ];
            gantt.config.lightbox.milestone_sections= [
                {name: "type", type: "typeselect", map_to: "type"},
                {name: "description", height: 70, map_to: "text", type: "textarea", focus: true},
                {name: "time", type: "duration", single_date: true, map_to: "auto"}
            ];
        //finCONFIG
        /*--FUNCIONES--*/
            gantt.addTaskLayer(function draw_deadline(task) {
            		if (task.deadline) {
            			var el = document.createElement('div');
            			el.className = 'deadline';
            			var sizes = gantt.getTaskPosition(task, task.deadline);

            			el.style.left = sizes.left + 'px';
            			el.style.top = sizes.top + 'px';

            			el.setAttribute('title', gantt.templates.task_date(task.deadline));
            			return el;
            		}
            		return false;

            });
        	gantt.templates.task_class = function (start, end, task) {
        		if (task.deadline && end.valueOf() > task.deadline.valueOf()) {
        			return 'overdue';
        		}
        	};
        	gantt.templates.rightside_text = function (start, end, task) {
        		if (task.deadline) {
        			if (end.valueOf() > task.deadline.valueOf()) {
        				var overdue = Math.ceil(Math.abs((end.getTime() - task.deadline.getTime()) / (24 * 60 * 60 * 1000)));
        				var text = "<b>Retraso: " + overdue + " días</b>";
        				return text;
        			}
        		}
        	};
            gantt.templates.timeline_cell_class = function (task, date) {
                if (!gantt.isWorkTime(date))
                    return "week_end";
                    return "";
            };
        	gantt.attachEvent("onTaskLoading", function (task) {
        		if (task.deadline)
        			task.deadline = gantt.date.parseDate(task.deadline, "xml_date");
        		return true;
        	});
            var getLabelbyKey= function(model,key){

                const keys= model.map(function(elem, index) {
                   //console.log(elem);
                   //console.log("elemento "+elem['key'])
                    return elem['key'];
                })
                ////console.log(keys)

                const index= keys.indexOf(key);
                if(index!=-1){
                    ////console.log(index+" es igual"+model[index])
                    return model[index].label;
                }else{return ""}


            }
            function onDragEnd(startPoint, endPoint, startDate, endDate, tasksBetweenDates, tasksInRow) {
        				if (tasksInRow.length === 1) {
        					var parent = tasksInRow[0];
        					gantt.createTask({
        						text:"Subtarea " + parent.text,
        						start_date: gantt.roundDate(startDate),
        						end_date: gantt.roundDate(endDate)
        					}, parent.id);
        				} else if (tasksInRow.length === 0) {
        					gantt.createTask({
        						text:"Nueva tarea",
        						start_date: gantt.roundDate(startDate),
        						end_date: gantt.roundDate(endDate)
        					});
        				}
        	}
            (function dynamicProgress() {

        		function calculateSummaryProgress(task) {
        			if (task.type != gantt.config.types.project)
        				return task.progress;
        			var totalToDo = 0;
        			var totalDone = 0;
        			gantt.eachTask(function (child) {
        				if (child.type != gantt.config.types.project) {
        					totalToDo += child.duration;
        					totalDone += (child.progress || 0) * child.duration;
        				}
        			}, task.id);
        			if (!totalToDo) return 0;
        			else return totalDone / totalToDo;
        		}

        		function refreshSummaryProgress(id, submit) {
        			if (!gantt.isTaskExists(id))
        				return;

        			var task = gantt.getTask(id);
        			task.progress = calculateSummaryProgress(task);

        			if (!submit) {
        				gantt.refreshTask(id);
        			} else {
        				gantt.updateTask(id);
        			}

        			if (!submit && gantt.getParent(id) !== gantt.config.root_id) {
        				refreshSummaryProgress(gantt.getParent(id), submit);
        			}
        		}


        		gantt.attachEvent("onParse", function () {
        			gantt.eachTask(function (task) {
        				task.progress = calculateSummaryProgress(task);
        			});
        		});

        		gantt.attachEvent("onAfterTaskUpdate", function (id) {
        			refreshSummaryProgress(gantt.getParent(id), true);
        		});

        		gantt.attachEvent("onTaskDrag", function (id) {
        			refreshSummaryProgress(gantt.getParent(id), false);
        		});
        		gantt.attachEvent("onAfterTaskAdd", function (id) {
        			refreshSummaryProgress(gantt.getParent(id), true);
        		});


        		(function () {
        			var idParentBeforeDeleteTask = 0;
        			gantt.attachEvent("onBeforeTaskDelete", function (id) {
        				idParentBeforeDeleteTask = gantt.getParent(id);
        			});
        			gantt.attachEvent("onAfterTaskDelete", function () {
        				refreshSummaryProgress(idParentBeforeDeleteTask, true);
        			});
        		})();
        	})();
            gantt.templates.progress_text = function (start, end, task) {
        		return "<span style='text-align:left;'>" + Math.round(task.progress * 100) + "% </span>";
        	};
        	gantt.templates.task_class = function (start, end, task) {
        		if (task.type == gantt.config.types.project)
        			return "hide_project_progress_drag";
        	};
            gantt.config.click_drag =
                    {
        				callback: onDragEnd,
        				singleRow: true
        			};
        //finFUNCIONES
        /*--TEXTOS--*/
            //Deadline
        	gantt.locale.labels.section_deadline = "Deadline";
            gantt.locale.labels.deadline_enable_button = 'Agregar';
        	gantt.locale.labels.deadline_disable_button = 'Eliminar';
            //pares de grid-lightbox
            gantt.locale.labels.nombreEntregable='Entregable';
            gantt.locale.labels.section_nombreEntregable='Nombre de entregable';
            gantt.locale.labels.staff='Staff';
            gantt.locale.labels.section_staff='Staff';
            gantt.locale.labels.cliente='Cliente';
            gantt.locale.labels.section_cliente='Cliente';
            gantt.locale.labels.tipo='Tipo';
            gantt.locale.labels.section_tipo='Tipo de proyecto';
            gantt.locale.labels.column_duration='Duración';
            gantt.locale.labels.column_text='Nombre de tarea';
            gantt.locale.labels.column_start_date='Fecha de inicio';
            gantt.locale.labels.section_description='Nombre de tarea';
            gantt.locale.labels.section_time='Duracion de la tarea';
            gantt.locale.labels.message_cancel='Cancelar';
            gantt.locale.labels.icon_cancel='Cancelar';
            gantt.locale.labels.icon_save='Guardar';
            gantt.locale.labels.icon_delete='Eliminar';
            gantt.locale.labels.icon_details='Detalles';
            gantt.locale.labels.icon_edit='Editar';
            gantt.locale.labels.new_task='Nueva tarea';
            gantt.locale.labels.link='Enlace';
            gantt.locale.labels.link_end='(fin)';
            gantt.locale.labels.link_start='(inicio)';
            gantt.locale.labels.confirm_deleting='La tarea será borrada permanentemente, estas seguro?';
            gantt.locale.labels.confirm_link_deleting='será borrado';
            gantt.locale.labels.days='Días';
            gantt.locale.labels.months='Meses';
            gantt.locale.labels.weeks='Semanas';
            gantt.locale.labels.years='Años';
            gantt.locale.date.day_full=['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'];
            gantt.locale.date.day_short=['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
            gantt.locale.date.month_full=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
            gantt.locale.date.month_short=['Ene','Feb','Mar','Abr','May','Jun','Jul','Agos','Sep','Oct','Nov','Dic'];
        //finTEXTOS
    //finGANTT1
    /*--INICIALIZACION--*/
        const { tasks,tasks2 } = this.props;
       //console.log(tasks)
        gantt.init(this.ganttContainer);
        gantt2.init(this.ganttContainer2);
        this.initGanttDataProcessor();
       //console.log('tasks1');
        //console.log(tasks2);
       //console.log(tasks);
        //console.log('tasks2');
       //console.log('tasks1');
        gantt.parse(tasks);//cargar tareas
        gantt2.parse(tasks2);//cargar tareas
    //finINICIALIZACION
  }

  componentWillUnmount() {
    if (this.dataProcessor) {
      this.dataProcessor.destructor();
      this.dataProcessor = null;
    }
    if (this.dataProcessor2) {
      this.dataProcessor2.destructor();
      this.dataProcessor2 = null;
    }
  }

  componentWillMount(){
          //console.log("WINDOW : ",window);
          this.setState({height: (window.innerHeight/100)*75 + 'px',width:window.innerWidth+'px'});
      }

  render() {
    //  gantt.parse(this.props.tasks);//cargar tareas
    //  gantt2.parse(this.props.tasks2);//cargar tareas
    const { zoom, tab } = this.props;
    var proyectosHeight= (tab==='proyectos'?'100%':'0%')
    var tiposHeight= (tab==='tipos'?'100%':'0%')

    this.filter();
    this.setZoom(zoom);
    this.setZoom2(zoom);
    return (
      <div style={{height:this.state.height}}>
          <TipoModal
              projectTypes={this.props.projectTypes}
              staffs={this.props.staff}
              clientes={this.props.cliente}
              visible={this.state.visible}
              wrappedComponentRef={this.saveFormRef}
              onCancel={this.handleCancel}
              onCreate={this.handleCreate}
              ></TipoModal>
          {/*tab==='tipos'&&<Button onClick={this.clone_to_Gantt1.bind(this)}>Copiar tipo de proyecto</Button>*/}
          {tab==='proyectos'&&<Button style={{position:'absolute',marginTop:'-32px',right:42}} onClick={this.showModal}>
                    Agregar Proyecto
                </Button>}


          <div
            ref={(input) => { this.ganttContainer = input }}
            style={{ width: '100%', height: proyectosHeight }}
            />

          <div
            ref={(input) => { this.ganttContainer2 = input }}
            style={{ width: '100%', height: tiposHeight }}
          />



 </div>
    );

  }
}
