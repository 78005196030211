import React from "react";
import {withRouter} from 'react-router-dom'

import CatalogosTabContent from "../../components/arquitectos/CatalogosTabContent";
import { Layout, Menu, Icon, Tabs, Button, Typography,
  Form,
   Input,
   Tooltip,
   Cascader,
   Select,
   Row,
   Col,
   Checkbox,
   AutoComplete,
 } from 'antd';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import ASider from "../../components/arquitectos/ASider";
import AHeader from "../../components/arquitectos/AHeader";
import {   clienteActions } from "../../actions";
const { Title } = Typography;

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;



// importing todo components

// importing our todo's action's



class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '0',
	  edit:false,

    };
  }

  componentWillMount(){
//    this.props.fetchAllCPU();

	 const { match: { params } } = this.props;
	//alert(params.id);
	if(params.id!="" && params.id!=undefined){
	//alert(params.id+' entro')
	this.setState({edit:true});
	this.props.clienteFetch(params.id);}
  }

  handleCreate = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);
	  console.log("entrando a ",this.state.edit)
      if(this.state.edit===true){
		  console.log('editar desde el formulario')
		  this.props.clienteUpdate({...values,id:this.props.cliente.id},this.handleRedirect)}
	  else{console.log('guardar desde el formulario')
		  this.props.clienteSave(values,this.handleRedirect)};
      form.resetFields();

    });
  };

  handleRedirect = e => {
    this.props.history.push('/clientes/');
  };

  render()
  {

    //console.log("copia esto we")
    //console.log(JSON.stringify(this.props.cpu))
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout id="sider">
        <ASider/>
        <Layout  className='layout-container-antd'>

            <AHeader >{this.state.edit===true?this.props.cliente.nombre:"Nuevo Cliente"}</AHeader>
          <Content  style={{ margin: '24px 16px 0' }}>
            <div style={{ overflowY:'auto', padding: 24, background: '#fff', height:'100%' }}>


              <Form>
                <Title>Cliente</Title>
                <Form.Item label="">
                {getFieldDecorator('nombre', { initialValue: this.state.edit===true?this.props.cliente.nombre:'',
                  rules: [
                    {
                      required: true,
                      message: 'Campo requerido!',
                    },
                  ],
                  })(<Input placeholder="Nombre del Cliente" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('movil', { initialValue: this.state.edit===true?this.props.cliente.movil:'',
                rules: [
                    {
                      required: true,
                      message: 'Campo requerido!',
                    },
                  ],
           		})(<Input placeholder="Movil" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('email', { initialValue: this.state.edit===true?this.props.cliente.email:'',
             rules: [
               {
                 type: 'email',
                 message: 'The input is not valid E-mail!',
               },
               {
                 required: true,
                 message: 'Please input your E-mail!',
               },
             ],
      			})(<Input placeholder="Email" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('proyectoDireccion', { initialValue: this.state.edit===true?this.props.cliente.proyectoDireccion:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Dirección" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('cp', { initialValue: this.state.edit===true?this.props.cliente.cp:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="CP" />)}
                </Form.Item>
                <Title>Empresa</Title>
                <Form.Item label="">
                {getFieldDecorator('empresaNombre', { initialValue: this.state.edit===true?this.props.cliente.empresaNombre:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Nombre de Empresa" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('empresaTelefono', { initialValue: this.state.edit===true?this.props.cliente.empresaTelefono:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Telefono" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('clienteDireccion', { initialValue: this.state.edit===true?this.props.cliente.clienteDireccion:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Dirección de Cliente" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('empresaDireccion', { initialValue: this.state.edit===true?this.props.cliente.empresaDireccion:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Dirección de Empresa" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('empresaCP', { initialValue: this.state.edit===true?this.props.cliente.empresaCP:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="CP" />)}
                </Form.Item>
                <Title>Fiscales</Title>
                <Form.Item label="">
                {getFieldDecorator('razonSocial', { initialValue: this.state.edit===true?this.props.cliente.razonSocial:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Razón Social" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('rfc', { initialValue: this.state.edit===true?this.props.cliente.rfc:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="RFC" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('estado', { initialValue: this.state.edit===true?this.props.cliente.estado:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Estado" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('ciudad', { initialValue: this.state.edit===true?this.props.cliente.ciudad:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Ciudad" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('calle', { initialValue: this.state.edit===true?this.props.cliente.calle:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="Calle" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('nExt', { initialValue: this.state.edit===true?this.props.cliente.nExt:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="No. Ext" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('nInt', { initialValue: this.state.edit===true?this.props.cliente.nInt:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="No. Int" />)}
                </Form.Item>
                <Form.Item label="">
                {getFieldDecorator('fiscalesCP', { initialValue: this.state.edit===true?this.props.cliente.fiscalesCP:'',
                  rules: [
                    {

                    },
                  ],
                  })(<Input placeholder="CP" />)}
                </Form.Item>
                <Form.Item>
                  <Button  onClick={this.handleCreate} type="primary" htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>
                </Form>


            </div>
          </Content>

        </Layout>
      </Layout>
    );
  };
}

const mapStateToProps = state => {
  return {
  //  cpu: state.cpu.CPUS,
	cliente:state.cliente.byID,
  };
};




const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
     // fetchAllCPU: cpuActions.fetchAllCPU,
	 clienteFetch:clienteActions.fetchByID,
      clienteSave:clienteActions.formSave,
	  clienteUpdate:clienteActions.update,
    },
    dispatch
  );
const WrappedClientes = Form.create()(Clientes)
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(WrappedClientes));
