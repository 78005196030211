import React from "react";
import { withRouter } from "react-router-dom";

import CatalogosTabContent from "../../components/arquitectos/CatalogosTabContent";
import {
  Layout,
  Menu,
  Icon,
  Tabs,
  Button,
  Typography,
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  AutoComplete,
  DatePicker,
  Table,
  Modal,
  Divider
} from "antd";
import moment from "moment-business-days";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import ASider from "../../components/arquitectos/ASider";
import AHeader from "../../components/arquitectos/AHeader";
import {
  proyectoActions,
  clienteActions,
  conceptoActions,
  staffActions,
  paneActions,
  authActions
} from "../../actions";
import { toast } from "react-toastify";
import Pdf from "react-to-pdf";

const { Title, Paragraph, Text } = Typography;

const { confirm } = Modal;
const { Column, ColumnGroup } = Table;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;
const dateFormat = "YYYY/MM/DD";
const ref = React.createRef();

// importing todo components

// importing our todo's action's

const tabuladores = {
  interior: "Interior",
  exterior: "Exterior",
  especial1: "Especial 1",
  especial2: "Especial 2"
};

/**/
const fases_columns = [
  { title: "FASES", dataIndex: "name", key: "name" },
  { title: "Periodo", dataIndex: "days", key: "days" },
  { title: "% Pagos", dataIndex: "percent", key: "percent" },
  { title: "Fechas de Entrega Estimadas", dataIndex: "endDate", key: "endDate" }
];

/*const distribucion_columns=[
  {title:"Nivel", dataIndex:'name',key:'name'},
  {title:"m2",dataIndex:'m2',key:'m2'}
]*/

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, edit, fase } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title={
            edit === true ? "Editando " + fase.name : "Crear nuevo elemento"
          }
          okText={edit === true ? "Editar" : "Crear"}
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre">
              {getFieldDecorator("name", {
                initialValue: edit === true ? fase.name : "",
                rules: [
                  {
                    required: true,
                    message: "Ingresa el nombre de tu elemento"
                  }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Días hábiles">
              {getFieldDecorator("days", {
                initialValue: edit === true ? fase.days : 0,
                rules: [
                  { type: "number", message: "Numero no válido" },
                  {
                    required: true,
                    message: "Ingresa el nombre de tu elemento"
                  }
                ]
              })(<InputNumber min={0} />)}
            </Form.Item>

            <Form.Item label="% Pagos">
              {getFieldDecorator("percent", {
                initialValue: edit === true ? fase.percent : 0,
                rules: [
                  { type: "number", message: "Numero no válido" },
                  {
                    required: true,
                    message: "Ingresa el nombre de tu elemento"
                  }
                ]
              })(<InputNumber min={0} />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "0",
      edit: false,
      selectedConcepto: "-1",
      etapa: 1,
      distribucion: {},
      selectedResponsable: "0",
      selectedAUXI: "-1",
      selectedREV: "-1",
      selectedTabulador: "-1",
      selectedDate: moment().format(dateFormat),
      distribucion_proyeccion: {},
      fases: {},
      nfases: 0,
      selectedFase: -1,
      calendario: {},
      ncalendario: 0,

      visible: false,
      editModal: false,
      npagos: 1,
      pagos: {},
      view: false
    };
  }

  ////////////////////////////////////////////////////////////////////////////
  /////////////////////////////Modal
  showModal = () => {
    this.setState({ visible: true });
  };
  editModal = id => {
    const { fases } = this.state;

    this.setState({ visible: true, editModal: true, selectedFase: id });
  };

  handleCancel = () => {
    this.setState({ visible: false, editModal: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      form.resetFields();
      this.setState({ visible: false, editModal: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  addModal = () => {
    const { editModal, selectedFase } = this.state;
    const modalForm = this.formRef.props.form;
    let name = "";
    modalForm.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      //name=values.title;

      modalForm.resetFields();
      // this.props.addConcepto(name)
      editModal === false
        ? this.handleFaseAdd(values)
        : this.handleFaseEdit(selectedFase, values);

      this.setState({ visible: false, editModal: false });
    });
  };

  //////////////////////////////////////////////////////////////////

  handleFaseAdd = values => {
    const { nfases, fases } = this.state;
    this.setState({
      fases: { ...fases, [nfases]: { ...values } },
      nfases: nfases + 1
    });
  };

  handleFaseRemove = id => {
    console.log(id);
    let newObjState = JSON.parse(JSON.stringify(this.state.fases));
    delete newObjState[id];
    this.setState({ fases: newObjState });
  };

  handleFaseEdit = (id, values) => {
    const { nfases, fases } = this.state;

    this.setState({ fases: { ...fases, [id]: { ...fases[id], ...values } } });
  };

  handleGenerateMetodo = () => {
    const {
      etapa,
      email,
      movil,
      cp,
      nombre,
      nombreProyecto,
      contraccionProyecto,
      proyectoDireccion,
      selectedAUXI,
      selectedConcepto,
      selectedREV,
      selectedResponsable,
      selectedDate,
      selectedTabulador,

      superficie,
      cos,
      cus,

      distribucion_proyeccion,

      fases
    } = this.state;

    const { pane, cpu } = this.props;

    let resumen_rows = [];
    let suma_total = 0;
    let suma_costo_total = 0;
    const resumen_col = [
      { title: "Conceptos del Proyecto", dataIndex: "name", key: "name" },
      { title: "M2", dataIndex: "m2", key: "m2" },
      { title: "Costo U. x m2", dataIndex: "costo", key: "costo" },
      { title: "Inversion total", dataIndex: "total", key: "total" }
    ];
    Object.keys(distribucion_proyeccion).map((id, index) => {
      const total = distribucion_proyeccion[id].total_dist;
      const variable_id = distribucion_proyeccion[id].pane_id;
      const name = distribucion_proyeccion[id].name;
      const pane_id = distribucion_proyeccion[id].pane_id;

      cpu.rows.map((row, rowIndex) => {
        if (
          (row.linterval !== "en adelante" &&
            total > Number(row.finterval) &&
            total < Number(row.linterval)) ||
          (total > row.finterval && row.linterval === "en adelante")
        ) {
          const resumen_costo = Number(row[variable_id]);
          const resumen_total = total * resumen_costo;
          suma_total += total;
          suma_costo_total += resumen_total;
          const resumen_row = {
            key: String(id),
            name: tabuladores[pane_id] + " - " + name,
            m2: Number(total),
            costo: resumen_costo,
            total: resumen_total
          };
          resumen_rows.push(resumen_row);
        }
      });
    });
    resumen_rows.push({
      key: "Totales",
      name: "Totales",
      m2: suma_total,
      costo: "",
      total: suma_costo_total
    });

    //return(<Table bordered dataSource={resumen_rows} columns={resumen_col} pagination={false}/>)
    let fases_rows = {};
    let suma_price = 0;
    let suma_percent = 0;
    let suma_days = 0;
    let entrega = selectedDate;

    const fases_columns = [
      { title: "FASES", dataIndex: "name", key: "name" },
      { title: "Periodo", dataIndex: "days", key: "days" },
      { title: "% Pagos", dataIndex: "percent", key: "percent" },
      { title: "Cantidades", dataIndex: "price", key: "price" },
      {
        title: "Fechas de Entrega Estimadas",
        dataIndex: "endDate",
        key: "endDate"
      },
      {
        title: "Operaciones",
        key: "action",
        render: record => (
          <div>
            {record.key !== "Totales" && (
              <span>
                <a onClick={() => this.editModal(record.id)}>Editar</a>
                <Divider type="vertical" />
                <a onClick={() => this.handleFaseRemove(record.id)}>Borrar</a>
              </span>
            )}
          </div>
        )
      }
    ];

    Object.keys(fases).map((id, index) => {
      const fase = fases[id];
      const name = fase.name;
      const days = fase.days;
      const percent = fase.percent;
      const price = suma_costo_total * (fase.percent / 100);
      //const endDate=0
      entrega = moment(entrega)
        .businessAdd(days)
        .format(dateFormat);
      const endDate = entrega;
      suma_days = days + suma_days;
      suma_percent = percent + suma_percent;
      suma_price = price + suma_price;

      fases_rows[id] = {
        id: id,
        key: String(id),
        name: name,
        days: days,
        percent: percent,
        price: price,
        endDate: endDate
      };
    });
    fases_rows["Totales"] = {
      key: "Totales",
      name: "Totales",
      days: suma_days + " días",
      percent: suma_percent + "%",
      price: "$" + suma_price,
      endDate: ""
    };

    /*this.props.proyectoSave({
			email:email,
			movil:movil,
			cp:cp,
			nombre:nombre,
			proyectoDireccion:proyectoDireccion,

		},{
			etapa:etapa,
			nombreProyecto:nombreProyecto,
			contraccionProyecto:contraccionProyecto,
			selectedAUXI:selectedAUXI,
			selectedConcepto:selectedConcepto,
			selectedREV:selectedREV,
			selectedResponsable:selectedResponsable,
			selectedDate:selectedDate,
			selectedTabulador:selectedTabulador,
			superficie:superficie,
			cos:cos,
			cus:cus,

			distribucion_proyeccion:distribucion_proyeccion,

			resumen_rows:resumen_rows,

			suma_total:suma_total,
			suma_costo_total:suma_costo_total,
		},
		  this.handleRedirect
			)*/

    if (suma_percent === 100) {
      const metodo_rows = [];
      const metodo_columns = [
        { title: "Concepto", dataIndex: "name", key: "name" },
        {
          title: "Cantidad",
          dataIndex: "cantidad",
          key: "cantidad",
          render: (record, index) => {
            let item;
            //console.log('@_@_@_@')
            //console.log(index)
            //console.log('@_@_@_@')
            switch (index.key) {
              case "NPagos":
                item = (
                  <InputNumber
                    value={this.state.npagos}
                    onChange={this.handleNPagosChange}
                    max={100}
                    min={1}
                  />
                );
                break;
              default:
                item = <span>{record}</span>;
                break;
            }
            return item;
          }
        }
      ];
      let fDate = moment(selectedDate);
      let lDate = moment(entrega);
      let diffM = lDate.diff(fDate, "months", true);
      //console.log(selectedResponsable)
      //console.log(this.props.staff.data)
      //const responsable = this.props.staff.data[selectedResponsable].nombre;
      const responsable = this.props.staff.data.filter(staff => staff.id == selectedResponsable)[0].nombre;
      console.log(responsable)
      metodo_rows.push({
        key: "Total",
        name: "Total de Proyecto",
        cantidad: "$" + suma_price
      });
      metodo_rows.push({
        key: "Duracion",
        name: "Duracion estimada (dias h.)",
        cantidad: suma_days + "(" + diffM.toFixed(2) + " meses)"
      });
      metodo_rows.push({
        key: "NPagos",
        name: "N. pagos parciales",
        cantidad: "Meses"
      });
      //metodo_rows.push({key:'Intervalos',name:'Intervalos', cantidad:"Meses"})
      metodo_rows.push({
        key: "Responsable",
        name: "Responsable",
        cantidad: responsable
      });
      metodo_rows.push({
        key: "fDate",
        name: "Fecha estimada de arranque",
        cantidad: selectedDate
      });

      /////
      let calendario_columns = [
        {
          title: "Fechas de Pago",
          width: 80,
          children: [
            {
              title: "",
              children: [
                {
                  title: "Totales",
                  children: [
                    {
                      title: "",
                      dataIndex: "date",
                      key: "date",
                      render: (record, row) => {
                        if (row.key === "lastTotal") {
                          return <span>Totales:</span>;
                        }
                        return (
                          <DatePicker
                            placeholder="Fecha de pago"
                            onChange={e =>
                              this.handleCalendarioDateChange(e, row.key)
                            }
                            format={dateFormat}
                          />
                        );
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        { title: "Fases del Proyecto", children: [] },
        {
          title: "Total",
          fixed: "right",
          children: [
            {
              title: "Cantidad",
              dataIndex: "total_price",
              key: "total_price",
              render: record => (
                <span>
                  $
                  {record
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              )
            },
            {
              title: "% Pago",
              dataIndex: "total_percent",
              key: "total_percent",
              render: record => <span>{record.toFixed(2)}%</span>
            }
          ]
        }
      ];
      //calendario_columns.push({title:'Total',dataIndex:'total',key:'total',})
      //let calendario={};
      Object.keys(fases).map((id, index) => {
        const fase = fases[id];
        const fase_row = fases_rows[id];
        const name = fase.name;
        const days = fase.days;
        const percent = fase.percent;
        const price = suma_costo_total * (fase.percent / 100);
        //const endDate=0
        entrega = moment(entrega)
          .businessAdd(days)
          .format(dateFormat);
        const endDate = entrega;
        suma_days = days + suma_days;
        suma_percent = percent + suma_percent;
        suma_price = price + suma_price;

        calendario_columns[1].children.push({
          title: fase.name,
          children: [
            {
              title:
                "$" +
                (fase_row != undefined
                  ? fase_row.price
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0),
              children: [
                {
                  title: "Cantidad",
                  key: String(id),
                  dataIndex: String(id) + "_price",
                  render: record => (
                    <span>
                      $
                      {record
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  )
                }
              ]
            },

            {
              title: (fase_row != undefined ? fase_row.percent : 0) + "%",
              children: [
                {
                  title: "% Pago",
                  key: String(id) + "_percent",
                  dataIndex: String(id) + "_percent",
                  render: (record, index) => {
                    if (index.key === "lastTotal") {
                      return <span>{record}%</span>;
                    }
                    return (
                      <InputNumber
                        onChange={e =>
                          this.handleCalendarioPercentChange(
                            e,
                            index.key,
                            String(id)
                          )
                        }
                        min={0}
                        max={100}
                      />
                    );
                  }
                }
              ]
            }
          ]
        });
      });

      ///////

      this.setState({
        resumen_rows: resumen_rows,
        suma_total: suma_total,
        suma_costo_total: suma_costo_total,
        fases_rows: fases_rows,
        suma_price: suma_price,
        suma_percent: suma_percent,
        suma_days: suma_days,
        entrega: entrega,
        etapa: etapa + 1,
        metodo_rows: metodo_rows,
        metodo_columns: metodo_columns,
        diffM: diffM,
        calendario_columns: calendario_columns
      });
    } else {
      toast.error("El costo de las fases no equivale al 100%");
    }
  };

  handleGuardarMetodo = () => {
    const {
      etapa,
      email,
      movil,
      cp,
      nombre,
      nombreProyecto,
      contraccionProyecto,
      proyectoDireccion,
      selectedAUXI,
      selectedConcepto,
      selectedREV,
      selectedResponsable,
      selectedDate,
      selectedTabulador,

      superficie,
      cos,
      cus,

      distribucion_proyeccion,

      fases,
      fases_rows,

      resumen_rows,

      suma_total,
      suma_costo_total,

      metodo_columns,
      metodo_rows,
      npagos,
      pagos,

      calendario_columns
    } = this.state;

    let calendario_rows = {};
    let total_pagos = 0;
    let total_col = {};
    total_col.key = "lastTotal";
    total_col.date = moment().format(dateFormat);
    for (let i = 0; i < npagos; i++) {
      calendario_rows[i] = {};
      calendario_rows[i].key = i;
      calendario_rows[i].date = moment().format(dateFormat);
      //calendario_rows[i].total=0
      let total = 0;

      Object.keys(fases).map((id, index) => {
        const percent = pagos[i] ? pagos[i][id] || 0 : 0;
        const price = fases_rows[id].price * (percent / 100);
        calendario_rows[i][id + "_percent"] = percent;
        calendario_rows[i][id + "_price"] = price;

        total_col[id + "_percent"] = total_col[id + "_percent"]
          ? total_col[id + "_percent"] + percent
          : percent;
        total_col[id + "_price"] = total_col[id + "_price"]
          ? total_col[id + "_price"] + price
          : price;

        total += price;
      });
      calendario_rows[i].total_price = total;
      calendario_rows[i].total_percent =
        (total * 100) / this.state.suma_costo_total;
      total_col["total_price"] = total_col["total_price"]
        ? total_col["total_price"] + total
        : total;
      total_col["total_percent"] = total_col["total_percent"]
        ? total_col["total_percent"] +
          (total * 100) / this.state.suma_costo_total
        : (total * 100) / this.state.suma_costo_total;
    }

    calendario_rows["lastTotal"] = total_col;

    if (total_col["total_percent"] === 100) {
      this.props.proyectoSave(
        {
          email: email,
          movil: movil,
          cp: cp,
          nombre: nombre,
          proyectoDireccion: proyectoDireccion
        },
        {
          etapa: etapa,
          nombreProyecto: nombreProyecto,
          contraccionProyecto: contraccionProyecto,
          selectedAUXI: selectedAUXI,
          selectedConcepto: selectedConcepto,
          selectedREV: selectedREV,
          selectedResponsable: selectedResponsable,
          selectedDate: selectedDate,
          selectedTabulador: selectedTabulador,
          superficie: superficie,
          cos: cos,
          cus: cus,

          distribucion_proyeccion: distribucion_proyeccion,

          resumen_rows: resumen_rows,

          suma_total: suma_total,
          suma_costo_total: suma_costo_total,

          fases: fases,
          fases_rows: fases_rows,

          //metodo_columns:metodo_columns,
          metodo_rows: metodo_rows,
          npagos: npagos,
          pagos: pagos,
          calendario_rows: calendario_rows
          //	calendario_columns:calendario_columns,
        },
        this.handleRedirect
      );
    } else {
      toast.error("Los pagos no equivalen al 100% de las fases");
    }
  };

  generateProyeccion = () => {
    const { subconceptos_object } = this.props;
    const {
      distribucion,
      superficie,
      cos,
      cus,
      selectedTabulador,
      selectedResponsable,
      selectedAUXI,
      selectedDate
    } = this.state;
    let data = {};

    if (selectedTabulador !== "-1" && selectedResponsable !== "0") {
      //this.props.fetchPaneById(selectedTabulador);
      console.log(selectedResponsable)
      Object.keys(subconceptos_object).map((id, index) => {
        let total_dist = Number(subconceptos_object[id].dimensiones);
        data[id] = {
          ...subconceptos_object[id],
          distribucion_table: [
            {
              key: "0",
              name: "N1",
              m2: Number(subconceptos_object[id].dimensiones)
            }
          ],
          distribucion: { 0: Number(subconceptos_object[id].dimensiones) }
        };
        Object.keys(distribucion[id]).map((elem, index) => {
          const m2 =
            subconceptos_object[id].dimensioncus *
            (distribucion[id][elem] / 100);
          if (elem <= subconceptos_object[id].niveles) {
            total_dist += Number(m2);
            const distribucion_table = data[id].distribucion_table.concat({
              key: String(elem),
              name: "N" + (Number(elem) + 1),
              m2: Number(m2)
            });
            data[id].distribucion = { ...data[id].distribucion, [elem]: m2 };
            data[id].distribucion_table = distribucion_table;
          }
        });
        data[id].total_dist = total_dist;
      });

      this.setState({
        distribucion_proyeccion: data,
        etapa: this.state.etapa + 1
      });
    } else {
      toast.error(
        "Asegurate de haber seleccionado un responsable y un tabulador"
      );
    }
  };

  totalDist = id => {
    const {
      niveles,
      dimensiones,
      dimensioncus
    } = this.props.subconceptos_object[id];
    const { distribucion, superficie, cos, cus } = this.state;

    let suma = Number(dimensiones);
    Object.keys(distribucion[id]).map((elem, index) => {
      const m2 = dimensioncus * (this.state.distribucion[id][elem] / 100);
      if (elem <= niveles) suma += Number(m2);
    });

    return suma;
  };

  handlePercentChange = (e, id) => {
    const {
      niveles,
      dimensiones,
      dimensioncus
    } = this.props.subconceptos_object[id];
    const { distribucion, superficie, cos, cus } = this.state;
    const { name, value } = e.target;
    let suma = 0;
    Object.keys(distribucion[id]).map((elem, index) => {
      if (elem !== name && elem <= niveles)
        suma += Number(distribucion[id][elem]);
    });
    if (
      Number(suma) + Number(value) <= 100 &&
      (dimensioncus * value) / 100 <= Number(dimensiones)
    ) {
      console.log("@@@@@@@");
      console.log(dimensiones);
      console.log((dimensioncus * value) / 100);
      console.log("@@@@@@@");
      this.setState({
        distribucion: {
          ...this.state.distribucion,
          [id]: {
            ...this.state.distribucion[id],
            [name]: Number(value)
          }
        }
      });
    }
  };

  handleCalendarioPercentChange = (value, key, name) => {
    const pagos = this.state.pagos;
    const pago = this.state.pagos[key];
    //const {distribucion,superficie,cos,cus}= this.state;
    console.log("@@@@@@@");
    console.log(value, key, name);

    if (Number(value) <= 100 && Number(value) >= 0) {
      //console.log('@@@@@@@')
      //console.log(dimensiones)
      //console.log(dimensioncus*value/100)
      //console.log('@@@@@@@')
      this.setState({
        pagos: {
          ...this.state.pagos,
          [key]: {
            ...this.state.pagos[key],
            [name]: Number(value)
          }
        }
      });
    }
  };

  handleCalendarioDateChange = (value, key) => {
    //console.log('fechas')
    //console.log(value)

    if (value !== undefined && value !== null) {
      //this.setState({selectedDate:value.format(dateFormat)});
      ////console.log('ssi entro');

      this.setState({
        pagos: {
          ...this.state.pagos,
          [key]: {
            ...this.state.pagos[key],
            date: value.format(dateFormat)
          }
        }
      });
    }
  };

  renderDistribucion = id => {
    //alert(id)
    const { subconceptos_object } = this.props;
    const { superficie, cos, cus } = this.state;
    let items = [];
    if (this.state.distribucion[id] !== undefined) {
      if (subconceptos_object[id].niveles !== undefined) {
        for (let i = 0; i <= subconceptos_object[id].niveles; i++) {
          //alert(id)

          if (i > 0) {
            const m2 =
              subconceptos_object[id].dimensioncus *
              (this.state.distribucion[id][i] / 100);
            items.push(
              <Row key={i}>
                N{i + 1}:{" "}
                <Input
                  value={this.state.distribucion[id][i] || 0}
                  type="number"
                  min={0}
                  max={100}
                  onChange={e => this.handlePercentChange(e, id)}
                  name={i}
                  style={{ width: 80 }}
                />
                % {(m2 ? m2.toFixed(2) : 0) || 0}m2
              </Row>
            );
          } else {
            const m2 = subconceptos_object[id].dimensiones;
            items.push(
              <Row key={i}>
                N{i + 1}:{" "}
                <Input
                  value={m2 || 0}
                  type="number"
                  min={0}
                  max={100}
                  disabled
                  name={i}
                  style={{ width: 80 }}
                />
                m2
              </Row>
            );
          }
        }
      } else {
        //esto nunca sucede, pero ojo aquí. esta desactualizado
        items.push(
          <Row key={0}>
            N1{" "}
            <Input
              name={0}
              value={this.state.distribucion[id][0] || 0}
              type="number"
              onChange={e => this.handlePercentChange(e, id)}
              style={{ width: 80 }}
            />
            % {superficie * cus * (this.state.distribucion[id][0] / 100)}m2
          </Row>
        );
      }
      items.push(<Row key="total">Total: {this.totalDist(id)}m2</Row>);
    } else {
      this.setState({ distribucion: { ...this.state.distribucion, [id]: [] } });
    }

    return items;
  };

  handleDimensionesChange = e => {
    // var change = {};
    //change[name] = e.target.value;
    //this.setState(change);
    if (
      e.target.value >= 0 &&
      e.target.value <= this.state.superficie * this.state.cos
    )
      this.props.handleDimensionesChange(e.target.name, Number(e.target.value));
  };

  handleDimensioncusChange = e => {
    // var change = {};
    //change[name] = e.target.value;
    //this.setState(change);
    if (
      e.target.value >= 0 &&
      e.target.value <= this.state.superficie * this.state.cus
    )
      this.props.handleDimensioncusChange(
        e.target.name,
        Number(e.target.value)
      );
  };

  handleNivelesChange = e => {
    //var change = {};
    //change[name] = e.target.value;
    //this.setState(change)
    if (e.target.value >= 0 && e.target.value <= 100)
      this.props.handleNivelesChange(e.target.name, Number(e.target.value));
  };

  handleProjectTypeSelectChange = value => {
    //console.log(`selected ${value}`);
    // console.log('ey k oonda')
    this.setState({ selectedConcepto: value });
    alert("entro");
  };

  componentWillMount() {
    this.props.fetchAllConceptoCompleted();
    this.props.fetchAllStaff();
    this.props.fetchAllPanes();

    const {
      match: { params }
    } = this.props;
    //alert(params.id);
    if (params.id != "" && params.id != undefined) {
      //alert(params.id+' entro')

      this.setState({ view: true, etapa: 4 });
      this.props.proyectoFetch(params.id);
      //alert(params.id)
      //delay(3000)
      //this.props.fetchPaneById(this.props.proyecto.selectedTabulador)
    }
  }

  handleCreate = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      console.log("entrando a ", this.state.edit);
      if (this.state.edit === true) {
        console.log("editar desde el formulario");
        //  this.props.clienteUpdate({...values,id:this.props.cliente.id},this.handleRedirect)
      } else {
        console.log("guardar desde el formulario");
        //  this.props.clienteSave(values,this.handleRedirect)
        this.props.fetchConceptoById(
          values.selectedConcepto,
          values.superficie,
          values.cos,
          values.cus
        );
        this.setState({ ...values, etapa: 2 });
      }
      //form.resetFields();
    });
  };

  handleRedirect = e => {
    this.props.history.push("/proyectos/");
  };

  handleResponsableSelectChange = value => {
    //console.log(`selected ${value}`);
    this.setState({ selectedResponsable: value });
  };
  handleTabuladorSelectChange = value => {
    //console.log(`selected ${value}`);

    this.setState({ selectedTabulador: value });
    this.props.fetchPaneById(value);
  };
  handleAUXISelectChange = value => {
    //console.log(`selected ${value}`);
    this.setState({ selectedAUXI: value });
  };
  handleREVSelectChange = value => {
    //console.log(`selected ${value}`);
    this.setState({ selectedREV: value });
  };

  handleDateChange = value => {
    //console.log('fechas')
    //console.log(value)

    if (value !== undefined && value !== null) {
      this.setState({ selectedDate: value.format(dateFormat) });
      ////console.log('ssi entro');
    }
  };
  handleNPagosChange = value => {
    //console.log('fechas')
    //console.log(e)
    //const value= e.target.value;

    if (value >= 1) {
      this.setState({ npagos: value });
      ////console.log('ssi entro');
    }
  };

  renderStaff = () => {
    var staff = this.props.staff.data;
    var staffData = [];
    Object.keys(staff||{}).forEach(function(el) {
      staffData.push({ key: staff[el].id, label: staff[el].nombre });
      //staffData.push({key:staff[el].nombre,label:staff[el].nombre});
    });
    return (
      <Select defaultValue="0" onChange={this.handleResponsableSelectChange}>
        <Option value="0">Responsable</Option>
        {staffData.map(function(elem, index) {
          //  //console.log(elem)
          return <Option value={elem.key}>{elem.label}</Option>;
        })}
      </Select>
    );
  };
  renderPanes = () => {
    const { panes } = this.props;
    var paneData = [];
    Object.keys(panes).forEach(function(el) {
      paneData.push({ key: panes[el].id, label: panes[el].name });
      //paneData.push({key:staff[el].nombre,label:staff[el].nombre});
    });
    return (
      <Select
        style={{ width: 200 }}
        defaultValue="-1"
        onChange={this.handleTabuladorSelectChange}
      >
        <Option value="-1">Tabulador</Option>
        {paneData.map(function(elem, index) {
          //  //console.log(elem)
          return <Option value={elem.key}>{elem.label}</Option>;
        })}
      </Select>
    );
  };
  renderResumenTable = () => {
    const { pane, cpu } = this.props;
    const { distribucion_proyeccion } = this.state;
    let resumen_rows = [];
    let suma_total = 0;
    let suma_costo_total = 0;
    const resumen_col = [
      { title: "Conceptos del Proyecto", dataIndex: "name", key: "name" },
      { title: "M2", dataIndex: "m2", key: "m2" },
      { title: "Costo U. x m2", dataIndex: "costo", key: "costo" },
      { title: "Inversion total", dataIndex: "total", key: "total" }
    ];
    Object.keys(distribucion_proyeccion).map((id, index) => {
      const total = distribucion_proyeccion[id].total_dist;
      const variable_id = distribucion_proyeccion[id].pane_id;
      const name = distribucion_proyeccion[id].name;
      const pane_id = distribucion_proyeccion[id].pane_id;

      cpu.rows.map((row, rowIndex) => {
        if (
          (row.linterval !== "en adelante" &&
            total > Number(row.finterval) &&
            total < Number(row.linterval)) ||
          (total > row.finterval && row.linterval === "en adelante")
        ) {
          const resumen_costo = Number(row[variable_id]);
          const resumen_total = total * resumen_costo;
          suma_total += total;
          suma_costo_total += resumen_total;
          const resumen_row = {
            key: String(id),
            name: tabuladores[pane_id] + " - " + name,
            m2: Number(total),
            costo: "$" + resumen_costo,
            total: "$" + resumen_total
          };
          resumen_rows.push(resumen_row);
        }
      });
    });
    resumen_rows.push({
      key: "Totales",
      name: "Totales",
      m2: suma_total,
      costo: "",
      total: "$" + suma_costo_total
    });

    return (
      <Table
        title={() => "Costos"}
        bordered
        dataSource={
          this.state.view === true && this.props.proyecto !== undefined
            ? this.props.proyecto.resumen_rows
            : resumen_rows
        }
        columns={resumen_col}
        pagination={false}
      />
    );
  };
  renderFases = () => {
    const { pane, cpu } = this.props;
    const { distribucion_proyeccion, selectedDate, fases } = this.state;
    let resumen_rows = [];
    let fases_rows = [];
    let suma_total = 0;
    let suma_costo_total = 0;

    let suma_price = 0;
    let suma_percent = 0;
    let suma_days = 0;
    let entrega = selectedDate;

    const resumen_col = [
      { title: "Conceptos del Proyecto", dataIndex: "name", key: "name" },
      { title: "M2", dataIndex: "m2", key: "m2" },
      { title: "Costo U. x m2", dataIndex: "costo", key: "costo" },
      { title: "Inversion total", dataIndex: "total", key: "total" }
    ];

    const fases_columns = [
      { title: "FASES", dataIndex: "name", key: "name" },
      { title: "Periodo", dataIndex: "days", key: "days" },
      { title: "% Pagos", dataIndex: "percent", key: "percent" },
      { title: "Cantidades", dataIndex: "price", key: "price" },
      {
        title: "Fechas de Entrega Estimadas",
        dataIndex: "endDate",
        key: "endDate"
      },
      {
        title: "Operaciones",
        key: "action",
        render: record => (
          <div>
            {record.key !== "Totales" && (
              <span>
                <a onClick={() => this.editModal(record.id)}>Editar</a>
                <Divider type="vertical" />
                <a onClick={() => this.handleFaseRemove(record.id)}>Borrar</a>
              </span>
            )}
          </div>
        )
      }
    ];
    const fases_columns_view = [
      { title: "FASES", dataIndex: "name", key: "name" },
      { title: "Periodo", dataIndex: "days", key: "days" },
      { title: "% Pagos", dataIndex: "percent", key: "percent" },
      { title: "Cantidades", dataIndex: "price", key: "price" },
      {
        title: "Fechas de Entrega Estimadas",
        dataIndex: "endDate",
        key: "endDate"
      }
    ];

    Object.keys(distribucion_proyeccion).map((id, index) => {
      const total = distribucion_proyeccion[id].total_dist;
      const variable_id = distribucion_proyeccion[id].pane_id;
      const name = distribucion_proyeccion[id].name;
      const pane_id = distribucion_proyeccion[id].pane_id;

      cpu.rows.map((row, rowIndex) => {
        if (
          (row.linterval !== "en adelante" &&
            total > Number(row.finterval) &&
            total < Number(row.linterval)) ||
          (total > row.finterval && row.linterval === "en adelante")
        ) {
          const resumen_costo = Number(row[variable_id]);
          const resumen_total = total * resumen_costo;
          suma_total += total;
          suma_costo_total += resumen_total;
          const resumen_row = {
            key: String(id),
            name: tabuladores[pane_id] + " - " + name,
            m2: Number(total),
            costo: resumen_costo,
            total: resumen_total
          };
          resumen_rows.push(resumen_row);
        }
      });
    });
    resumen_rows.push({
      key: "Totales",
      name: "Totales",
      m2: suma_total,
      costo: "",
      total: suma_costo_total
    });

    Object.keys(fases).map((id, index) => {
      const fase = fases[id];
      const name = fase.name;
      const days = fase.days;
      const percent = fase.percent;
      const price = suma_costo_total * (fase.percent / 100);
      //const endDate=0
      entrega = moment(entrega)
        .businessAdd(days)
        .format(dateFormat);
      const endDate = entrega;
      suma_days = days + suma_days;
      suma_percent = percent + suma_percent;
      suma_price = price + suma_price;

      fases_rows.push({
        id: id,
        key: String(id),
        name: name,
        days: days + " días",
        percent: percent + "%",
        price: "$" + price,
        endDate: endDate
      });
    });
    fases_rows.push({
      key: "Totales",
      name: "Totales",
      days: suma_days + " días",
      percent: suma_percent + "%",
      price: "$" + suma_price,
      endDate: ""
    });

    return (
      <Table
        bordered
        dataSource={
          this.state.view === true && this.props.proyecto !== undefined
            ? Object.values(this.props.proyecto.fases_rows)
            : fases_rows
        }
        columns={this.state.view === true ? fases_columns_view : fases_columns}
        pagination={false}
      />
    );
  };
  renderMetodo = () => {
    const { metodo_rows, metodo_columns } = this.state;
    /*const {selectedDate,entrega,suma_days,suma_price, selectedResponsable}=this.state;
	const metodo_rows=[]
	const metodo_columns=[
		{title:'Concepto', dataIndex:'name', key:'name'},
		{
			title:'Cantidad',
			dataIndex:'cantidad',
			key:'cantidad',
			render:(record,index)=>{
				let item;
				//console.log('@_@_@_@')
				//console.log(index)
				//console.log('@_@_@_@')
				switch(index.key){
					case 'NPagos':
					item=(<InputNumber value={this.state.npagos} onChange={this.handleNPagosChange} max={100} min={1}/>)
					break;
					default:
					item=(<span>{record}</span>)
					break;
				}
				return(item)}
		},
	]
	let fDate=moment(selectedDate)
	let lDate=moment(entrega)
	let diffM=lDate.diff(fDate,'months',true)
	const responsable = this.props.staff.data[selectedResponsable].nombre;
	metodo_rows.push({key:'Total',name:'Total de Proyecto', cantidad:"$"+suma_price})
	metodo_rows.push({key:'Duracion',name:'Duracion estimada', cantidad:suma_days+'('+diffM.toFixed(2)+' meses)'})
    metodo_rows.push({key:'NPagos',name:'N. pagos parciales', cantidad:"Meses"})
	metodo_rows.push({key:'Intervalos',name:'Intervalos', cantidad:"Meses"})
	metodo_rows.push({key:'Responsable',name:'Responsable', cantidad:responsable})
	metodo_rows.push({key:'fDate',name:'Fecha estimada de arranque', cantidad:selectedDate})
*/
    if (this.state.view === true) {
      const metodo_columns_view = [
        { title: "Concepto", dataIndex: "name", key: "name" },
        {
          title: "Cantidad",
          dataIndex: "cantidad",
          key: "cantidad",
          render: (record, index) => {
            let item;
            //console.log('@_@_@_@')
            //console.log(index)
            //console.log('@_@_@_@')
            switch (index.key) {
              case "NPagos":
                item = <span>{this.props.proyecto.npagos}</span>;
                break;
              default:
                item = <span>{record}</span>;
                break;
            }
            return item;
          }
        }
      ];
      return (
        <Table
          bordered
          dataSource={
            this.state.view === true && this.props.proyecto !== undefined
              ? this.props.proyecto.metodo_rows
              : metodo_rows
          }
          columns={metodo_columns_view}
          pagination={false}
        />
      );
      let fDate = moment(this.props.proyecto.selectedDate);
      let lDate = moment(this.props.proyecto.entrega);
      let diffM = lDate.diff(fDate, "months", true);

      /////
      let calendario_columns = [
        {
          title: "Fechas de Pago",
          width: 80,
          children: [
            {
              title: "",
              children: [
                {
                  title: "Totales",
                  children: [
                    {
                      title: "",
                      dataIndex: "date",
                      key: "date",
                      render: (record, row) => {
                        if (row.key === "lastTotal") {
                          return <span>Totales:</span>;
                        }
                        return <span>{record}</span>;
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        { title: "Fases del Proyecto", children: [] },
        {
          title: "Total",
          fixed: "right",
          children: [
            {
              title: "Cantidad",
              dataIndex: "total_price",
              key: "total_price",
              render: record => (
                <span>
                  $
                  {record
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              )
            },
            {
              title: "% Pago",
              dataIndex: "total_percent",
              key: "total_percent",
              render: record => <span>{record.toFixed(2)}%</span>
            }
          ]
        }
      ];
      //calendario_columns.push({title:'Total',dataIndex:'total',key:'total',})
      //let calendario={};
      Object.keys(this.props.proyecto.fases).map((id, index) => {
        const fase = this.props.proyecto.fases[id];
        const fase_row = this.props.proyecto.fases_rows[id];
        const name = fase.name;
        const days = fase.days;
        const percent = fase.percent;
        const price =
          this.props.proyecto.suma_costo_total * (fase.percent / 100);
        //const endDate=0

        calendario_columns[1].children.push({
          title: fase.name,
          children: [
            {
              title:
                "$" +
                (fase_row != undefined
                  ? fase_row.price
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0),
              children: [
                {
                  title: "Cantidad",
                  key: String(id),
                  dataIndex: String(id) + "_price",
                  render: record => (
                    <span>
                      $
                      {record
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  )
                }
              ]
            },

            {
              title: (fase_row != undefined ? fase_row.percent : 0) + "%",
              children: [
                {
                  title: "% Pago",
                  key: String(id) + "_percent",
                  dataIndex: String(id) + "_percent",
                  render: (record, index) => {
                    if (index.key === "lastTotal") {
                      return <span>{record}%</span>;
                    }
                    return (
                      <InputNumber
                        onChange={e =>
                          this.handleCalendarioPercentChange(
                            e,
                            index.key,
                            String(id)
                          )
                        }
                        min={0}
                        max={100}
                      />
                    );
                  }
                }
              ]
            }
          ]
        });
      });

      ///////
    }

    return (
      <Table
        bordered
        dataSource={
          this.state.view === true && this.props.proyecto !== undefined
            ? this.props.proyecto.metodo_rows
            : metodo_rows
        }
        columns={metodo_columns}
        pagination={false}
      />
    );
  };
  renderCalendario = () => {
    if (this.state.view === false) {
      const {
        npagos,
        fases,
        calendario_columns,
        pagos,
        fases_rows
      } = this.state;
      let calendario_rows = {};
      let total_pagos = 0;
      let total_col = {};
      total_col.key = "lastTotal";
      total_col.date = moment().format(dateFormat);
      for (let i = 0; i < npagos; i++) {
        calendario_rows[i] = {};
        calendario_rows[i].key = i;
        calendario_rows[i].date = moment().format(dateFormat);
        //calendario_rows[i].total=0
        let total = 0;

        Object.keys(fases).map((id, index) => {
          const percent = pagos[i] ? pagos[i][id] || 0 : 0;
          const price = fases_rows[id].price * (percent / 100);
          calendario_rows[i][id + "_percent"] = percent;
          calendario_rows[i][id + "_price"] = price;

          total_col[id + "_percent"] = total_col[id + "_percent"]
            ? total_col[id + "_percent"] + percent
            : percent;
          total_col[id + "_price"] = total_col[id + "_price"]
            ? total_col[id + "_price"] + price
            : price;

          total += price;
        });
        calendario_rows[i].total_price = total;
        calendario_rows[i].total_percent =
          (total * 100) / this.state.suma_costo_total;
        total_col["total_price"] = total_col["total_price"]
          ? total_col["total_price"] + total
          : total;
        total_col["total_percent"] = total_col["total_percent"]
          ? total_col["total_percent"] +
            (total * 100) / this.state.suma_costo_total
          : (total * 100) / this.state.suma_costo_total;
      }
      //calendario_rows['lastTotal'].total_price=total
      //calendario_rows['lastTotal'].total_percent=(total*100)/this.state.suma_costo_total
      calendario_rows["lastTotal"] = total_col;
      console.log(calendario_rows);

      return (
        <Table
          bordered
          pagination={false}
          dataSource={Object.values(calendario_rows)}
          scroll={{ x: 1300 }}
          columns={calendario_columns}
        />
      );
    } else {
      const metodo_columns_view = [
        { title: "Concepto", dataIndex: "name", key: "name" },
        {
          title: "Cantidad",
          dataIndex: "cantidad",
          key: "cantidad",
          render: (record, index) => {
            let item;
            //console.log('@_@_@_@')
            //console.log(index)
            //console.log('@_@_@_@')
            switch (index.key) {
              case "NPagos":
                item = <span>{this.props.proyecto.npagos}</span>;
                break;
              default:
                item = <span>{record}</span>;
                break;
            }
            return item;
          }
        }
      ];

      let fDate = moment(this.props.proyecto.selectedDate);
      let lDate = moment(this.props.proyecto.entrega);
      let diffM = lDate.diff(fDate, "months", true);

      /////
      let calendario_columns = [
        {
          title: "Fechas de Pago",
          width: 80,
          children: [
            {
              title: "",
              children: [
                {
                  title: "Totales",
                  children: [
                    {
                      title: "",
                      dataIndex: "date",
                      key: "date",
                      render: (record, row) => {
                        if (row.key === "lastTotal") {
                          return <span>Totales:</span>;
                        }
                        return <span>{record}</span>;
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        { title: "Fases del Proyecto", children: [] },
        {
          title: "Total",
          fixed: "right",
          children: [
            {
              title: "Cantidad",
              dataIndex: "total_price",
              key: "total_price",
              render: record => (
                <span>
                  $
                  {record
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              )
            },
            {
              title: "% Pago",
              dataIndex: "total_percent",
              key: "total_percent",
              render: record => <span>{record.toFixed(2)}%</span>
            }
          ]
        }
      ];
      //calendario_columns.push({title:'Total',dataIndex:'total',key:'total',})
      //let calendario={};
      Object.keys(this.props.proyecto.fases).map((id, index) => {
        const fase = this.props.proyecto.fases[id];
        const fase_row = this.props.proyecto.fases_rows[id];
        const name = fase.name;
        const days = fase.days;
        const percent = fase.percent;
        const price =
          this.props.proyecto.suma_costo_total * (fase.percent / 100);
        //const endDate=0

        calendario_columns[1].children.push({
          title: fase.name,
          children: [
            {
              title:
                "$" +
                (fase_row != undefined
                  ? fase_row.price
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0),
              children: [
                {
                  title: "Cantidad",
                  key: String(id),
                  dataIndex: String(id) + "_price",
                  render: record => (
                    <span>
                      $
                      {record
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  )
                }
              ]
            },

            {
              title: (fase_row != undefined ? fase_row.percent : 0) + "%",
              children: [
                {
                  title: "% Pago",
                  key: String(id) + "_percent",
                  dataIndex: String(id) + "_percent",
                  render: (record, index) => {
                    if (index.key === "lastTotal") {
                      return <span>{record}%</span>;
                    }
                    return <span>{record}%</span>;
                  }
                }
              ]
            }
          ]
        });
      });
      return (
        <Table
          bordered
          pagination={false}
          dataSource={Object.values(this.props.proyecto.calendario_rows)}
          scroll={{ x: 1300 }}
          columns={calendario_columns}
        />
      );
    }
  };

  render() {
    //console.log("copia esto we")
    //console.log(JSON.stringify(this.props.cpu))
    const {
      conceptos,
      concepto,
      subconceptos_array,
      subconceptos_object
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { etapa } = this.state;

    return (
      <Layout id="sider">
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          edit={this.state.editModal}
          onCancel={this.handleCancel}
          onCreate={this.addModal}
          fase={
            this.state.selectedFase !== -1
              ? this.state.fases[this.state.selectedFase]
              : {}
          }
        />

        <ASider />
        <Layout className="layout-container-antd">
          <AHeader>
            {this.state.view === true && this.props.proyecto !== undefined
              ? this.props.proyecto.nombreProyecto
              : "Nuevo Proyecto"}
          </AHeader>
          <Content style={{ margin: "24px 16px 0" }}>
            <div
              style={{
                overflowY: "auto",
                padding: 24,
                background: "#fff",
                height: "100%"
              }}
            >
              <Form layout="inline">
                {etapa === 1 && (
                  <div>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="">
                          {getFieldDecorator("selectedConcepto", {
                            initialValue: "-1",
                            rules: [
                              {
                                pattern: new RegExp("^((?!(-1)).)*$"),
                                message: "Selecciona un concepto válido"
                              },
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(
                            <Select
                              style={{ width: 200 }}
                              onChange={this.handleConceptoSelectChange}
                            >
                              <Option value="-1">Selecciona un concepto</Option>
                              {conceptos != undefined
                                ? Object.keys(conceptos).map((elem, index) => {
                                    return (
                                      <Option
                                        key={conceptos[elem].id}
                                        value={conceptos[elem].id}
                                      >
                                        {conceptos[elem].name}
                                      </Option>
                                    );
                                  })
                                : ""}
                            </Select>
                          )}
                        </Form.Item>

                        <Form.Item label="">
                          {getFieldDecorator("nombreProyecto", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.nombre
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<Input placeholder="Nombre del Proyecto" />)}
                        </Form.Item>

                        <Form.Item label="">
                          {getFieldDecorator("contraccionProyecto", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.nombre
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<Input placeholder="Contracción del Proyecto" />)}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Title level={4}>Cliente</Title>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="">
                          {getFieldDecorator("nombre", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.nombre
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<Input placeholder="Nombre del Cliente" />)}
                        </Form.Item>

                        <Form.Item label="">
                          {getFieldDecorator("movil", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.movil
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<Input placeholder="Telefono" />)}
                        </Form.Item>

                        <Form.Item label="">
                          {getFieldDecorator("email", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.email
                                : "",
                            rules: [
                              { type: "email", message: "Email inválido" },
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<Input placeholder="E-mail" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="">
                          {getFieldDecorator("proyectoDireccion", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.proyectoDireccion
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<Input placeholder="Dirección del Proyecto" />)}
                        </Form.Item>

                        <Form.Item label="">
                          {getFieldDecorator("cp", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.cp
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<Input placeholder="CP" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Title level={4}>Dimensiones</Title>
                    <Paragraph>
                      {" "}
                      Carga las dimensiones del proyecto para poder generar los
                      calculos.{" "}
                    </Paragraph>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Superficie total del terreno">
                          {getFieldDecorator("superficie", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.nombre
                                : "",
                            rules: [
                              { type: "number", message: "Numero no válido" },
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(<InputNumber min={0} placeholder="0 m2" />)}
                        </Form.Item>

                        <Form.Item label="cos">
                          {getFieldDecorator("cos", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.nombre
                                : "",
                            rules: [
                              { type: "number", message: "Numero no válido" },
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(
                            <InputNumber
                              min={0}
                              max={1}
                              step={0.05}
                              placeholder="0.00"
                            />
                          )}
                        </Form.Item>

                        <Form.Item label="cus">
                          {getFieldDecorator("cus", {
                            initialValue:
                              this.state.edit === true
                                ? this.props.cliente.nombre
                                : "",
                            rules: [
                              { type: "number", message: "Número no válido" },
                              {
                                required: true,
                                message: "Campo requerido!"
                              }
                            ]
                          })(
                            <InputNumber
                              min={0}
                              max={1}
                              step={0.05}
                              placeholder="0.00"
                            />
                          )}
                        </Form.Item>
                        <Form.Item>
                          <Button onClick={this.handleCreate} htmlType="submit">
                            Calcular
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state.etapa === 2 && subconceptos_array !== undefined && (
                  <div>
                    <Title level={4}>Distribución</Title>
                    <Paragraph>
                      Asigna la distribución del proyecto en sus diferentes
                      rubros.{" "}
                    </Paragraph>
                    <Row>
                      <Col span={8}>
                        <Row>
                          {" "}
                          <Text strong>Superficie</Text>{" "}
                        </Row>
                        <Row> {this.state.superficie} </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          {" "}
                          <Text strong>COS</Text>{" "}
                        </Row>
                        <Row> {this.state.cos} </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          {" "}
                          <Text strong>CUS</Text>{" "}
                        </Row>
                        <Row> {this.state.cus} </Row>
                      </Col>
                    </Row>
                    {subconceptos_array.map((elem, index) => {
                      return (
                        <Row key={elem.id}>
                          <Col span={8}>
                            <Text strong>{tabuladores[elem.pane_id]}</Text> -{" "}
                            {elem.name}{" "}
                            <Input
                              name={elem.id}
                              value={
                                subconceptos_object[elem.id].dimensiones || 0
                              }
                              onChange={this.handleDimensionesChange}
                              type="number"
                              min={0}
                              max={this.state.superficie * this.state.cos}
                              style={{ width: 80 }}
                              placeholder="0"
                            />{" "}
                            m2
                          </Col>

                          <Col span={8}>
                            Niveles Adicionales{" "}
                            <Input
                              value={
                                subconceptos_object[elem.id].dimensioncus || 0
                              }
                              type="number"
                              name={elem.id}
                              onChange={this.handleDimensioncusChange}
                              min={0}
                              max={this.state.superficie * this.state.cus}
                              style={{ width: 80 }}
                              placeholder="0"
                            />{" "}
                            m2
                          </Col>

                          <Col span={8}>
                            Niveles Adicionales{" "}
                            <Input
                              value={subconceptos_object[elem.id].niveles || 0}
                              type="number"
                              name={elem.id}
                              onChange={this.handleNivelesChange}
                              min={0}
                              max={100}
                              style={{ width: 80 }}
                              placeholder="0"
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Row>
                      {subconceptos_array.map((elem, index) => {
                        return (
                          <Col key={elem.id} span={6}>
                            <Row>
                              <Title level={4}>
                                {tabuladores[elem.pane_id]}
                              </Title>{" "}
                              ({elem.name})
                            </Row>

                            {this.renderDistribucion(elem.id)}
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                )}
                {etapa === 3 && (
                  <Row>
                    <Title level={4}>Responsables</Title>
                    <Paragraph>
                      Define quien sera el responsable del proyecto y de la
                      misma forma los tiempos que tomara con fechas estimadas.{" "}
                    </Paragraph>
                    <Row type="flex" justify="space-between">
                      <Col span={6}>
                        <Row> Responsable </Row>
                        <Row> {this.renderStaff()} </Row>
                      </Col>
                      <Col span={6}>
                        <Row> Numero de Auxiliares </Row>
                        <Row>
                          <Select
                            defaultValue="-1"
                            onChange={this.handleAUXISelectChange}
                          >
                            <Option value="-1">0</Option>
                            <Option value="0">1</Option>
                            <Option value="1">2</Option>
                            <Option value="2">3</Option>
                            <Option value="3">4</Option>
                          </Select>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row> Fecha de posible arranque </Row>
                        <Row>
                          {" "}
                          <DatePicker
                            placeholder="Fecha de arranque"
                            format={dateFormat}
                            onChange={this.handleDateChange}
                          />{" "}
                        </Row>
                      </Col>
                    </Row>
                    <Title level={4}>Proceso</Title>
                    <Paragraph>
                      Asigne el nivel de atención a los detalles con las
                      revisiones{" "}
                    </Paragraph>
                    <Row>
                      Número de Revisiones:{" "}
                      <Select
                        style={{ width: 50 }}
                        defaultValue="-1"
                        onChange={this.handleREVSelectChange}
                      >
                        <Option value="-1">0</Option>
                        <Option value="0">1</Option>
                        <Option value="1">2</Option>
                        <Option value="2">3</Option>
                        <Option value="3">4</Option>
                      </Select>
                    </Row>
                    <Title level={4}>Cotización</Title>
                    <Paragraph>
                      Selecciona el tabulador a aplicar para la proyección{" "}
                    </Paragraph>

                    <Row>Selecciona el tabulador: {this.renderPanes()}</Row>
                  </Row>
                )}
                {etapa === 4 && (
                  <Row>
                    <Title level={4}>Distribución</Title>
                    <Paragraph>
                      A continuación mostramos los cálculos estimados del
                      proyecto en base a la distribución.{" "}
                    </Paragraph>
                    <Row>
                      {Object.keys(this.state.distribucion_proyeccion).map(
                        (id, index) => {
                          const { distribucion_proyeccion } = this.state;
                          return (
                            <Col span={4}>
                              <Table
                                bordered
                                title={() =>
                                  tabuladores[
                                    distribucion_proyeccion[id].pane_id
                                  ] +
                                  " - " +
                                  distribucion_proyeccion[id].name
                                }
                                footer={() =>
                                  "Total: " +
                                  distribucion_proyeccion[id].total_dist +
                                  "m2"
                                }
                                dataSource={
                                  distribucion_proyeccion[id].distribucion_table
                                }
                                pagination={false}
                              >
                                <Column
                                  title="Nivel"
                                  dataIndex="name"
                                  key="name"
                                />
                                <Column title="M2" dataIndex="m2" key="m2" />
                              </Table>
                            </Col>
                          );
                        }
                      )}
                    </Row>
                    <Title level={4}>Resumen de Proyecto</Title>
                    <Paragraph>
                      A continuación mostramos los cálculos estimados del
                      proyecto{" "}
                    </Paragraph>
                    <Row type="flex" justify="space-between">
                      <Col span={8}>{this.renderResumenTable()}</Col>
                      <Col span={14}>
                        {this.props.pane !== undefined ? (
                          <Table
                            bordered
                            title={() => "Tabulador " + this.props.pane.name}
                            columns={this.props.cpu.columns}
                            dataSource={this.props.cpu.rows}
                            pagination={false}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Title level={4}>Fases del Proyecto y entregas</Title>
                    <Paragraph>
                      A continuación mostramos los cálculos estimados del
                      proyecto{" "}
                    </Paragraph>
                    <Row type="flex" justify="space-between">
                      {this.state.view === false && (
                        <Col span={5}>
                          <Row>
                            <Text strong> Fecha posible de arranque</Text>{" "}
                          </Row>
                          <Row>
                            {" "}
                            <DatePicker
                              placeholder="Fecha de arranque"
                              format={dateFormat}
                              onChange={this.handleDateChange}
                            />{" "}
                          </Row>
                          <Row>
                            <Text strong>
                              {" "}
                              Presione el botón para añadir una fase
                            </Text>
                          </Row>
                          <Row>
                            <Button type="primary" onClick={this.showModal}>
                              Añadir
                            </Button>
                          </Row>
                        </Col>
                      )}
                      <Col span={16}>{this.renderFases()}</Col>
                    </Row>
                  </Row>
                )}
                {etapa === 5 && (
                  <Pdf customSave={e => this.props.sendMail({dest:'ilovetechnology.jobsoltero@gmail.com',file:e.output('datauristring')})}>
                    {({ toPdf, targetRef }) => (
                      <Row>
                        <Row>
                          <div ref={targetRef}>
                            <Title level={4}>Método de pago</Title>
                            <Paragraph>
                              A continuación mostramos el método de pago para el
                              proyecto descrito anteriormente.{" "}
                            </Paragraph>

                            <Row>{this.renderMetodo()}</Row>
                            <Title level={4}>Calendario de Pago</Title>
                            <Paragraph>
                              A continuación mostramos las fechas de pagos
                              propuestas correspondientes al proyecto descrito
                              previamente:
                            </Paragraph>
                            {/*<Button type="primary">Generar Tabla</Button>*/}
                            <Row>{this.renderCalendario()}</Row>
                          </div>
                        </Row>
                        <button onClick={toPdf}>Enviar y descargar</button>

                      </Row>
                    )}
                  </Pdf>
                )}

                <Row
                  style={{ marginTop: 100 }}
                  type="flex"
                  justify="space-between"
                  align="top"
                >
                  {this.state.view === false && etapa > 2 && (
                    <Button
                      onClick={() =>
                        this.setState({ etapa: this.state.etapa - 1 })
                      }
                      type="primary"
                    >
                      Regresar
                    </Button>
                  )}
                  {etapa === 2 && (
                    <Button disabled type="primary">
                      Regresar
                    </Button>
                  )}
                  {etapa === 2 && (
                    <Button
                      onClick={() =>
                        this.setState({ etapa: this.state.etapa + 1 })
                      }
                      type="primary"
                    >
                      Siguiente
                    </Button>
                  )}
                  {etapa === 3 && (
                    <Button onClick={this.generateProyeccion} type="primary">
                      Proyectar
                    </Button>
                  )}
                  {etapa === 4 && this.state.view === false && (
                    <Button onClick={this.handleGenerateMetodo} type="primary">
                      Método de pago
                    </Button>
                  )}
                  {etapa === 5 && this.state.view === true && (
                    <Button
                      onClick={() =>
                        this.setState({ etapa: this.state.etapa - 1 })
                      }
                      type="primary"
                    >
                      Regresar
                    </Button>
                  )}
                  {etapa === 4 && this.state.view === true && (
                    <Button
                      onClick={() =>
                        this.setState({ etapa: this.state.etapa + 1 })
                      }
                      type="primary"
                    >
                      Método de pago
                    </Button>
                  )}
                  {this.state.view === false && etapa === 5 && (
                    <Button onClick={this.handleGuardarMetodo} type="primary">
                      Guardar
                    </Button>
                  )}
                </Row>
              </Form>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    //  cpu: state.cpu.CPUS,
    cliente: state.cliente.byID,
    proyecto: state.proyecto.byID,
    staff: state.staff,
    conceptos: state.concepto.dataCompleted,
    concepto: state.concepto.byID,
    subconceptos_array: state.concepto.subByID_array,
    subconceptos_object: state.concepto.subByID_object,

    panes: state.pane.data,
    pane: state.pane.paneByID,
    cpu: state.pane.cpuByID,
    rev: state.pane.revByID,
    auxi: state.pane.auxiByID
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // fetchAllCPU: cpuActions.fetchAllCPU,
      proyectoSave: proyectoActions.formSave,

      clienteFetch: clienteActions.fetchByID,
      proyectoFetch: proyectoActions.fetchByID,
      clienteSave: clienteActions.formSave,

      clienteUpdate: clienteActions.update,

      fetchAllStaff: staffActions.fetchAll,
      fetchAllPanes: paneActions.fetchAll,
      fetchPaneById: paneActions.fetchByID,

      fetchAllConceptoCompleted: conceptoActions.fetchAllCompleted,
      fetchConceptoById: conceptoActions.fetchByID_especial,
      handleDimensionesChange: conceptoActions.handleDimensionesChange,
      handleDimensioncusChange: conceptoActions.handleDimensioncusChange,
      handleNivelesChange: conceptoActions.handleNivelesChange,
      sendMail: authActions.sendMail
    },
    dispatch
  );
const WrappedClientes = Form.create()(Clientes);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedClientes)
);
