import { paneConstants } from "../constants";

const initialState = {
 data:[],paneByID:{},revByID:{},cpuByID:{},auxiByID:{}};

export default (state = initialState, action) => {
switch (action.type) {
   case paneConstants.FETCH_SUCCESS:
     return action.payload;
   case paneConstants.FETCH_BY_ID_SUCCESS:
     return {...state,...action.payload}
   default: return state;
}

};
