import React from "react";
import ConceptosTabContent from "../../components/arquitectos/ConceptosTabContent";
import ASider from "../../components/arquitectos/ASider";

import { Layout, Menu, Icon, Tabs, Button, Modal, Form, Input} from 'antd';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { cpuActions, revActions, auxiActions, paneActions, conceptoActions, gantt2Actions } from "../../actions";

const {confirm} = Modal;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const TabPane = Tabs.TabPane;


// importing todo components

// importing our todo's action's

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(

  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form,  } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Crear nuevo elemento"
          okText="Crear"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: 'Ingresa el nombre de tu elemento' }],
              })(<Input />)}
            </Form.Item>

          </Form>
        </Modal>
      );
    }
  },
);





class Catalogos extends React.Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    //console.log(props.cpu);
  const panes = [    ];
    this.state = {
      activeKey: '0',
      panes,
	  visible:false,
    };
  }


////////////////////////////////////////////////////////////////////////////
/////////////////////////////Modal
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
	this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
 	 if (err) {
 	   return;
 	 }

 	 console.log('Received values of form: ', values);
 	 form.resetFields();
 	 this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };


  addModal = () => {
	const modalForm= this.formRef.props.form;
	let name= '';
	modalForm.validateFields((err, values) => {
	  if (err) {
		return;
	  }

	  console.log('Received values of form: ', values);
	  name=values.title;

	  modalForm.resetFields();
	  this.props.addConcepto(name)

	  this.setState({ visible: false });




	});


  };


  //////////////////////////////////////////////////////////////////





  showDeleteConfirm(id) {

    confirm({
      title: 'Desea eliminar el elemento?',
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
		  this.props.removeConcepto(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  componentWillMount(){
    this.props.fetchAllCPU();
    this.props.fetchAllREV();
    this.props.fetchAllAUXI();
    this.props.fetchAllPanes();
	this.props.fetchAllConceptos();
	this.props.fetchAllProjects();
  }

  HandleUpdateCPURow = row => this.props.updateRowCPU(row);
  HandleUpdateREVRow = row => this.props.updateRowREV(row);
  HandleUpdateAUXISRow = row => this.props.updateRowAUXI(row);
  HandleCPUSave = cpu => this.props.CPUSave(cpu);

  onChange = (activeKey) => {
    this.setState({ activeKey });
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }
  add = () => {

    //const panes = this.state.panes;
    //const activeKey = `newTab${this.newTabIndex++}`;
    //panes.push({ title: 'A'+(this.newTabIndex+3), content: <ConceptosTabContent/>, key: activeKey });
    //this.setState({ panes, activeKey });
	this.showModal();
	//this.props.addPane('Nueva Pestaña')
  }





  remove = (targetKey) => {
	 if(targetKey!=1 & targetKey!=2 & targetKey!=0){
		 this.showDeleteConfirm(targetKey);}
    //this.props.removePane(targetKey);
  }

render()
{

	var data2=[]
	data2.data=this.props.projects.data;
    data2.links= this.props.projects.link;
	let unflatTab= {}
	if (data2.data!=undefined){
	    var tipos=data2.data.filter(function(elem) {
	        return !elem.parent
	    })
	    tipos=tipos.map(function(elem, index) {
	        return {key:elem.id.toString(),label:elem.text};
	    })
	}
	else{
		tipos=[{key:'Undefined',label:'No se encontraron tipos'}]
	}

	if (this.props.panes!=undefined){

	    var tabuladores=this.props.panes.map(function(elem, index) {

			unflatTab[elem.id]=elem.name;

	        return {key:elem.id.toString(),label:elem.name};
	    })
	}
	else{
		tabuladores=[{key:'Undefined',label:'No se encontraron tipos'}]
	}



	const conceptos= this.props.conceptos!==undefined?this.props.conceptos.map((concepto)=> {

		 const returnable = { title: concepto.name, content: <ConceptosTabContent tipos={tipos}
		  tabuladores={tabuladores} unflatTab={unflatTab} subConceptos={concepto.subconceptos}
		  project={concepto.gantt2_id}
		 updateConcepto={this.props.updateConcepto} addSub={this.props.addSub}
		  removeSub={this.props.removeSub}
		  conceptoId={concepto.id}

 		   />, key: concepto.id };
		return returnable
	}):[]




  return (
    <Layout id="sider">
      <ASider/>
      <Layout className='layout-container-antd'>
        <Header style={{ background: '#fff', padding: 0 }} />
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, background: '#fff', height:'100%' }}>

              <div style={{ marginBottom: 16 }}>
                {/*<Button onClick={this.add}>Nueva tarifa</Button>*/}
              </div>
              <Tabs
			   onEdit={this.onEdit}
                closable={false}
                onChange={this.onChange}
                activeKey={this.state.activeKey}
                type="editable-card"

                style={{  display: 'flex',
                flexDirection: 'column',height:'100%'}}
              >
                {conceptos.map(concepto => <TabPane style={{position:'absolute', height: '100%'}} tab={concepto.title} subconceptos={concepto.subconceptos!=undefined?concepto.subconceptos:[]}  key={concepto.key}>{concepto.content}</TabPane>)}
              </Tabs>
          </div>
        </Content>

      </Layout>
	  <CollectionCreateForm
		wrappedComponentRef={this.saveFormRef}
		visible={this.state.visible}
		onCancel={this.handleCancel}
		onCreate={this.addModal}
	  />


    </Layout>
  );
};
}

const mapStateToProps = state => {
  return {
    cpu: state.cpu.CPUS,
    panes: state.pane.data,
    rev: state.rev.REVS,
    auxi: state.auxi.AUXIS,
	conceptos: state.concepto.data,
	projects: state.gantt2
  };
};

Catalogos.propTypes = {
  cpu: PropTypes.array.isRequired,
  updateRowCPU: PropTypes.func.isRequired,

};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addCPUrow: cpuActions.addCPUrow,
      removeCPUrow: cpuActions.removeCPUrow,
      updateRowCPU: cpuActions.updateRowCPU,
      updateRowREV: revActions.updateRowREV,
      updateRowAUXI: auxiActions.updateRowAUXI,
      fetchAllCPU: cpuActions.fetchAllCPU,
      fetchAllREV: revActions.fetchAllREV,
      fetchAllAUXI: auxiActions.fetchAllAUXI,
	  fetchAllPanes:paneActions.fetchAll,
      CPUSave: cpuActions.CPUSave,
	  addPane:paneActions.add,
	  removePane:paneActions.remove,

	  addConcepto: conceptoActions.add,
	  removeConcepto: conceptoActions.remove,
	  fetchAllConceptos:conceptoActions.fetchAll,
	  fetchAllProjects: gantt2Actions.fetchAll,
	  updateConcepto: conceptoActions.update,
	  addSub: conceptoActions.addSub,
	  removeSub: conceptoActions.removeSub,

    },
    dispatch
  );

export default connect(mapStateToProps,mapDispatchToProps)(Catalogos);
