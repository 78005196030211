import { authConstants } from "../constants";

const INITIAL_STATE = {
  email: '',
  password: '',
  user: null,
  //idToken:'',
  error: '',
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authConstants.EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case authConstants.PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case authConstants.LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case authConstants.LOGIN_USER_SUCCESS:
      return { ...state, ...INITIAL_STATE, user: action.payload };
    case authConstants.LOGIN_USER_FAIL:
      return { ...state, error: 'Authentication Failed.', password: '', loading: false };
    case authConstants.LOGOUT_USER:
      return { INITIAL_STATE};
    default:
      return state;
  }
};
