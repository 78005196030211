import firebase from 'firebase';
import { paneConstants } from "../constants";
import { toast } from "react-toastify";

const revColumns = [
{
"dataIndex":"nrev",
"editable":false,
"key":"nrev",
"title":"Numero de revisiones"
},
{
"dataIndex":"increv",
"editable":true,
"key":"increv",
"title":"Incremento de presupuesto"
}]
const cpuColumns=[{"colSpan":2,"dataIndex":"finterval","key":"finterval","title":"Intervalos"},{"colSpan":0,"dataIndex":"linterval","key":"linterval","title":"FIntervalos"},{"dataIndex":"sotano","editable":true,"key":"sotano","title":"Exterior"},{"dataIndex":"interior","editable":true,"key":"interior","title":"Interior",
 	}
	,{
		"dataIndex": "especial1",
		"editable": "true",
		"key": "especial1",
		"title": "Especial 1"
	},
	{
		"dataIndex": "especial2",
		"editable": "true",
		"key": "especial2",
		"title": "Especial 2"
	}

];
const auxiColumns=[{"dataIndex":"n","editable":false,"key":"n","title":"Numero de auxiliares"},{"dataIndex":"inc","editable":true,"key":"inc","title":"Incremento de presupuesto"}];
const revRows = [{"increv":"2","key":"0","nrev":1},{"increv":"4","key":"1","nrev":2},
{"increv":"10","key":"2","nrev":3},{"increv":"15","key":"3","nrev":4}];

const cpuRows=[{"finterval":0,"interior":"0","key":"0","linterval":149,"exterior":"0","especial1":"0","especial2":"0"}
,{"finterval":150,"interior":"0","key":"1","linterval":349,"exterior":"0","especial1":"0","especial2":"0"},
{"finterval":350,"interior":"0","key":"2","linterval":549,"exterior":"0","especial1":"0","especial2":"0"},{"finterval":550,"interior":"0","key":"3","linterval":699,"exterior":"0","especial1":"0","especial2":"0"},{"finterval":700,"interior":"0","key":"4","linterval":999,"exterior":"0","especial1":"0","especial2":"0"},
{"finterval":1000,"interior":"0","key":"5","linterval":"en adelante","exterior":"0","especial1":"0","especial2":"0"}];
const auxiRows=[{"inc":"20","key":"0","n":1},{"inc":"40","key":"1","n":2},{"inc":"10","key":"2","n":3},{"inc":"15","key":"3","n":4}];

const fetchAll = () => {
	return (dispatch) => {

      firebase.database().ref(`/panes/`)
   	 .on('value', snapshot => {
   		 const preData=Object.values(snapshot.val());
		 const data=preData.filter(d => d.status==1)

   		 console.log(data);
   	   dispatch({ type: paneConstants.FETCH_SUCCESS, payload: {data:data} });
   	 });
    };
};

const fetchByID = (uid) => {
	return (dispatch) => {

      firebase.database().ref(`/panes/${uid}`)
   	 	.on('value', snapshot => {
   		 	const data=snapshot.val();

			firebase.database().ref(`/CPUS/${uid}`)
			   .on('value', CPUsnapshot =>{
				   firebase.database().ref(`/REVS/${uid}`)
					  .on('value', REVSsnapshot =>{
						  firebase.database().ref(`/AUXIS/${uid}`)
						   .on('value', AUXISsnapshot =>{

   	   							dispatch({ type: paneConstants.FETCH_BY_ID_SUCCESS, payload: {paneByID:data,revByID:REVSsnapshot.val(),cpuByID:CPUsnapshot.val(),auxiByID:AUXISsnapshot.val(),} });						

   	 				});
   	 			});
   	 		});
   	 });
    };
};



const fetchSoftDeleted = () => {
	return (dispatch) => {

      firebase.database().ref(`/panes/`)
   	 	.on('value', snapshot => {
   		 	const data=Object.values(snapshot.val());
   		 	console.log(data);
   	   		dispatch({ type: paneConstants.FETCH_SUCCESS, payload: {data:data} });
   	 });
    };

};



const remove = (uid) => {
	return(dispatch) =>{
		if(uid!=0 & uid!=1 & uid!=2){
			firebase.database().ref(`/panes/${uid}/`)
			  .update({"status":0}).then(()=>{
				  dispatch({ type: paneConstants.REMOVE_SUCCESS });
					toast.success("Eliminado");
			  })
		}
  	}
};

const add = (data) => {
	return (dispatch) => {
	  firebase.database().ref(`/panes/`)
		.push({"name":data})
		.then((response) => {
			console.log(response.path.pieces_[1])
			const uid= response.path.pieces_[1];
			firebase.database().ref(`/panes/${uid}/`)
			  .update({ id: uid, status:2 })
			  .then(() => {
				    firebase.database().ref(`/REVS/${uid}/`)
					.set({ id: uid, columns: revColumns, rows: revRows }).then(()=>{

						firebase.database().ref(`/AUXIS/${uid}/`)
						.set({ id: uid, columns: auxiColumns, rows: auxiRows }).then(()=>{
							firebase.database().ref(`/CPUS/${uid}/`)
							.set({ id: uid, columns: cpuColumns, rows: cpuRows }).then(()=>{

								firebase.database().ref(`/panes/${uid}/`)
								.update({ status: 1 }).then(()=>{

									dispatch({ type: paneConstants.ADD_SUCCESS });
									toast.success("Creado");
								})
							})

						})

					})




				//Actions.employeeList({ type: 'reset' });
			  });







		  //Actions.employeeList({ type: 'reset' });
	  });

}};
const update = () => {};



export const paneActions = {
  remove, add, fetchAll, fetchSoftDeleted, fetchByID,
};
