import React from "react";
import { Link } from "react-router-dom";


import { Table, Divider, Tag } from 'antd';
//ismport "./styles.scss";

const { Column, ColumnGroup } = Table;

class StaffsTable extends React.Component {
    constructor(props){
       super(props)




  }

  /*  handleDelete = key => {
      const dataSource = [...this.state.CPUdataSource];
      this.setState({ CPUdataSource: dataSource.filter(item => item.key !== key) });
    };*/
    remove= (id) => {
			this.props.remove(id)
	}


    render(){
      // const { visible, loading } = this.state;
      const data = this.props.data;

        return (

              <div>

                    {/*  <Button onClick={this.handleRowAdd} type="primary" style={{ marginBottom: 16 }}>
                        Añadir fila
                      </Button>
                      <Button onClick={this.handleColumnAdd} type="primary" style={{ marginBottom: 16 }}>
                        Añadir columna
                        </Button>*/}
                        <Table dataSource={data}>

                            <Column title="Nombre" dataIndex="nombre" key="nombre" />
                            <Column title="Correo" dataIndex="email" key="email" />
                            <Column title="Telefono" dataIndex="movil" key="movil" />
                            <Column title="Rol" dataIndex="role" key="role" />
                            <Column
                                title="Operaciones"
                                key="action"
                                render={(text, record) => (
                                  <span>
                                    <Link to={"/staffs/editar/"+record.id}>Editar</Link>
                                    <Divider type="vertical" />
                                    <a onClick={() => this.remove(record.id)}>Borrar</a>
                                  </span>
                                )}
                            />
                        </Table>




                    </div>
        );
      }
}
export default StaffsTable;
