import firebase from 'firebase';
import { clienteConstants } from "../constants";
import { toast } from "react-toastify";



const formSave = ( cliente, redirect ) => {
 // console.log(cliente);
 // const id= Math.round(Math.random() * 36 ** 12).toString(36);
  return (dispatch) => {
    firebase.database().ref(`/clientes/`)
      .push({ ...cliente, status:2 })
      .then((response) => {
		  const uid= response.path.pieces_[1];
		  firebase.database().ref(`/clientes/${uid}/`)
	        .update({ id:uid, status:1 }).then(()=>{
				dispatch({ type: clienteConstants.ADD_SUCCESS });
		          toast.success("Guardado");
				  redirect();
			})

        //Actions.employeeList({ type: 'reset' });
      });
  };
};

const update = ( cliente, redirect ) => {
  console.log("@@@@ actualizando:",cliente);
 // const id= Math.round(Math.random() * 36 ** 12).toString(36);
  return (dispatch) => {
    firebase.database().ref(`/clientes/${cliente.id}`)
      .update({ ...cliente })
      .then((response) => {

				dispatch({ type: clienteConstants.UPDATE_SUCCESS });
		          toast.success("Guardado");
				  redirect();

        //Actions.employeeList({ type: 'reset' });
      });
  };
};



const fetchByID = (uid) => {
	return (dispatch) => {

      firebase.database().ref(`/clientes/${uid}`)
   	 .on('value', snapshot => {
   		 const data=snapshot.val();
		 //const data=preData.filter(d => d.status==1)

   		 console.log("fetch by id exitoso");
		 console.log(data);
		    		 console.log("fetch by id exitoso");
   	   dispatch({ type: clienteConstants.FETCH_BY_ID_SUCCESS, payload: {byID:data} });
   	 });
    };
};

const fetchAll = () => {
	return (dispatch) => {

      firebase.database().ref(`/clientes/`)
   	 .on('value', snapshot => {
   		 const preData=Object.values(snapshot.val());
		 const data=preData.filter(d => d.status==1)

   		 console.log("fetch all exitoso");
   	   dispatch({ type: clienteConstants.FETCH_SUCCESS, payload: {data:data} });
   	 });
    };
};


const fetchSoftDeleted = () => {
	return (dispatch) => {

      firebase.database().ref(`/clientes/`)
   	 	.on('value', snapshot => {
   		 	const data=Object.values(snapshot.val());
   		 	console.log(data);
   	   		dispatch({ type: clienteConstants.FETCH_SUCCESS, payload: {data:data} });
   	 });
    };

};

const remove = (uid) => {
	return(dispatch) =>{

			firebase.database().ref(`/clientes/${uid}/`)
			  .update({"status":0}).then(()=>{
				  dispatch({ type: clienteConstants.REMOVE_SUCCESS });
					toast.success("Eliminado");
			  })

  	}
};





export const clienteActions = {
  fetchAll, fetchByID, formSave, update, remove
};
