import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Tabs, Button, } from 'antd';
import "./styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withRouter} from 'react-router-dom'

//import * as logo from "./aA.svg";

import {  staffActions, authActions } from "../../../actions";


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const LogoSvg = () => (
  <svg width="82px" height="54px" viewBox="0 0 82 54" version="1.1">
      <title>Group 23</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id="aA-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group-23" fill="#000000" fill-rule="nonzero">
              <path d="M81.2358742,54 L76.6611301,54 L75.9003411,53.2305 L71.3239285,41.657625 L69.7973454,40.88475 L49.2059914,40.88475 L47.6777399,41.657625 L43.1029957,53.2305 L42.3355331,54 L37.7624574,54 L37,53.2305 L37,47.8321875 L55.3056503,0.7711875 L56.0664393,0 L62.9318923,0 L63.6943497,0.7711875 L82,47.8321875 L82,53.2305 L81.2358742,54 Z M59.8803945,11.5711875 L59.1179371,11.5711875 L50.7292377,33.172875 L51.4916951,33.942375 L67.5083049,33.942375 L68.2724307,33.172875 L59.8803945,11.5711875 Z" id="Shape"></path>
              <path d="M32.2367064,39.2310604 L26.9036509,39.2310604 L26.1420239,38.4604383 L25.3803969,35.3829975 L24.6171033,35.3829975 C24.6171033,35.3829975 21.5722619,40 13.947659,40 C5.57142857,40 1,35.3829975 1,27.6919194 C1,19.2319017 7.85630957,15.3821562 17.7607937,15.3821562 C21.5705953,15.3821562 24.6154367,16.1561435 24.6154367,16.1561435 L25.3787303,15.3821562 L25.3787303,13.8459597 C25.3787303,8.46338283 23.0921827,6.92213856 17.7607937,6.92213856 C12.4294047,6.92213856 10.139524,8.46338283 10.139524,11.539141 L9.37956356,12.3097632 L3.28654758,12.3097632 L2.52492058,11.539141 C2.52492058,6.15319901 6.33138899,0 17.7641269,0 C29.191865,0 33,5.38425945 33,16.9234005 L33,38.4604383 L32.2367064,39.2310604 Z M25.3803969,23.0765995 L24.6171033,22.30766 C24.6171033,22.30766 22.3355554,21.5370378 18.5224207,21.5370378 C11.6661111,21.5370378 8.62126973,23.8455391 8.62126973,27.6919194 C8.62126973,31.5382998 10.9028176,33.0761789 15.4742461,33.0761789 C21.5705953,33.0761789 25.3787303,29.9987381 25.3787303,25.3867833 L25.3787303,23.0765995 L25.3803969,23.0765995 Z" id="Shape"></path>
              <polygon id="Shape" points="42.3716284 54 0.631713953 54 0 53.2579333 0 48.7404394 0.631713953 48 42.3716284 48 43 48.7404394 43 53.2579333"></polygon>
          </g>
      </g>
  </svg>
);
const LogoIcon = props => <Icon component={LogoSvg} {...props} />;


class ASider extends React.Component {

    componentWillMount(){

    }

  render(){
     // console.log('usuario')
      //console.log(this.props.user.UserInfo)

    return(
        <Sider
          className={'sider'}
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
          >
          <div className="logo">   <LogoIcon/>  </div>
          <Menu className={'menu'} theme="dark" mode="inline" >
          {/*   <Menu.Item key="1">
              <Icon type="user" />
              <span className="nav-text">Proyectos</span>
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="video-camera" />
              <span className="nav-text">Seguimiento</span>
            </Menu.Item>
            <Menu.Item key="3">
              <Icon type="upload" />
              <span className="nav-text">Utilerias</span>
            </Menu.Item>
            */}
          <SubMenu
            key="sub2"
            title={
              <span>
                <Icon type="project" />
                Catálogos
              </span>
            }
          >
               {/*<Menu.Item key="4"><Link to="/Servicios">Tipos de Servicio</Link></Menu.Item>*/}
			   <Menu.Item key="51"><Link to="/proyectos">Proyectos</Link></Menu.Item>
			   <Menu.Item key="52"><Link to="/proyectos/crear">Nuevo Proyecto</Link></Menu.Item>
	           <Menu.Item key="5"><Link to="/Catalogos">Tabulador</Link></Menu.Item>
			    <Menu.Item key="53"><Link to="/Conceptos">Conceptos</Link></Menu.Item>
               <Menu.Item key="3"><Link to="/Gantt">Gantt</Link></Menu.Item>
               {/*<Menu.Item key="6">Conceptos</Menu.Item>
               <Menu.Item key="7">Staff</Menu.Item>*/}
        </SubMenu>
        {this.props.user&&this.props.user.UserInfo.customClaims.role==='admin' && <SubMenu
            key="sub3"
            title={
              <span>
                <Icon type="user" />
                Clientes
              </span>
            }>
          <Menu.Item key="8"><Link to="/clientes">Listado</Link></Menu.Item>
          <Menu.Item key="9"><Link to="/clientes/crear">Nuevo Cliente</Link></Menu.Item>
        </SubMenu>}
        {this.props.user&&this.props.user.UserInfo.customClaims.role==='admin' && <SubMenu
            key="sub4"
            title={
              <span>
                <Icon type="team" />
                Staff
              </span>
            }>
          <Menu.Item key="10"><Link to="/staffs">Listado</Link></Menu.Item>
          <Menu.Item key="11"><Link to="/staffs/crear">Nuevo Staff</Link></Menu.Item>
        </SubMenu>}

          </Menu>
        </Sider>);
        };

}

const mapStateToProps = state => {
  return {
  /*  cpu: state.cpu.CPUS,
    rev: state.rev.REVS,
    auxi: state.auxi.AUXIS,*/
//    gantt: state.gantt,
//    gantt2: state.gantt2,
//    staff:state.staff,
//    cliente:state.cliente,
    user:state.auth.user

  };
};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
     // fetchAll: ganttActions.fetchAll,
      //fetchAll2: gantt2Actions.fetchAll,
     // stafffetchAll:staffActions.fetchAll,
     // clientefetchAll:clienteActions.fetchAll,
    //  UpdateGantt:ganttActions.formSave,
    //  UpdateGantt2:gantt2Actions.formSave,

    },
    dispatch
  );

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ASider));
