import { ganttConstants } from "../constants";

const initialState = {
  gantts:[]};

export default (state = initialState, action) => {
switch (action.type) {
   case ganttConstants.FETCH_SUCCESS:
     return action.payload;
   default: return state;
}

};
