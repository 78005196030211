import React from "react";
import ServiciosTabContent from "../../components/arquitectos/ServiciosTabContent";
import { Layout, Menu, Icon, Tabs, Button, } from 'antd';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ASider from "../../components/arquitectos/ASider";

import { cpuActions, revActions, auxiActions, procesoActions } from "../../actions";

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const TabPane = Tabs.TabPane;


// importing todo components

// importing our todo's action's



class Servicios extends React.Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;

    this.state = {
      activeKey: '0',

    };
  }

  componentWillMount(){
    //this.props.fetchAllCPU();
    //this.props.fetchAllREV();
    //this.props.fetchAllAUXI();

  }

  HandleUpdateCPURow = row => this.props.updateRowCPU(row);
  HandleUpdateREVRow = row => this.props.updateRowREV(row);
  HandleUpdateAUXISRow = row => this.props.updateRowAUXI(row);
  HandleCPUSave = cpu => this.props.CPUSave(cpu);


  onChange = (activeKey) => {
    this.setState({ activeKey });
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }
  add = () => {
    const panes = this.state.panes;
    const activeKey = `newTab${this.newTabIndex++}`;
    panes.push({ title: 'A'+(this.newTabIndex+3), content: <ServiciosTabContent/>, key: activeKey });
    this.setState({ panes, activeKey });
  }

render()
{

  console.log("copia esto we")
  console.log(JSON.stringify(this.props.proceso))
  var panes = [
    { title: 'Hotelero', content: <ServiciosTabContent    cpuKey={0} />, key: '0' },
    { title: 'Condominio Vertical', content: <ServiciosTabContent      cpuKey={1} />, key: '1' },
    { title: 'Condominio Horizontal', content: <ServiciosTabContent   cpuKey={2} />, key: '2' },
  ];
  return (
    <Layout id="sider">
      <ASider/>

      <Layout className='layout-container-antd'>
        <Header style={{ background: '#fff', padding: 0 }} />

        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, background: '#fff', height:'100%' }}>

              <div style={{ marginBottom: 16 }}>
                {/*<Button onClick={this.add}>Nueva tarifa</Button>*/}
              </div>
              <Tabs
                hideAdd
                closable={false}
                onChange={this.onChange}
                activeKey={this.state.activeKey}
                type="card"
                style={{  display: 'flex',
                flexDirection: 'column',height:'100%'}}
              >
                {panes.map(pane => <TabPane style={{position:'absolute', height: '100%'}} tab={pane.title} key={pane.key}>{pane.content}</TabPane>)}
              </Tabs>
          </div>
        </Content>

      </Layout>
    </Layout>
  );
};
}

const mapStateToProps = state => {
  return {

    proceso: state.proceso.PROCESOS,
  };
};

Servicios.propTypes = {
  cpu: PropTypes.array.isRequired,
  updateRowCPU: PropTypes.func.isRequired,
};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addCPUrow: cpuActions.addCPUrow,
      removeCPUrow: cpuActions.removeCPUrow,
      updateRowCPU: cpuActions.updateRowCPU,
      updateRowREV: revActions.updateRowREV,
      updateRowAUXI: auxiActions.updateRowAUXI,
      fetchAllCPU: cpuActions.fetchAllCPU,
      fetchAllREV: revActions.fetchAllREV,
      fetchAllAUXI: auxiActions.fetchAllAUXI,
      CPUSave: cpuActions.CPUSave,



    },
    dispatch
  );

export default connect(mapStateToProps,mapDispatchToProps)(Servicios);
