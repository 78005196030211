 import { cpuConstants } from "../constants";

const initialState = {
  CPUS:{
        0:{ id: 0,
            columns: [
           {
            title: 'Intervalos',
            dataIndex: 'finterval',
            key: 'finterval',
            colSpan: 2,
            //editable:true,

          },
          {
            title: 'FIntervalos',
            dataIndex: 'linterval',
            key: 'linterval',
            colSpan: 0,
          //  editable:true,

          },
          {
            title: 'Exterior',
            dataIndex: 'sotano',
            key: 'sotano',
            editable:true,

          },
          {
            title: 'Interior',
            dataIndex: 'interior',
            key: 'interior',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                finterval: 0,
                linterval: 149,
                sotano: '$',
                interior: '$',
              },
              {
                key: '1',
                finterval: 150,
                linterval: 349,
                sotano: '$',
                interior: '$',
              },
              {
                key: '2',
                finterval: 350,
                linterval: 549,
                sotano: '$',
                interior: '$',
              },
              {
                key: '3',
                finterval: 550,
                linterval: 699,
                sotano: '$',
                interior: '$',
              },
              {
                key: '4',
                finterval: 700,
                linterval: 999,
                sotano: '$',
                interior: '$',
              },
              {
                key: '5',
                finterval: 1000,
                linterval: 'en adelante',
                sotano: '$',
                interior: '$',
              },

            ],
        },
        1:{ id: 1,
            columns: [
           {
            title: 'Intervalos',
            dataIndex: 'finterval',
            key: 'finterval',
            colSpan: 2,
            //editable:true,

          },
          {
            title: 'FIntervalos',
            dataIndex: 'linterval',
            key: 'linterval',
            colSpan: 0,
          //  editable:true,

          },
          {
            title: 'Exterior',
            dataIndex: 'sotano',
            key: 'sotano',
            editable:true,

          },
          {
            title: 'Interior',
            dataIndex: 'interior',
            key: 'interior',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                finterval: 0,
                linterval: 149,
                sotano: '$',
                interior: '$',
              },
              {
                key: '1',
                finterval: 150,
                linterval: 349,
                sotano: '$',
                interior: '$',
              },
              {
                key: '2',
                finterval: 350,
                linterval: 549,
                sotano: '$',
                interior: '$',
              },
              {
                key: '3',
                finterval: 550,
                linterval: 699,
                sotano: '$',
                interior: '$',
              },
              {
                key: '4',
                finterval: 700,
                linterval: 999,
                sotano: '$',
                interior: '$',
              },
              {
                key: '5',
                finterval: 1000,
                linterval: 'en adelante',
                sotano: '$',
                interior: '$',
              },

            ],
        },
        2:{ id: 2,
            columns: [
           {
            title: 'Intervalos',
            dataIndex: 'finterval',
            key: 'finterval',
            colSpan: 2,
            //editable:true,

          },
          {
            title: 'FIntervalos',
            dataIndex: 'linterval',
            key: 'linterval',
            colSpan: 0,
          //  editable:true,

          },
          {
            title: 'Exterior',
            dataIndex: 'sotano',
            key: 'sotano',
            editable:true,

          },
          {
            title: 'Interior',
            dataIndex: 'interior',
            key: 'interior',
            editable:true,

          },
          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                finterval: 0,
                linterval: 149,
                sotano: '$',
                interior: '$',
              },
              {
                key: '1',
                finterval: 150,
                linterval: 349,
                sotano: '$',
                interior: '$',
              },
              {
                key: '2',
                finterval: 350,
                linterval: 549,
                sotano: '$',
                interior: '$',
              },
              {
                key: '3',
                finterval: 550,
                linterval: 699,
                sotano: '$',
                interior: '$',
              },
              {
                key: '4',
                finterval: 700,
                linterval: 999,
                sotano: '$',
                interior: '$',
              },
              {
                key: '5',
                finterval: 1000,
                linterval: 'en adelante',
                sotano: '$',
                interior: '$',
              },

            ],
        },

    }

};

export default (state = initialState, action) => {
  switch (action.type) {
    case cpuConstants.CPUS_FETCH_SUCCESS:
    return action.payload;

    case cpuConstants.ADD_CPU_SUCCESS:
      return Object.assign({}, state, {
        rows: state.rows.concat(action.payload)
      });
      case cpuConstants.UPDATE_ROW_CPU:
        var newData=state.CPUS[action.payload.cpuKey];
        newData=newData.rows.map(
          row =>{

              if(row.key===action.payload.key){
                return {...row, [action.payload.prop]:action.payload.value}
              }
              return row;
            }
                 );

        return { ...state, CPUS:{
                ...state.CPUS,
                  [action.payload.cpuKey]: {
                    ...state.CPUS[action.payload.cpuKey],
                    rows:newData
                  }
              //  ...row, completed: !row.completed
        }
      };
    case cpuConstants.REMOVE_CPU_SUCCESS:
      return {
        rows: state.rows.filter(row => row.id !== action.payload)
      };
    default:
      return state;
  }
};
