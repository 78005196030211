import firebase from 'firebase';
import { revConstants } from "../constants";
import { toast } from "react-toastify";

const fetchAllREV = () => {
  return (dispatch) => {
    firebase.database().ref(`/`)
      .on('value', snapshot => {
        dispatch({ type: revConstants.REVS_FETCH_SUCCESS, payload: snapshot.val() });
      });
  };
};

const addREVSuccess = rev => dispatch => {
  dispatch({
    type: revConstants.ADD_REV_SUCCESS,
    payload: rev
  });

  toast.success("REV added!");
};

const updateRowREV = rev => dispatch => {
  dispatch({
    type: revConstants.UPDATE_ROW_REV,
    payload: rev
  });


};

const toggleREVSuccess = id => dispatch => {
  dispatch({
    type: revConstants.TOGGLE_REV_SUCCESS,
    payload: id
  });

  toast.info("REV state updated!");
};

const removeREVSuccess = id => dispatch => {
  dispatch({
    type: revConstants.REMOVE_REV_SUCCESS,
    payload: id
  });
  toast.error("REV removed!");
};

const addREV = name =>
  addREVSuccess({
    id: Math.round(Math.random() * 36 ** 12).toString(36),
    name: name,
    completed: false
  });

const toggleREV = id => toggleREVSuccess(id);

const removeREV = id => removeREVSuccess(id);

export const revActions = {
  fetchAllREV,
  updateRowREV,
};
