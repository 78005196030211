 import { procesoConstants } from "../constants";

const initialState = {
  PROCESOS:{
        0:{ id: 0,
            columns: [
          {
            title: 'Procesos',
            dataIndex: 'n',
            key: 'n',
            editable:false,

          },

          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                n: 'Preliminares',

              },
              {
                key: '1',
                n: 'Anteproyecto',

              },
              {
                key: '2',
                n: 'Desarrollo de Proyecto',

              },
              {
                key: '3',
                n: 'Proyecto Ejecutivo',

              },


            ],
        },
        1:{ id: 1,
            columns: [
          {
            title: 'Procesos',
            dataIndex: 'n',
            key: 'n',
            editable:false,

          },

          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                n: 'Preliminares',

              },
              {
                key: '1',
                n: 'Anteproyecto',

              },
              {
                key: '2',
                n: 'Desarrollo de Proyecto',

              },
              {
                key: '3',
                n: 'Proyecto Ejecutivo',

              },


            ],
        },
        2:{ id: 2,
            columns: [
          {
            title: 'Procesos',
            dataIndex: 'n',
            key: 'n',
            editable:false,

          },

          /*{
            title: 'Operaciones',
            dataIndex: 'operation',
            render: (text, record) =>
              this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
                  <a href="javascript:;">Borrar</a>
                </Popconfirm>
              ) : null,
          },*/
        ],
            rows: [
              {
                key: '0',
                n: 'Preliminares',

              },
              {
                key: '1',
                n: 'Anteproyecto',

              },
              {
                key: '2',
                n: 'Desarrollo de Proyecto',

              },
              {
                key: '3',
                n: 'Proyecto Ejecutivo',

              },


            ],
        },

    }

};

export default (state = initialState, action) => {
  switch (action.type) {
      case procesoConstants.FETCH_SUCCESS:
    return action.payload;

      case procesoConstants.ADD_SUCCESS:
        return Object.assign({}, state, {
          ...state, Procesos:{
                  ...state.Procesos,
                    [action.payload.cpuKey]: state.Procesos[action.payload.cpuKey].rows.concat(action.payload)
        }
      });
      case procesoConstants.UPDATE_ROW:
        var newData=state.Procesos[action.payload.cpuKey];
        newData=newData.rows.map(
          row =>{

              if(row.key===action.payload.key){
                return {...row, [action.payload.prop]:action.payload.value}
              }
              return row;
            }
                 );

        return { ...state, Procesos:{
                ...state.Procesos,
                  [action.payload.cpuKey]: {
                    ...state.Procesos[action.payload.cpuKey],
                    rows:newData
                  }
              //  ...row, completed: !row.completed
        }
      };
    case procesoConstants.REMOVE_SUCCESS:
      return {
        rows: state.rows.filter(row => row.id !== action.payload)
      };
    default:
      return {...state};
  }
};
