export const authConstants = {
   EMAIL_CHANGED:'EMAIL_CHANGED',
   PASSWORD_CHANGED:'PASSWORD_CHANGED',
   LOGIN_USER_SUCCESS:'LOGIN_USER_SUCCESS',
   LOGIN_USER_FAIL:'LOGIN_USER_FAIL',
   LOGIN_USER:'LOGIN_USER',
   LOGOUT_USER:'LOGOUT_USER',
   EMAIL_SUCCESS:'EMAIL_SUCCESS',
   API:'https://us-central1-arquitectos-88d9d.cloudfunctions.net/api/users/',
   API_EMAIL:'https://us-central1-arquitectos-88d9d.cloudfunctions.net/api/email',
  /*UPDATE:'https://us-central1-arquitectos-88d9d.cloudfunctions.net/api/users/{id}',
   CREATE:'https://us-central1-arquitectos-88d9d.cloudfunctions.net/api/users/',
   REMOVE:'https://us-central1-arquitectos-88d9d.cloudfunctions.net/api/users/{id}',
   FETCH_ALL:'https://us-central1-arquitectos-88d9d.cloudfunctions.net/api/users/',
   FETCH_BY_ID:'https://us-central1-arquitectos-88d9d.cloudfunctions.net/api/users/{id}',*/
};
