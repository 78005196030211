import firebase from 'firebase';
import { auxiConstants } from "../constants";
//import { toast } from "react-toastify";

const fetchAllAUXI = () => {
  return (dispatch) => {
    firebase.database().ref(`/`)
      .on('value', snapshot => {
        dispatch({ type: auxiConstants.FETCH_SUCCESS, payload: snapshot.val() });
      });
  };
};



const updateRowAUXI = auxi => dispatch => {
  dispatch({
    type: auxiConstants.UPDATE_ROW,
    payload: auxi
  });


};


export const auxiActions = {

  updateRowAUXI, fetchAllAUXI,
};
