/*
@license

dhtmlxGantt v.6.3.7 Professional Evaluation
This software is covered by DHTMLX Evaluation License. Contact sales@dhtmlx.com to get Commercial or Enterprise license. Usage without proper license is prohibited.

(c) XB Software Ltd.

*/
Gantt.plugin(function (e) {
  !function (e, t) {
    "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define("locale/locale_es", [], t) : "object" == typeof exports ? exports["locale/locale_es"] = t() : e["locale/locale_es"] = t();
  }(window, function () {
    return function (e) {
      var t = {};

      function n(o) {
        if (t[o]) return t[o].exports;
        var r = t[o] = {
          i: o,
          l: !1,
          exports: {}
        };
        return e[o].call(r.exports, r, r.exports, n), r.l = !0, r.exports;
      }

      return n.m = e, n.c = t, n.d = function (e, t, o) {
        n.o(e, t) || Object.defineProperty(e, t, {
          enumerable: !0,
          get: o
        });
      }, n.r = function (e) {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(e, "__esModule", {
          value: !0
        });
      }, n.t = function (e, t) {
        if (1 & t && (e = n(e)), 8 & t) return e;
        if (4 & t && "object" == typeof e && e && e.__esModule) return e;
        var o = Object.create(null);
        if (n.r(o), Object.defineProperty(o, "default", {
          enumerable: !0,
          value: e
        }), 2 & t && "string" != typeof e) for (var r in e) {
          n.d(o, r, function (t) {
            return e[t];
          }.bind(null, r));
        }
        return o;
      }, n.n = function (e) {
        var t = e && e.__esModule ? function () {
          return e.default;
        } : function () {
          return e;
        };
        return n.d(t, "a", t), t;
      }, n.o = function (e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }, n.p = "/codebase/", n(n.s = 222);
    }({
      222: function _(t, n) {
        e.locale = {
          date: {
            month_full: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            month_short: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            day_full: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
            day_short: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"]
          },
          labels: {
            new_task: "Nueva tarea",
            dhx_cal_today_button: "Hoy",
            day_tab: "Día",
            week_tab: "Semana",
            month_tab: "Mes",
            new_event: "Nuevo evento",
            icon_save: "Guardar",
            icon_cancel: "Cancelar",
            icon_details: "Detalles",
            icon_edit: "Editar",
            icon_delete: "Eliminar",
            confirm_closing: "",
            confirm_deleting: "El evento se borrará definitivamente, ¿continuar?",
            section_description: "Descripción",
            section_time: "Período",
            section_type: "Tipo",
            column_wbs: "EDT",
            column_text: "Tarea",
            column_start_date: "Inicio",
            column_duration: "Duración",
            column_add: "",
            link: "Enlace",
            confirm_link_deleting: "será borrada",
            link_start: " (inicio)",
            link_end: " (fin)",
            type_task: "Tarea",
            type_project: "Proyecto",
            type_milestone: "Hito",
            minutes: "Minutos",
            hours: "Horas",
            days: "Días",
            weeks: "Semanas",
            months: "Meses",
            years: "Años",
            message_ok: "OK",
            message_cancel: "Cancelar",
            section_constraint: "Constraint",
            constraint_type: "Constraint type",
            constraint_date: "Constraint date",
            asap: "As Soon As Possible",
            alap: "As Late As Possible",
            snet: "Start No Earlier Than",
            snlt: "Start No Later Than",
            fnet: "Finish No Earlier Than",
            fnlt: "Finish No Later Than",
            mso: "Must Start On",
            mfo: "Must Finish On",
            resources_filter_placeholder: "type to filter",
            resources_filter_label: "hide empty"
          }
        };
      }
    });
  });
});