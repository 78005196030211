import { Form, Input, Icon, Button, Modal, Radio } from 'antd';
import React from 'react';

let id = 0;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(

  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form,  } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Crear nuevo elemento"
          okText="Crear"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: 'Ingresa el nombre de tu elemento' }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Descripcion">
              {getFieldDecorator('descripcion')(<Input type="textarea" />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);


class DynamicFieldSet extends React.Component {
  state = {
    visible: false,
  };




  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  remove = (k,index) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const names = form.getFieldValue('names');
    console.log(names);

    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }
    names.splice(index,1);
    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
      names: names,
    });
  };

  add = () => {
    const { form } = this.props;
    const modalForm= this.formRef.props.form;
    let name= '';
    modalForm.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);
      name=values.title;
      // can use data-binding to get

      const names =  form.getFieldValue('names');
      const keys = form.getFieldValue('keys');
      id=Math.round(Math.random() * 36 ** 12).toString(36);
      const nextNames = names.concat(name);
      const nextKeys = keys.concat(id);
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys, names:nextNames,
      });
      modalForm.resetFields();
      this.setState({ visible: false });
    });


  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        console.log('Received values of form: ', values);
        console.log('Merged values:', keys.map(key => names[key]));
      }
    });
  };

  render() {
    const { fields } = this.state;
    const {handleOnClick} = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };
    getFieldDecorator('names', { initialValue: [] });
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const names = getFieldValue('names');
    console.log('vuelta' +getFieldValue('names'))
    const formItems = keys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayoutWithOutLabel : formItemLayoutWithOutLabel)}

        required={false}
        key={k}
      >

          <Button onClick={() => handleOnClick(k)} type="secondary" placeholder="passenger name" style={{ width: '60%', marginRight: 8 }} >
            {names[index]}
          </Button>


        {keys.length > 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => this.remove(k,index)}
          />
        ) : null}
      </Form.Item>
    ));
    return (
      <Form {...fields} onChange={this.handleFormChange}  onSubmit={this.handleSubmit}>
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel}>
        {/*  <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
            <Icon type="plus" /> Añadir proceso
          </Button> */}
          <Button type="dashed"  onClick={this.showModal}>
          <Icon type="plus" />  Añadir elemento
          </Button>
        </Form.Item>
      {/* No se para que es jajajajaja
          <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        */}

        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.add}
        />

      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create({
  name:'dynamic_form_item',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      names: Form.createFormField({
        ...props.names,
        value: props.names.value,
      }),
      keys: Form.createFormField({
        ...props.keys,
        value: props.keys.value,
      }),
    };
  },
  onValuesChange(_, values) {
    console.log(values);
  },

})(DynamicFieldSet);
export default (WrappedDynamicFieldSet);
