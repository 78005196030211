import React from "react";
import { Link } from "react-router-dom";

import { Table, Divider, Tag } from 'antd';
//ismport "./styles.scss";

const { Column, ColumnGroup } = Table;

class ClientesTable extends React.Component {
    constructor(props){
       super(props)




  }

  /*  handleDelete = key => {
      const dataSource = [...this.state.CPUdataSource];
      this.setState({ CPUdataSource: dataSource.filter(item => item.key !== key) });
    };*/

	remove= (id) => {
			this.props.remove(id)
	}

    render(){
      // const { visible, loading } = this.state;
      const data = this.props.data;

        return (

              <div>

                    {/*  <Button onClick={this.handleRowAdd} type="primary" style={{ marginBottom: 16 }}>
                        Añadir fila
                      </Button>
                      <Button onClick={this.handleColumnAdd} type="primary" style={{ marginBottom: 16 }}>
                        Añadir columna
                        </Button>*/}
                        <Table dataSource={data}>

                            <Column title="Nombre" dataIndex="nombre" key="nombre" />
                            <Column title="Correo" dataIndex="email" key="email" />

                          <Column title="Telefono" dataIndex="empresaTelefono" key="empresaTelefono" />
                          <Column title="Empresa" dataIndex="empresaNombre" key="empresaNombre" />
                          {<Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                              <span>
                                {/*<a>Invite {record.lastName}</a>*/}
								<Link to={"/clientes/editar/"+record.id}>Editar</Link>
                                <Divider type="vertical" />
                                <a onClick={() => this.remove(record.id)}>Borrar</a>
                              </span>
                            )}
                          />}
                        </Table>




                    </div>
        );
      }
}
export default ClientesTable;
