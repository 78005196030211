import React from "react";
import { Route, Switch } from "react-router-dom";

// importing containers
import TodosContainer from "../containers/todos";
import Catalogos from "../containers/catalogos";
import Conceptos from "../containers/conceptos";
import Servicios from "../containers/servicios";
import Gantt from "../containers/gantt";
import Login from "../containers/login";
import WrappedClientes from "../containers/clientes/clientesForm";
import clientesList from "../containers/clientes/clientesList";
import ProyectosList from "../containers/proyectos/List";
import WrappedProyectos from "../containers/proyectos/Form";
import WrappedStaffs from "../containers/staffs/staffsForm";
import staffsList from "../containers/staffs/staffsList";

const routes = () => (
  <Switch>
      <Route path="/" exact render={({history})=><Login history={history}/>} />
      <Route path="/Catalogos" exact component={Catalogos} />
      <Route path="/Conceptos" exact component={Conceptos} />
      <Route path="/Servicios" exact component={Servicios} />
      <Route path="/Gantt" exact component={Gantt} />

      <Route path="/clientes/crear" exact component={WrappedClientes} />
	   <Route path="/clientes/editar/:id" exact component={WrappedClientes} />
      <Route path="/clientes/" exact component={clientesList} />

	  <Route path="/proyectos/ver/:id" exact component={WrappedProyectos} />
	  <Route path="/proyectos/crear" exact component={WrappedProyectos} />
	  <Route path="/proyectos" exact component={ProyectosList} />

	  <Route path="/staffs/crear" exact component={WrappedStaffs} />
      <Route path="/staffs/editar/:id" exact component={WrappedStaffs} />
      <Route path="/staffs/" exact component={staffsList} />
    <Route path="/index" exact component={TodosContainer} />
  </Switch>
);

export default routes;
